import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import { Helmet } from "react-helmet";

const testimonials = [
  {
    id: 1,
    name: "Shreya Singh",
    comment:
      "The workshop offered an engaging and tailored learning pace, making concepts clearer!",
    imgUrl:
      "https://imagevars.gulfnews.com/2018/8/20/1_16a084fc39b.2268802_3772564017_16a084fc39b_large.jpg",
  },
  {
    id: 2,
    name: "Rahul Patel",
    comment:
      "Received invaluable insights, refining my strategy for the UPSC journey!",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 3,
    name: "Divya Sharma",
    comment:
      "Boosted my confidence remarkably with personalized sessions. Grateful!",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 4,
    name: "Amit Gupta",
    comment:
      "The discussions were insightful and impactful, shaping my approach positively",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 5,
    name: "Ishaan Verma",
    comment: "Benefited from excellent mentorship and engaging discussions",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 6,
    name: "Pooja Desai",
    comment: "Topics were broken down well, immensely beneficial workshop!",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 7,
    name: "Ravi Kumar",
    comment:
      "Engaging discussions elevated my analytical skills and understanding",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 8,
    name: "Neha Rajput",
    comment:
      "Insightful sessions refined my preparation methods for UPSC exams",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 9,
    name: "Ankit Tiwari",
    comment:
      "This workshop was much needed and now i feel more clarity in my preparation",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 10,
    name: "Sneha Mishra",
    comment: "Great mentorship and engaging discussions!",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
  {
    id: 11,
    name: "Aarav Singh",
    comment:
      "thank you saralupsc for this workshop. really enjoyed the activities",
    imgUrl: "https://static.theprint.in/wp-content/uploads/2022/03/NAVEEEN.jpg",
  },
];

const PrelimsWorkshop = () => {
  // Get today's date
  const today = new Date();

  // Calculate tomorrow's date
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Format tomorrow's date to display in the desired format
  const formattedDate = `${tomorrow.getDate()}-${
    tomorrow.getMonth() + 1
  }-${tomorrow.getFullYear()}`;

  return (
    <>
      <Helmet>
        <title>Prelims Booster Workshop for UPSC 2024</title>
      </Helmet>
      <section>
        <div class="container px-4 ">
          <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div class="col-10 col-sm-8 col-lg-5">
              <img
                src="/assets/images/veer-pratap.png"
                class="d-block mx-lg-auto img-fluid"
                alt="Bootstrap Themes"
                width="400"
                height="500"
                loading="lazy"
              />
            </div>
            <div class="col-lg-7">
              <h5>CRACK UPSC IN FIRST ATTEMPT</h5>
              <h1 className="display-4 fw-bold text-body-emphasis">
                Clear UPSC Prelims 2024 in{" "}
                <span className="text-danger border-bottom border-danger">
                  First Attempt
                </span>
              </h1>
              <h4 className="mb-4">
                We help UPSC aspirants struggling with{" "}
                <span className="fw-bold text-danger ">Right Strategy</span> and{" "}
                <span className="fw-bold text-danger ">Low Confidence</span> to
                crack Prelims in First Attempt
              </h4>
              <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-lg px-4"
                >
                  Waste One Year
                </button>
                <a
                  type="button"
                  class="btn btn-danger btn-lg px-4 me-md-2"
                  href="https://imjo.in/ZGha7v"
                >
                  Join Prelims Booster Workshop for ₹ 499
                </a>
              </div>
              <p className="mt-3">
                * Reserve a seat before <b>December 20, 2023</b> to unlock
                <br />
                <b>Bonuses Worth ₹10,496</b>
              </p>
            </div>
          </div>
        </div>

        <div class="container mb-5">
          <div class="p-5 text-center  bg-danger rounded-5">
            <h1 class="text-white mt-5">
              Increase your Chances of Cracking <u>Prelims 2024 by 86%</u>
            </h1>
            <p class="lead text-white">
              With the right strategy and mentorship,{" "}
              <span className="fw-bold">UPSC is the easiest exam </span>
              to crack. We realise this and want every aspirant to clear Prelims
              in the first attempt itself. Prelims Booster Workshop has been
              designed to identify the pitfalls in aspirants preparation and
              give actionable information to equip them with right knowledge
            </p>

            <a
              class="d-inline-flex align-items-center btn btn-light btn-lg px-4 rounded-pill mb-5 mt-3"
              type="button"
              href="https://imjo.in/ZGha7v"
            >
              Join Prelims Booster Workshop for ₹ 499
            </a>
          </div>
        </div>

        <section>
          <div className="container px-4 pt-3" id="hanging-icons">
            <div className="text-center">
              <h1 class="display-4 fw-bold text-body-emphasis">
                But, why{" "}
                <span className="text-danger">Prelims Booster Workshop</span>?
              </h1>
            </div>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 py-5">
              <div class="col d-flex align-items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.75em"
                  height="1.75em"
                  fill="currentColor"
                  class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                </svg>
                <div>
                  <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                    Activity Based Workshop
                  </h3>
                  <p>Dive deep into your performance with detailed analysis.</p>
                </div>
              </div>
              <div class="col d-flex align-items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.75em"
                  height="1.75em"
                  fill="currentColor"
                  class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                </svg>
                <div>
                  <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                    Prelims Focused
                  </h3>
                  <p>
                    Sharpen your knowledge and skills for the Preliminary
                    examination.
                  </p>
                </div>
              </div>
              <div class="col d-flex align-items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.75em"
                  height="1.75em"
                  fill="currentColor"
                  class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                </svg>
                <div>
                  <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                    Tricks by Toppers
                  </h3>
                  <p>
                    Smart work is required over hard to beat other aspirants in
                    this exam
                  </p>
                </div>
              </div>
              <div class="col d-flex align-items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.75em"
                  height="1.75em"
                  fill="currentColor"
                  class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                </svg>
                <div>
                  <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                    Correct Reading Material
                  </h3>
                  <p>
                    Get access to valuable study materials to enhance your
                    preparation.
                  </p>
                </div>
              </div>
              <div class="col d-flex align-items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.75em"
                  height="1.75em"
                  fill="currentColor"
                  class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                </svg>
                <div>
                  <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                    Proven Results
                  </h3>
                  <p>
                    380 candidates have cleared Prelims successfully with the
                    help of this workshop.
                  </p>
                </div>
              </div>
              <div class="col d-flex align-items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.75em"
                  height="1.75em"
                  fill="currentColor"
                  class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                </svg>
                <div>
                  <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                    Extra Bonuses
                  </h3>
                  <p>
                    Get access to study materials which will help you boost your
                    preparation
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container mb-5 bt-2">
          <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
            <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
              <h1 className="display-4 fw-bold lh-2 text-body-emphasis">
                <span className="text-danger border-bottom border-danger">
                  380 Aspirants
                </span>{" "}
                cleared UPSC Prelims
              </h1>
              <p className="lead fw-bold text-danger">
                With the help of this workshop, 380 aspirants have been able to
                clear Prelims easily and reached till interview stage
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                <a
                  type="button"
                  class="btn btn-danger btn-lg px-4 me-md-2 fw-bold"
                  href="https://imjo.in/ZGha7v"
                >
                  Join Prelims Booster Workshop for ₹ 499
                </a>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden">
              <img
                className="rounded-lg-3"
                src="/assets/images/veer-pratap.png"
                alt="UPSC Ranker"
                width="350"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 bg-danger py-5">
        <h1 className="fw-bold mb-2 text-center text-white">
          How Good Does it Feel to Clear{" "}
          <span className="border-bottom">Prelims in First Attempt?</span>
        </h1>
        <h5 className="text-center mb-5 text-white">
          Read the Joys of Successful Aspirants
        </h5>

        {/* Swiper for desktop mode */}
        <div className="d-none d-md-block">
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <div>
                          <p>
                            - <b>{TESTIMONIAL.name}</b>
                          </p>

                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        {/* Swiper for mobile mode */}
        <div className="d-md-none">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            freeMode={true}
            navigation={true}
            pagination={{ clickable: true }}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i} className="px-3">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <div>
                          <p>
                            - <b>{TESTIMONIAL.name}</b>
                          </p>

                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        {/* Second Swiper for desktop mode */}
        <div className="d-none d-md-block mt-4">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <div>
                          <p>
                            - <b>{TESTIMONIAL.name}</b>
                          </p>

                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
      <section className="py-5" style={{ backgroundColor: "#F8F9FA" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-md-10  col-lg-8">
              <h2 className="mb-4 text-center">Frequently Asked Questions</h2>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style={{ fontSize: "20px" }}
                    >
                      When does the Workshop start?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The workshop will be held on 24 December, 2023 from 10AM
                      to 1PM
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      style={{ fontSize: "20px" }}
                    >
                      I am a working person, not sure if I can make it, will you
                      share the recordings?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Yes, all the videos (Live & recorded) will be shared with
                      you. Valid for only next week of the workshop (Our
                      intention is that students should complete these tasks
                      quickly in same week itself)
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      style={{ fontSize: "20px" }}
                    >
                      When will I receive the bonus?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      After the Workshop is done, you will receive all the
                      bonuses within 48 hours.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                      style={{ fontSize: "20px" }}
                    >
                      Is there any further mentoring after 5 Days Workshop?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Yes, there is a complete 12-Month Mentoring to 10X your
                      UPSC Preparation and increase your chances of getting
                      through the exam in your First Attempt itself.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                      style={{ fontSize: "20px" }}
                    >
                      Are these strategies and secrets available on the
                      internet?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      These strategies are available on internet not at one
                      place. Our team members have cleared Prelims themselves
                      and has analysed strategies of 500 people who cleared UPSC
                      and then made this activity based workshop
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container mb-5 mt-5">
          <div class="p-5 text-center  bg-danger rounded-5">
            <h1 class="text-white mt-5">
              Don't Miss the chance to clear Prelims 2024 in First Attempt
            </h1>
            <p class="lead text-white">
              <u>
                {" "}
                Only 5 months are left and if you don't join this workshop now,
                you won't be able to clear Prelims 2024
              </u>
            </p>

            <a
              class="d-inline-flex align-items-center btn btn-light btn-lg px-4 rounded-pill mb-5 mt-3"
              type="button"
              href="https://imjo.in/ZGha7v"
            >
              Join Prelims Booster Workshop for for ₹ 499
            </a>
          </div>
        </div>
      </section>
      <section className="pb-5">
        <div className="container px-4 pt-3" id="hanging-icons">
          <div className="text-center mb-4">
            <h1 class="display-4 fw-bold text-body-emphasis">
              Cost of <span className="text-danger">not joining</span> workshop?
            </h1>
          </div>
          <div class="row">
            <div className="col-sm-2"></div>
            <div class="col-sm-8 py-2 d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                fill="red"
                class="bi bi-x-square-fill  flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Face failures and decide to leave your Dream of becoming an
                  IAS
                </h3>
              </div>
            </div>

            <div className="col-sm-2"></div>
            <div className="col-sm-2"></div>

            <div class="col-sm-8 py-2 d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                fill="red"
                class="bi bi-x-square-fill  flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Stay under-confident about your capabilities and make
                  repetitive mistakes
                </h3>
              </div>
            </div>

            <div className="col-sm-2"></div>
            <div className="col-sm-2"></div>

            <div class="col-sm-8 py-2  d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                fill="red"
                class="bi bi-x-square-fill  flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Fail to manage the huge syllabus within timelines
                </h3>
              </div>
            </div>

            <div className="col-sm-2"></div>
            <div className="col-sm-2"></div>

            <div class="col-sm-8 py-2  d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                fill="red"
                class="bi bi-x-square-fill  flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Continue facing social pressures and mental stress resulting
                  sometimes into suicidal actions
                </h3>
              </div>
            </div>

            <div className="col-sm-2"></div>
            <div className="col-sm-2"></div>

            <div class="col-sm-8 py-2  d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                fill="red"
                class="bi bi-x-square-fill  flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Keep comparing yourself with others
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#F8F9FA" }}>
        <div className="container py-5">
          <div className="row align-items-center g-lg-5 py-5">
            <div className="col-sm-1"></div>
            <div className="col-sm-10 text-center text-center">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis mb-3">
                <span className="text-danger">Smart Aspirants</span> have
                already joined{" "}
                <span className="text-danger">Prelims Booster Workshop</span>
              </h1>
              <p className="fs-4">
                You can{" "}
                <span className="text-danger border-bottom border-danger fw-bold">
                  FAIL, WASTE A YEAR
                </span>{" "}
                and then come back to us
              </p>{" "}
              <h4>OR</h4>
              <p className="fs-4">
                be a SMART Aspirant and clear UPSC Prelims 2024 in{" "}
                <span className="text-danger border-bottom border-danger fw-bold">
                  FIRST ATTEMPT
                </span>
              </p>
              <button
                type="button"
                class="btn btn-outline-secondary btn-lg px-4 me-3"
              >
                Waste One Year
              </button>
              <a
                type="button"
                class="btn btn-danger btn-lg px-4 "
                href="https://imjo.in/ZGha7v"
              >
                Join Prelims Booster Workshop for ₹ 499
              </a>
            </div>
            <div className="col-sm-1"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrelimsWorkshop;
