import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import "./list-groups.css";
import MentorReviews from "./MentorReviews";
import PackRadio from "./PackRadio";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";

function convertTo12HourFormat(time) {
  const formattedHour = time % 12 || 12;
  const period = time >= 12 ? "PM" : "AM";
  return `${formattedHour} ${period}`;
}

const MentorProfileOneHour = () => {
  const { mentorId } = useParams();
  const [mentorDetails, setMentorDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { currentUser } = useAuth();
  const { navigate } = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDayDate, setSelectedDayDate] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const fetchMentorDetails = async () => {
      try {
        const response = await axios.get(apiUrl + `mentors/${mentorId}`);
        setMentorDetails(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching mentor details:", error);
        setIsLoading(false);
      }
    };

    fetchMentorDetails();
  }, [mentorId]);

  if (isLoading) {
    return (
      <div className="container">
        <p>Loading...</p>
      </div>
    );
  }

  if (!isLoading && !mentorDetails) {
    return (
      <div className="container">
        <p>No details available for this mentor</p>
      </div>
    );
  }

  const handleMentorshipButton = (event) => {
    if (selectedSlot) {
      setModalShow(true);
    } else {
      alert("Please select a time slot");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{mentorDetails.fullName} - Making UPSC Saral</title>
        <meta name="description" content={mentorDetails.bio} />
        <meta property="og:description" content={mentorDetails.bio} />
        <meta
          property="og:title"
          content={mentorDetails.fullName + " - Making UPSC Saral"}
        />
        <meta property="og:image" content={mentorDetails.profilePicture} />
        <meta
          property="og:url"
          content={
            "https://saralupsc.com/upsc-mentor/" +
            mentorDetails._id +
            "/" +
            mentorDetails.slug
          }
        />
      </Helmet>

      <section className="py-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              {" "}
              <div className="d-flex align-items-center mb-4">
                <div className="flex-shrink-0">
                  <img
                    className="rounded-circle"
                    src={mentorDetails.profilePicture}
                    alt=""
                    width="80px"
                    height="80px"
                  />
                </div>
                <div className="ms-3">
                  <h2 className="h3">
                    {mentorDetails.fullName}{" "}
                    {mentorDetails.recommended ? (
                      <span class="badge text-bg-danger">
                        {" "}
                        Recommended by SaralUPSC
                      </span>
                    ) : (
                      <></>
                    )}
                  </h2>
                  <p className="text-success fw-bold ">
                    {mentorDetails.prelimsCleared > 0 ? (
                      "Cleared UPSC Prelims " +
                      mentorDetails.prelimsCleared +
                      " times "
                    ) : (
                      <></>
                    )}
                    •
                    {mentorDetails.interviewsAppeared > 0 ? (
                      " Appeared in " +
                      mentorDetails.interviewsAppeared +
                      " Interviews"
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              </div>
              <p>
                <div dangerouslySetInnerHTML={{ __html: mentorDetails.bio }} />
              </p>
              <div className="card border-1 shadow-sm mb-2 mb-lg-4 p-lg-0">
                <div className="card-body p-5 pt-5">
                  <div className="row">
                    <div className="col-3">
                      <h5 className="text-danger">Expertise</h5>
                    </div>
                    <div className="col-9">
                      {mentorDetails.subjects.map((subject) => (
                        <small class="d-inline-flex mb-2 me-2 px-2 py-1 fw-semibold text-warning-emphasis bg-warning-subtle border border-warning-subtle rounded-2">
                          {subject}
                        </small>
                      ))}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-3">
                      <h5 className="text-danger">Fluent In</h5>
                    </div>
                    <div className="col-9">
                      {mentorDetails.languages.map((language) => (
                        <small class="d-inline-flex mb-2 me-2 px-2 py-1 fw-semibold text-warning-emphasis bg-warning-subtle border border-warning-subtle rounded-2">
                          {language}
                        </small>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <MentorReviews mentorId={mentorId} />
            </div>

            <div className="col-sm-5">
              <div className="d-flex flex-column flex-md-row gap-4 w-100 align-items-center justify-content-center sticky-top">
                <div className="list-group list-group-radio d-grid gap-2 border-0">
                  <Slots
                    mentorId={mentorId}
                    setSelectedSlot={setSelectedSlot}
                    selectedSlot={selectedSlot}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedDayDate={selectedDayDate}
                    setSelectedDayDate={setSelectedDayDate}
                  />
                  <p>* Prices are inclusive of GST</p>

                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={handleMentorshipButton}
                  >
                    Book Mentorship Session for ₹ {mentorDetails.pricePerHour}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container px-4 py-5" id="icon-grid">
          <h2 class="pb-2 border-bottom text-center">
            <span className="text-danger">Choose a Mentor</span> if you face
            these problems
          </h2>

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 py-5">
            <div class="col d-flex align-items-start">
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Can't keep up with Current Affairs?
                </h3>
                <p className="mt-2 text-danger">
                  Weekly Current Affair handouts and weekly classes.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Worried about exam readiness?
                </h3>
                <p className="mt-2 text-danger">
                  Smart AI Enabled Student Preparation Tracker.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Need feedback but no one to guide?
                </h3>
                <p className="mt-2 text-danger">
                  Access to Interview appeared and highly motivating Mentors.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Prelims/Mains approaching and feeling unprepared?
                </h3>
                <p className="mt-2 text-danger">
                  Demand oriented Prelims & Mains specific plans.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Struggling with unstructured studies?
                </h3>
                <p className="mt-2 text-danger">
                  Personalized Target Oriented Plan
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Unsure about answer writing?
                </h3>
                <p className="mt-2 text-danger">
                  Live Answer Writing Analysis with Mentors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LoginModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedSlot={selectedSlot}
        price={mentorDetails.pricePerHour}
        mentorName={mentorDetails.fullName}
        mentorId={mentorDetails._id}
        selectedDate={selectedDate}
        selectedDayDate={selectedDayDate}
      />
    </>
  );
};

const Slots = ({
  mentorId,
  setSelectedSlot,
  selectedSlot,
  selectedDate,
  setSelectedDate,
  setSelectedDayDate,
}) => {
  const [availableSlots, setAvailableSlots] = useState({});

  const handleDateClick = (day, dateY) => {
    setSelectedDate(day);
    setSelectedDayDate(dateY);
    setSelectedSlot(null); // Reset selected slot when changing the date
  };

  const handleSlotClick = (day, time) => {
    setSelectedSlot(`${day}-${time}`);
  };

  // Helper function to get day name from day index (0 to 6)
  const getDayName = (day) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[day];
  };

  // Helper function to get month name from month index (0 to 11)
  const getMonthName = (month) => {
    const months = [
      "Jan",
      "Febr",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month];
  };

  useEffect(() => {
    const fetchAvailableSlots = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(
          `${apiUrl}mentors/${mentorId}/available-slots`
        );
        setAvailableSlots(response.data);
      } catch (error) {
        console.error("Error fetching available slots:", error);
      }
    };

    fetchAvailableSlots();
  }, [mentorId]);

  if (Object.keys(availableSlots).length === 0) {
    return (
      <>
        <div class="spinner-border m-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h4 className="p-1">Availabile Sessions</h4>
          <small className="p-1">Book 1:1 one hour sessions with mentor</small>
          <div className="p-1 mt-2">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              {Object.keys(availableSlots).map(
                (day, dayIndex) =>
                  availableSlots[day].length > 0 && (
                    <div
                      key={dayIndex}
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        display: "inline-block",
                      }}
                    >
                      <input
                        type="radio"
                        className="btn-check visually-hidden"
                        name="options-base"
                        id={`option${day}`}
                        autoComplete="off"
                        checked={selectedDate === day}
                        onChange={() =>
                          handleDateClick(day, availableSlots[day][0].date)
                        }
                      />
                      <label
                        className={`btn ${
                          selectedDate === day
                            ? "btn-success"
                            : "btn-outline-secondary"
                        }`}
                        htmlFor={`option${day}`}
                      >
                        {getDayName(day)}
                        <br />
                        {new Date(availableSlots[day][0].date).getDate()}{" "}
                        {getMonthName(
                          new Date(availableSlots[day][0].date).getMonth()
                        )}{" "}
                      </label>
                    </div>
                  )
              )}
            </div>

            <p>Please select a time</p>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {selectedDate &&
                availableSlots[selectedDate].map((slot, slotIndex) => (
                  <div key={slotIndex} className="me-1 mb-1">
                    <input
                      type="radio"
                      className="btn-check visually-hidden"
                      name="options-base"
                      id={`option${selectedDate}-${slot.time}`}
                      autoComplete="off"
                      checked={selectedSlot === `${selectedDate}-${slot.time}`}
                      disabled={slot.status === "closed" ? true : false}
                      onChange={() => handleSlotClick(selectedDate, slot.time)}
                    />
                    <label
                      className={`btn me-2 mb-2 ${
                        slot.status === "closed "
                          ? "btn-danger"
                          : selectedSlot === `${selectedDate}-${slot.time}`
                          ? "btn-success"
                          : "btn-outline-secondary"
                      }`}
                      htmlFor={`option${selectedDate}-${slot.time}`}
                    >
                      {convertTo12HourFormat(slot.time)}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const LoginModal = ({
  show,
  onHide,
  selectedSlot,
  mentorName,
  mentorId,
  price,
  selectedDayDate,
}) => {
  const [isRegistered, setIsRegistered] = useState(false);
  const { currentUser } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegistration = async (formData) => {
    try {
      const response = await axios.post(
        `${apiUrl}student/register-student`,
        formData
      );
      console.log("Student registered:", response.data);
      setIsRegistered(true); // Set registration status to true upon successful registration
    } catch (error) {
      console.error("Error registering student:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="row h-100">
        <div className="col-sm-6" style={{ backgroundColor: "#FADBD8" }}>
          <center>
            <img
              src="/assets/images/SaralUPSC.png"
              className="img-fluid mt-2"
              width="100px"
            ></img>

            <p className="fw-bold mb-0">Crack UPSC in First Attempt</p>
            <p className="px-3">
              “The dream begins with a teacher who believes in you, who tugs and
              pushes and leads you to the next plateau, sometimes poking you
              with a sharp stick called truth.”
            </p>
            <img
              src="https://ias.prepp.in/svg/leadform-left.svg"
              className="img-fluid mb-4"
            ></img>
          </center>
        </div>
        <div className="col-sm-6 py-5 px-4">
          {!currentUser ? (
            !isRegistered ? (
              <RegisterForm
                onRegistration={handleRegistration}
                handleInputChange={handleInputChange}
                formData={formData}
              />
            ) : (
              <CheckoutForm
                selectedSlot={selectedSlot}
                mentorName={mentorName}
                mentorId={mentorId}
                studentPhone={formData.phone}
                onHide={onHide}
                price={price}
                selectedDayDate={selectedDayDate}
              />
            )
          ) : (
            <CheckoutForm
              selectedSlot={selectedSlot}
              mentorName={mentorName}
              mentorId={mentorId}
              studentPhone={formData.phone}
              onHide={onHide}
              price={price}
              selectedDayDate={selectedDayDate}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

const RegisterForm = ({ onRegistration, handleInputChange, formData }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);

  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();

    // Phone number validation regex pattern for Indian mobile numbers
    const phonePattern = /^[6-9]\d{9}$/;

    // Extracting the phone number from the form data
    const phoneNumber = formData.phone;

    // Check if the phone number matches the regex pattern
    if (!phonePattern.test(phoneNumber)) {
      // Handle invalid phone number
      alert("Please enter a valid Indian mobile number.");
      return; // Prevent further execution if the phone number is invalid
    }

    setIsLoading(true); // Set loading state to true upon form submission
    await onRegistration(formData); // Pass formData to the parent component's registration handler
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleRegistrationSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h5>Register to Buy </h5>
            {/*    <button
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button> */}
          </div>
          <label className="form-label" for="name">
            Full Name *
          </label>
          <input
            className="form-control form-control"
            id="name"
            type="text"
            name="name"
            placeholder="Your Full Name"
            onChange={handleInputChange} // Add onChange handler
            required
          />
        </div>

        <div className="col-12 mt-3">
          <label className="form-label" for="phone">
            Mobile Number *
          </label>
          <div class="input-group">
            <span class="input-group-text">+91</span>
            <input
              type="tel"
              class="form-control"
              placeholder="Your Mobile Number"
              name="phone"
              id="phone"
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="col-12 mt-3">
          <label className="form-label" for="phone">
            Email *
          </label>
          <input
            className="form-control form-control"
            id="email"
            type="email"
            name="email"
            placeholder="Your Email Address"
            onChange={handleInputChange} // Add onChange handler
            required
          />
        </div>

        <div className="col-12 mb-3 mt-3">
          <label className="form-label" for="email">
            City *
          </label>
          <input
            className="form-control form-control"
            id="city"
            type="text"
            name="city"
            placeholder="Your City"
            onChange={handleInputChange} // Add onChange handler
            required
          />
        </div>

        <div className="col-12 mt-3">
          <p>
            By clicking the Register button, you agree to SaralUPSC{" "}
            <a
              href="https://saralupsc.com/terms-and-conditions"
              target="_blank"
              className="text-danger"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://saralupsc.com/privacy-policy"
              className="text-danger"
              target="_blank"
            >
              Privacy Policy
            </a>
          </p>
          <button className="btn btn-lg btn-danger" disabled={isLoading}>
            {isLoading ? (
              <>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Register"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

const CheckoutForm = ({
  mentorName,
  mentorId,
  selectedSlot,
  price,
  studentPhone,
  onHide,
  selectedDayDate,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const initPayment = (data) => {
    const options = {
      key: "rzp_live_599rgFdKe7L8qT",
      amount: data.amount,
      currency: "INR",
      name: "SaralUPSC",
      description: `Mentorship Slot - ${selectedSlot} for ${mentorName} with ${mentorId} for ${price}`,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyUrl = apiUrl + "mentor-orders/verify-order";
          await axios
            .post(verifyUrl, response)
            .then((response) => response.data)
            .then((data) => {
              console.log(data);
              if (data.message === "Payment successful.") {
                navigate(`/mentorship-confirmation`);
              }
            });
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    try {
      if (currentUser) {
        const orderUrl = apiUrl + "mentor-orders/create-registered-order";

        currentUser.getIdToken().then(async (token) => {
          const payloadHeader = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          await axios
            .post(
              orderUrl,
              {
                mentorId: mentorId,
                price: price,
                date: selectedDayDate,
                time: selectedSlot.split("-")[1],
                mentorName: mentorName,
              },
              payloadHeader
            )
            .then((response) => response.data)
            .then((data) => {
              initPayment(data);
              onHide();
            })
            .catch((err) => {
              console.log(err.message);
              alert("Already Enrolled");
            });

          setIsProcessingPayment(true);
        });
      } else {
        const orderUrl = apiUrl + "mentor-orders/create-order";
        await axios
          .post(orderUrl, {
            mentorId: mentorId,
            price: price,
            date: selectedDayDate,
            time: selectedSlot.split("-")[1],
            mentorName: mentorName,
            studentPhone: studentPhone,
          })
          .then((response) => response.data)
          .then((data) => {
            initPayment(data);
            onHide();
          })
          .catch((err) => {
            console.log(err.message);
            alert("Already Enrolled");
          });

        setIsProcessingPayment(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessingPayment(false); // Reset processing payment state
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h5>Checkout </h5>
          <br />
          <div className="d-flex align-content-between flex-wrap">
            <div className="d-flex align-items-center justify-content-between mb-5">
              <div className="col-7">
                <small className="text-danger fw-bold">One Hour Session</small>{" "}
                <br />
                <b>Mentorship by {mentorName}</b>
                <br />
                <small>
                  {selectedDayDate},{" "}
                  {convertTo12HourFormat(selectedSlot.split("-")[1])}
                </small>
              </div>

              <div>
                <p className="mb-0">Total Charges</p>
                <h4>
                  <b>₹ {price}</b>
                </h4>
                <small>
                  Incl. GST(18%) <br />₹ {((price / 1.18) * 0.18).toFixed(2)}
                </small>
              </div>
            </div>
            {/*  <div className="mt-5">
              <a
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
                className="text-danger"
              >
                Have a coupon code?
              </a>
              <div class="collapse mt-3" id="collapseExample">
                <form class="card p-2">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Promo code"
                    />
                    <button type="submit" class="btn btn-danger">
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
            <button
              className="w-100 btn btn-danger btn-lg mt-5"
              onClick={handlePayment}
              disabled={isProcessingPayment} // Disable the button during payment processing
            >
              {isProcessingPayment ? ( // Display spinner if processing payment
                <>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <span className="visually-hidden ms-2">Processing...</span>
                </>
              ) : (
                <>Proceed to Pay ₹ {price}</>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorProfileOneHour;
