import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

const NavbarCompact = () => {
  const { currentUser } = useAuth();
  const [show, setShow] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleLogout = () => {
    logout();
    setShow(false);
    navigate("/");
  };

  const handleShow = () => setShow(true);

  return (
    <nav class="navbar bg-body-tertiary navbar-expand-md">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="/assets/icons/SaralUPSC.png" alt="Bootstrap" width="80" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
              <img
                src="/assets/icons/SaralUPSC.png"
                alt="Bootstrap"
                width="80"
              />
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-auto">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/tests/previous"
                  style={{ fontSize: "18px" }}
                >
                  Previous Year Papers
                </a>
              </li>
              <li className="nav-item dropdown px-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "18px" }}
                >
                  Prelims Practice Tests
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/tests/polity">
                      Polity
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/tests/economy">
                      Economy
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/tests/ancient-history">
                      Ancient History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/tests/medieval-history">
                      Medieval History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/tests/modern-history">
                      Modern History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/tests/geography">
                      Geography
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/tests/science">
                      Science
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/tests/environment">
                      Environment
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/tests/art-and-culture">
                      Art and Culture
                    </a>
                  </li>
                </ul>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/upsc-mentors"
                  style={{ fontSize: "18px" }}
                >
                  Find a Mentor
                </a>
              </li>
              {/* 
              <li className="nav-item dropdown  px-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "18px" }}
                >
                  Free Notes
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/articles/polity">
                      Polity
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/articles/economy">
                      Economy
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/articles/ancient-history"
                    >
                      Ancient History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/articles/medieval-history"
                    >
                      Medieval History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/articles/modern-history"
                    >
                      Modern History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/articles/geography">
                      Geography
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/articles/science">
                      Science
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/articles/environment">
                      Environment
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/articles/art-and-culture"
                    >
                      Art and Culture
                    </a>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item dropdown  px-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "18px" }}
                >
                  Preparation Tracks
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/track/polity">
                      Polity
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/track/economy">
                      Economy
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/track/ancient-history">
                      Ancient History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/track/medieval-history">
                      Medieval History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/track/modern-history">
                      Modern History
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/track/geography">
                      Geography
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/track/science">
                      Science
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/track/environment">
                      Environment
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item" href="/track/art-and-culture">
                      Art and Culture
                    </a>
                  </li>
                </ul>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/mains-questions"
                  style={{ fontSize: "18px" }}
                >
                  Mains Answer Writing
                </a>
              </li>
            </ul>

            {currentUser ? (
              <>
                <span className="navbar-text me-3">
                  {currentUser.phoneNumber}
                </span>

                <a
                  type="button"
                  href="/dashboard"
                  class="btn btn-outline-danger me-3"
                >
                  Dashboard
                </a>

                <button
                  className="btn btn-danger text-white me-3"
                  onClick={handleShow}
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <a
                  type="button"
                  href="https://forms.gle/X6WJW3aiGNprVgRNA"
                  target="_blank"
                  class="btn btn-outline-danger me-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-award"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                    <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                  </svg>{" "}
                  Join RANKONE Programme
                </a>
                <a className="btn btn-danger text-white me-3" href="/login">
                  Login
                </a>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to logout?</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Yes, Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </nav>
  );
};

export default NavbarCompact;
