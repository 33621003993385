import axios from "axios";
import React, { useEffect, useState } from "react";
import MainsQuestionTile from "./MainsQuestionTile";

const QuestionList = () => {
  const [questions, setQuestions] = useState([]);
  const [subjects, setSubjects] = useState([
    "GS Paper 1",
    "GS Paper 2",
    "GS Paper 3",
    "GS Paper 4",
    "Essay",
    "Polity",
    "History",
    "Economy",
    "Internal Security",
    "International Relations",
    "Science",
  ]); // List of subjects

  const apiUrl = process.env.REACT_APP_API_URL;
  const [page, setPage] = useState(1);
  const [hasMoreQuestions, setHasMoreQuestions] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState(""); // Selected subject
  const [selectedYear, setSelectedYear] = useState(""); // Selected year

  useEffect(() => {
    fetchQuestions(page, selectedSubject, selectedYear);
    // Fetch the list of subjects and years here if needed.
  }, [page, selectedSubject, selectedYear]);

  const fetchQuestions = async (pageNumber, subject, year) => {
    try {
      const response = await axios.get(`${apiUrl}mains-question`, {
        params: { page: pageNumber, subject, year },
      });
      if (response.data.questions.length === 0) {
        setHasMoreQuestions(false);
      } else {
        setQuestions((prevQuestions) => [
          ...prevQuestions,
          ...response.data.questions,
        ]);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleLoadMore = () => {
    if (hasMoreQuestions) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSubjectChange = (selectedSubject) => {
    setSelectedSubject(selectedSubject);
    // Reset page to 1 when subject changes
    setPage(1);
    // Clear the list of questions
    setQuestions([]);
  };

  const handleYearChange = (selectedYear) => {
    setSelectedYear(selectedYear);
    // Reset page to 1 when year changes
    setPage(1);
    // Clear the list of questions
    setQuestions([]);
  };

  const startYear = 2023;
  const endYear = 2000;

  const years = [];

  for (let year = startYear; year >= endYear; year--) {
    years.push(year);
  }

  return (
    <section>
      <div className="container mt-5">
        <div className="filter-container mb-3">
          <div className="d-flex align-items-center ">
            <div className="dropdown me-2">
              <button
                className="btn btn-secondary btn-sm dropdown-toggle"
                type="button"
                id="subjectDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {selectedSubject ? selectedSubject : "All Subjects"}
              </button>
              <ul className="dropdown-menu" aria-labelledby="subjectDropdown">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => handleSubjectChange("")}
                  >
                    All Subjects
                  </button>
                </li>
                {/* Render subjects based on your subject list */}
                {subjects.map((subject) => (
                  <li key={subject}>
                    <button
                      className="dropdown-item"
                      onClick={() => handleSubjectChange(subject)}
                    >
                      {subject}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="dropdown">
              <button
                className="btn btn-secondary btn-sm dropdown-toggle"
                type="button"
                id="yearDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {selectedYear ? selectedYear : "All Years"}
              </button>
              <ul className="dropdown-menu" aria-labelledby="yearDropdown">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => handleYearChange("")}
                  >
                    All Years
                  </button>
                </li>
                {/* Render years based on your year list */}
                {years.map((year) => (
                  <li key={year}>
                    <button
                      className="dropdown-item"
                      onClick={() => handleYearChange(year)}
                    >
                      {year}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="list-group">
          {questions.length === 0 ? (
            <>
              <MainsQuestionTile />
              <MainsQuestionTile />
              <MainsQuestionTile />
              <MainsQuestionTile />
              <MainsQuestionTile />
            </>
          ) : (
            questions.map((question) => (
              <MainsQuestionTile key={question.id} question={question} />
            ))
          )}
        </div>

        {!hasMoreQuestions ? (
          <div className="text-center mt-3">
            <button className="btn btn-secondary" disabled>
              No More Questions
            </button>
          </div>
        ) : (
          <div className="text-center mt-3">
            <button className="btn btn-primary" onClick={handleLoadMore}>
              Load More
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default QuestionList;
