import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";

const colors = ["#435EBE", "#56C9EA", "#EA9456"];

const StudentDonut = ({ high, medium, low }) => {
  const data = [
    { name: "Above 90% Prepared", value: high },
    { name: "More than 50%", value: medium },
    { name: "Less than 50% ", value: low },
  ];

  return (
    <ResponsiveContainer width="100%" height={280}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={60} // Set the inner radius for creating a donut chart
          outerRadius={80}
          fill="#42CB79"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => `${value}%`} />{" "}
        {/* Display tooltip with percentage */}
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default StudentDonut;
