import React from "react";
import { useParams } from "react-router-dom";

const MentorPaymentSuccess = ({ paymentId }) => {
  return (
    <>
      {!paymentId ? (
        <>
          {" "}
          <section className="py-5">
            <div className="container">
              <main>
                <h1 className="text-body-emphasis">
                  Thank you for joining the Mentorship Program
                </h1>
                {/*  <p className="text-danger">Payment Reference ID: </p> */}
                <p class="fs-5 fw-bold col-md-8">
                  Welcome to UPSC Mentorship Program. Your mentor will contact
                  you shortly.
                </p>
                <p>An invoice will be shared on your email soon. </p>
                <div class="mb-5">
                  <a href="/login" class="btn btn-danger btn-lg px-4">
                    Login Now
                  </a>

                  <p className="mt-3">
                    If you have any issue, please reach out to us at{" "}
                    <a href="mailto:team@saralupsc.com" className="text-danger">
                      team@saralupsc.com
                    </a>{" "}
                    or our mobile number at{" "}
                    <a href="tel:+91981027701" className="text-danger">
                      +91-98180-27701
                    </a>
                  </p>
                </div>
              </main>
            </div>
          </section>
        </>
      ) : (
        <>
          <section>
            <div class="d-flex align-items-center justify-content-center vh-100 bg-danger">
              <h1 class="display-1 fw-bold text-white">
                404. <br />
                This page does not exist.
              </h1>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MentorPaymentSuccess;
