import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import BlogPrelimQuestion from "./BlogPrelimQuestion";

const SingleBlogNew = () => {
  const { articleId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [blog, setBlog] = useState();

  useEffect(() => {
    axios
      .get(`${apiUrl}article/${articleId}`)
      .then((data) => data.data)
      .then((test) => {
        setBlog(test);
      })
      .catch((err) => console.log(err));
  }, []);

  if (!blog) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.meta_desc} />
        <meta property="og:description" content={blog.meta_desc} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:image" content={blog.poster} />
        <meta
          property="og:url"
          content={"https://saralupsc.com/article/" + blog._id}
        />
      </Helmet>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div
              className={blog.questions.length < 1 ? "col-sm-12" : "col-sm-8"}
            >
              <img
                src={blog.poster}
                className="img-fluid w-100 rounded shadow-sm "
                alt="Blog Poster"
              />
              <h1 className="mt-4">{blog.title}</h1>
              <div className="d-flex justify-content-between mt-4 align-items-center">
                <p>
                  {blog.user_created ? (
                    <>
                      <span style={{ color: "#9B2727" }}>
                        By {blog.user_created.name}
                      </span>{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-dot"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      </svg>
                    </>
                  ) : (
                    <></>
                  )}
                  <span>
                    Date Created : {new Date(blog.createdAt).toDateString()}
                  </span>
                </p>
                <p>
                  <a href="/blogs" className="text-decoration-none text-danger">
                    <span className="me-3">Share Now</span>{" "}
                    <img src="/assets/icons/share.png" alt="Share Icon" />
                  </a>
                </p>
              </div>
              <div
                className="row mt-4"
                style={{ fontSize: "20px" }}
                dangerouslySetInnerHTML={{ __html: blog.desc }}
              />
            </div>

            {blog.questions.length > 0 ? (
              <div className="col-sm-4 text-left hidden-sm">
                <div className="sticky-top">
                  <h3>Previous Year Prelims Questions</h3>

                  <div
                    className="accordion "
                    id="accordionPanelsStayOpenExample"
                  >
                    {blog.questions.length > 0 &&
                      blog.questions.map((question, i) => {
                        return (
                          <BlogPrelimQuestion index={i} question={question} />
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleBlogNew;
