import React from "react";

const TopicCard = ({ topic }) => {
  if (!topic) {
    return (
      <>
        <div
          className="card shadow-sm mt-3"
          style={{ backgroundColor: "#FAFAFA" }}
        >
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <img src="/assets/icons/polity.svg"></img>
                <div>
                  <p className="placeholder-wave">
                    <span className="placeholder "></span>
                  </p>
                </div>
              </div>

              <a
                className="btn btn-success disabled placeholder col-2"
                aria-disabled="true"
              ></a>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="card shadow-sm mt-3" style={{ backgroundColor: "#FAFAFA" }}>
      <div className="card-body">
        <div className="d-flex justify-content-between  align-items-center">
          <div className="d-flex justify-content-between  align-items-center">
            <img src="/assets/icons/polity.svg"></img>
            <div>
              <h4>{topic.name}</h4>
              <small className="mb-3 px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2">
                Important
              </small>
            </div>
          </div>

          <a
            href={"/topic-attempt/" + topic._id}
            type="button"
            className="btn btn-success"
          >
            Attempt Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopicCard;
