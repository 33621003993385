import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const CheckoutPage = () => {
  const { currentUser } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const registerStudent = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}student/register-student`,
        formData
      );
      console.log("Student registered:", response.data);
    } catch (error) {
      console.error("Error registering student:", error);
    }
  };

  const initPayment = (data) => {
    const options = {
      key: "rzp_test_nyworXzFtzzd48",
      amount: data.amount,
      currency: "INR",
      name: "SaralUPSC",
      description: "Test Transaction",
      order_id: data.id,
      handler: async (response) => {
        try {
          if (currentUser) {
            currentUser.getIdToken().then(async (token) => {
              const payloadHeader = {
                headers: {
                  Authorization: "Bearer " + token,
                },
              };

              const verifyUrl = apiUrl + "orders/verify";
              await axios
                .post(verifyUrl, response, payloadHeader)
                .then((response) => response.data)
                .then((data) => {
                  console.log(data);
                  if (data.message === "Payment successful.") {
                    navigate("/student-dashboard");
                  }
                });
              console.log(data);
            });
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    try {
      const orderUrl = apiUrl + "orders/create";

      if (currentUser) {
        currentUser.getIdToken().then(async (token) => {
          const payloadHeader = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          await axios
            .post(orderUrl, { items: "1" }, payloadHeader)
            .then((response) => response.data)
            .then((data) => {
              console.log(data);
              initPayment(data);
            })
            .catch((err) => {
              console.log(err.message);
              alert("Already Enrolled");
            });
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="py-5">
        <div className="container">
          <div class="text-center">
            <img
              class="d-block mx-auto mb-4"
              src="/docs/5.3/assets/brand/bootstrap-logo.svg"
              alt=""
              width="72"
              height="57"
            />
            <h2>Checkout form</h2>
            <p class="lead">
              Below is an example form built entirely with Bootstrap’s form
              controls. Each required form group has a validation state that can
              be triggered by attempting to submit the form without completing
              it.
            </p>
          </div>

          <div class="row g-5">
            <div class="col-md-5 col-lg-4 order-md-last">
              <h4 class="d-flex justify-content-between align-items-center mb-3">
                <span class="text-primary">Your cart</span>
                <span class="badge bg-primary rounded-pill">3</span>
              </h4>
              <ul class="list-group mb-3">
                <li class="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 class="my-0">Mentorship by Jay Sharma</h6>
                    <small class="text-body-secondary">IAS Choice </small>
                  </div>
                  <span class="text-body-secondary">$12</span>
                </li>

                <li class="list-group-item d-flex justify-content-between bg-body-tertiary">
                  <div class="text-success">
                    <h6 class="my-0">Promo code</h6>
                    <small>EXAMPLECODE</small>
                  </div>
                  <span class="text-success">−$5</span>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <span>Total (INR)</span>
                  <strong>20000</strong>
                </li>
              </ul>

              <form class="card p-2">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Promo code"
                  />
                  <button type="submit" class="btn btn-secondary">
                    Redeem
                  </button>
                </div>
              </form>

              <button class="w-100 btn btn-primary btn-lg" type="submit">
                Proceed to Payment
              </button>
            </div>
            <div class="col-md-7 col-lg-8">
              <h4 class="mb-3">Your Details</h4>
              <form class="needs-validation" novalidate="">
                <div class="row g-3">
                  <div class="col-sm-6">
                    <label for="firstName" class="form-label">
                      First name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="firstName"
                      placeholder=""
                      value=""
                      required=""
                    />
                    <div class="invalid-feedback">
                      Valid first name is required.
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <label for="lastName" class="form-label">
                      Last name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      placeholder=""
                      value=""
                      required=""
                    />
                    <div class="invalid-feedback">
                      Valid last name is required.
                    </div>
                  </div>

                  <div class="col-6">
                    <label for="email" class="form-label">
                      Email
                    </label>
                    <div class="input-group has-validation">
                      <span class="input-group-text">@</span>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="email"
                        required=""
                      />
                      <div class="invalid-feedback">
                        Your Email is required.
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <label for="email" class="form-label">
                      Email <span class="text-body-secondary">(Optional)</span>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="you@example.com"
                    />
                    <div class="invalid-feedback">
                      Please enter a valid email address for shipping updates.
                    </div>
                  </div>

                  <div class="col-md-4">
                    <label for="state" class="form-label">
                      State
                    </label>
                    <select class="form-select" id="state" required="">
                      <option value="">Choose...</option>
                      <option>California</option>
                    </select>
                    <div class="invalid-feedback">
                      Please provide a valid state.
                    </div>
                  </div>

                  <div class="col-md-3">
                    <label for="zip" class="form-label">
                      Zip
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="zip"
                      placeholder=""
                      required=""
                    />
                    <div class="invalid-feedback">Zip code required.</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckoutPage;
