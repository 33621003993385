import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import SingleTest from "./SingleTest";

const AttemptedTests = () => {
  const [reports, setReports] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const { currentUser } = useAuth();
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    fetchMyReports(newPage);
    setCurrentPage(newPage);
  };

  const fetchMyReports = async (page) => {
    try {
      setIsLoading(true);
      if (currentUser) {
        const token = await currentUser.getIdToken();
        const payloadHeader = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        const filters = {
          subjects: selectedSubjects,
          createdAt: selectedDate,
        };

        const queryParams = new URLSearchParams(filters);
        const apiUrl = process.env.REACT_APP_API_URL; // Replace with your API URL
        const response = await fetch(
          `${apiUrl}my-recent-tests?page=${page}&${queryParams.toString()}`,
          payloadHeader
        );
        const data = await response.json();
        setReports(data.result);
        setPageCount(data.totalPageCount);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMyReports(currentPage); // Fetch initial reports
  }, [selectedSubjects, selectedDate, currentPage]);

  // Function to handle subject selection
  const handleSubjectSelection = (subject) => {
    if (selectedSubjects.includes(subject)) {
      setSelectedSubjects(selectedSubjects.filter((s) => s !== subject));
    } else {
      setSelectedSubjects([...selectedSubjects, subject]);
    }
  };

  // Function to handle date selection
  const handleDateSelection = (date) => {
    setSelectedDate(date);
  };

  // Function to remove selected subjects
  const removeSelectedSubject = (subject) => {
    setSelectedSubjects(selectedSubjects.filter((s) => s !== subject));
  };

  // Function to clear selected date
  const clearSelectedDate = () => {
    setSelectedDate(null);
  };

  if (error || !reports.map) {
    return <p className="text-danger">There is some error in fetching tests</p>;
  }

  const options = [
    { value: "Polity", label: "Polity" },
    { value: "Economy", label: "Economy" },
    { value: "Ancient History", label: "Ancient History" },
    { value: "Environment", label: "Environment" },
  ];

  return (
    <>
      <h3>Attempted Tests</h3>
      {/* filters */}
      <div className="d-flex justify-content-between">
        {/*   <div>
          <div className="d-flex justify-content-between">
            {selectedSubjects.length > 0 && (
              <div className="d-flex" style={{ marginRight: "12px" }}>
                {selectedSubjects.map((subject) => (
                  <div
                    key={subject}
                    className="px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 align-items-center d-flex"
                    style={{ marginRight: "5px" }}
                    onClick={() => removeSelectedSubject(subject)}
                  >
                    {subject}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "10px" }}
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </div>
                ))}
              </div>
            )}

            {selectedDate && (
              <div className="d-flex" style={{ marginRight: "12px" }}>
                <div
                  className="px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 align-items-center d-flex"
                  style={{ marginRight: "5px" }}
                  onClick={() => clearSelectedDate()}
                >
                  {selectedDate}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "10px" }}
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div> */}

        {/* filters */}
        {/*      <div className="d-flex justify-content-between">
          <div className="dropdown me-3">
            <button
              className="btn bg-success-subtle border border-success-subtle dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Subject
            </button>
            <ul className="dropdown-menu">
              {options.map((option) => (
                <li key={option.value}>
                  <button
                    className="dropdown-item"
                    onClick={() => handleSubjectSelection(option.value)}
                  >
                    {option.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="dropdown">
            <button
              className="btn bg-success-subtle border border-success-subtle dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selectedDate || "Date Attempted"}
            </button>
            <ul className="dropdown-menu">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleDateSelection("Today")}
                >
                  Today
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleDateSelection("Yesterday")}
                >
                  Yesterday
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleDateSelection("Last 7 Days")}
                >
                  Last 7 Days
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleDateSelection("Last 30 Days")}
                >
                  Last 30 Days
                </button>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
      {/* reports */}
      <div className="mt-3">
        {/* Display loading message or SingleTest component */}
        {isLoading ? (
          <p>
            {" "}
            <SingleTest />
          </p>
        ) : reports.length === 0 ? (
          <p>You have not attempted any tests.</p>
        ) : (
          reports.map((report) => <SingleTest data={report} key={report._id} />)
        )}

        <nav aria-label="...">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange("previous")}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: pageCount }, (_, index) => (
              <li
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                key={index}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange("next")}
                disabled={currentPage === pageCount}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default AttemptedTests;
