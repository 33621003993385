import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import AttemptedTests from "./AttemptedTests";
import MainsAnswerList from "./MainsAnswerList";
import PreparationAnalytics from "./PreparationAnalytics";
import PreparationScore from "./PreparationScore";

const Dashboard = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === null) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const redirectType = localStorage.getItem("redirectType");
    if (redirectType === "prelims") {
      const report = JSON.parse(localStorage.getItem("report"));
      console.log(report);
      if (report) {
        currentUser.getIdToken().then((token) => {
          const payloadHeader = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          const apiUrl = process.env.REACT_APP_API_URL;

          const url = apiUrl + "submit-test/" + report.testId;

          axios
            .post(url, report, payloadHeader)
            .then((response) => response.data)
            .then((progress) => {
              localStorage.removeItem("report");
              localStorage.removeItem("redirectType");
              console.log(progress);
              navigate("/report/" + progress._id);
            })
            .catch((error) => {
              console.log(error);
              navigate("/dashboard");
            });
        });
      }
    }
  }, []);

  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-12">
                <PreparationScore />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12">
                <AttemptedTests />
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="sticky-top">
              <div className="row">
                <div className="col-sm-12">
                  {/*   <PreparationAnalytics /> */}
                  <MainsAnswerList />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-12">
                  <img
                    src="/assets/images/rankonebanner.jpeg"
                    className="img-fluid"
                    width={"100%"}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
