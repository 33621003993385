import React from "react";

const TestCard = ({ test }) => {
  if (!test) {
    return (
      <div className="col">
        <div className="card" aria-hidden="true">
          <svg
            className="bd-placeholder-img card-img-top"
            width="100%"
            height="180"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Placeholder"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#868e96"></rect>
          </svg>
          <div className="card-body">
            <div className="h5 card-title placeholder-glow">
              <span className="placeholder col-6"></span>
            </div>
            <p className="card-text placeholder-glow">
              <span className="placeholder col-7"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-6"></span>
              <span className="placeholder col-8"></span>
            </p>
            <a className="btn btn-primary disabled placeholder col-6"></a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col">
      <div className="card mb-3 h-100">
        <img
          src={
            test.poster
              ? test.poster
              : "https://static.toiimg.com/thumb/msid-100588009,width-400,resizemode-4/100588009.jpg"
          }
          className="card-img-top img-fluid px-3 pt-3  rounded"
          alt="..."
          style={{ maxHeight: "170px" }}
        />
        <div className="card-body p-3">
          <h4 className="card-title">{test.name}</h4>
          <p style={{ color: "#800080" }}>AIR 5 attempted this</p>
          <small className="text-body-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-clock"
              viewBox="0 0 16 16"
            >
              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
            </svg>{" "}
            Time : {test.questions.length * 2} Minutes
          </small>
          <br />
          <small className="text-body-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-clock"
              viewBox="0 0 16 16"
            >
              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
            </svg>{" "}
            Total Question : {test.questions.length} Questions
          </small>
          <a className="btn btn-danger w-100 mt-2" href={"/test/" + test._id}>
            Attempt Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default TestCard;
