import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import ScoreColor from "../ScoreColor";
import FocusChart from "./FocusChart";
import NextTopics from "./NextTopics";
import ReadingList from "./ReadingList";
import ReportAnalytics from "./ReportAnalytics";
import StudentComparison from "./StudentComparison";

const TopicReport = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [data, setData] = useState(null);

  const params = useParams();
  const reportId = params.reportId;
  const { currentUser } = useAuth();

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      const payloadHeader = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .get(
          apiUrl + "topic-reports/complete-report/" + reportId,
          payloadHeader
        )
        .then((response) => response.data)
        .then((data) => {
          setData(data);
        });
    });
  }, []);

  if (!data) {
    return (
      <>
        <section>
          <div className="container mt-5">
            <center>
              <h1 class="placeholder-glow">
                <span class="placeholder col-12"></span>
              </h1>
              <p
                style={{ fontSize: "26px", color: "#828282" }}
                className="fw-light text-warning"
              >
                <u>
                  <span className="text-danger">Micro Analysis</span> of your
                  Preparation to make{" "}
                  <span className="text-danger">UPSC Saral</span>
                </u>
              </p>
            </center>
          </div>
        </section>

        <section>
          <div className="container mt-5">
            <div className="row">
              <div className="col-sm-8">
                <ReportAnalytics />

                <div className="row mt-3">
                  <div className="col-12">
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <h4 className="mb-0">
                          <u>Focus Area</u>
                        </h4>
                        <p class="placeholder-glow">
                          <span class="placeholder col-12"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <StudentComparison />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-3 mb-3">
            <div className="row">
              <div className="col-sm-6">
                <NextTopics />
              </div>

              <div className="col-sm-6">
                <ReadingList />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  const report = data.report;

  const questionsAttempted = report.answers.filter(
    (answer) => answer !== -1
  ).length;

  // Filter out the incorrectly answered questions
  const wrongQuestions = report.answers
    .map((answer, index) =>
      answer !== report.topicTest.questions[index].correct ? index : -1
    )
    .filter((index) => index !== -1);

  // Create a single array of blogs for incorrectly answered questions
  const readingList = [].concat.apply(
    [],
    wrongQuestions.map((questionIndex) => {
      const question = report.topicTest.questions[questionIndex];
      if (question && question.blogs) {
        return question.blogs;
      }
      return [];
    })
  );

  // Create a focus array of objects
  const focus = [
    { name: "Least Important", value: 1 },
    { name: "Important", value: 1 },
    { name: "Most Important", value: 1 },
  ];

  report.topicTest.questions.forEach((question, index) => {
    if (report.answers[index] === question.correct) {
      // Question is answered correctly
      const weight = question.weight;
      if (weight >= 0 && weight < 0.33) {
        focus[0].value++;
      } else if (weight >= 0.33 && weight < 0.67) {
        focus[1].value++;
      } else if (weight >= 0.67 && weight <= 1) {
        focus[2].value++;
      }
    }
  });

  // Create a focusComment variable with detailed suggestions
  let focusComment = "You have a balance between all importance levels.";
  if (focus[2].value > 0) {
    focusComment =
      "You are focusing on the most important topics, which is good. However, remember to review other areas to ensure comprehensive knowledge.";
  } else if (focus[1].value > 0 && focus[0].value === 0) {
    focusComment =
      "You are giving equal attention to both important and least important topics. To improve, it is advised to prioritize high-importance areas and allocate more time to mastering those core concepts.";
  } else if (focus[1].value > 0 && focus[0].value > 0) {
    focusComment =
      "You are covering important and least important topics, but consider focusing more on core concepts. It's essential to read and understand the core of each topic.";
  } else if (focus[0].value > 0 && focus[2].value === 0) {
    focusComment =
      "You are primarily focusing on the least important topics. While it's good to have knowledge in all areas, it's recommended to allocate more time to studying core concepts to improve your overall performance.";
  } else if (focus[0].value > 0 && focus[1].value === 0) {
    focusComment =
      "You are heavily focusing on the least important topics and missing out on important concepts. It's crucial to balance your study by allocating more time to important topics.";
  } else if (focus[1].value === 0 && focus[2].value === 0) {
    focusComment =
      "You are not giving adequate attention to either important or most important topics. It is advised to prioritize and allocate more time to these areas to improve your performance.";
  } else if (focus[0].value === 0 && focus[2].value === 0) {
    focusComment =
      "You are not focusing on the most important topics and are missing out on core knowledge. It's recommended to allocate more time to master the core concepts in each topic.";
  }

  return (
    <>
      <section>
        <div className="container mt-5">
          <center>
            <h1>
              You are{" "}
              <ScoreColor
                score={report.knowledge_score > 0 ? report.knowledge_score : 0}
              />{" "}
              prepared in {report.topicTest.name}
            </h1>
            <p
              style={{ fontSize: "26px", color: "#828282" }}
              className="fw-light text-warning"
            >
              <u>
                <span className="text-danger">Micro Analysis</span> of your
                Preparation to make{" "}
                <span className="text-danger">UPSC Saral</span>
              </u>
            </p>
          </center>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-sm-8">
              <ReportAnalytics
                accuracy={report.accuracy}
                time={report.time}
                knowledge_score={(report.knowledge_score * 100).toFixed(2)}
              />

              <div className="row mt-3">
                <div className="col-sm-6">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h4 className="mb-0">
                        <u>Focus Area</u>
                      </h4>
                      <p className="mt-0 fw-bold text-danger">{focusComment}</p>
                      <FocusChart data={focus} />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <StudentComparison
                    high={data.studentsWithHighKnowledge}
                    medium={data.studentsWithMediumKnowledge}
                    low={data.studentsWithLowKnowledge}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <ReadingList readingList={readingList} />
            </div>
          </div>
        </div>
      </section>

      <div class="bg-warning text-secondary px-4 py-5 mt-5 mb-0 text-center">
        <div class="py-5">
          <h1 class="display-5 fw-bold text-white">
            Find a Mentor and Crack UPSC in First Attempt
          </h1>
          <div class="col-lg-6 mx-auto">
            <p class="fs-5 mb-4 text-danger fw-bold">
              A mentor can make preparation journey easy by telling you about
              tips and tricks that can be used to score better.
            </p>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <button
                type="button"
                class="btn btn-outline-danger btn-lg px-4 me-sm-3 fw-bold"
              >
                Find a UPSC Mentor Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*   <section>
        <div className="container mt-3 mb-3">
          <div className="row">
            <div className="col-sm-6">
              <NextTopics topics={data.nextTopics} />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default TopicReport;
