import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Function to generate stars based on average rating
const generateStars = (avgRating) => {
  const stars = [];
  const totalStars = 5; // Total number of stars
  const roundedRating = Math.round(avgRating * 2) / 2; // Round the rating to nearest 0.5

  for (let i = 1; i <= totalStars; i++) {
    if (i <= roundedRating) {
      // Full star
      stars.push(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
      );
    } else if (i - 0.5 === roundedRating) {
      // Half-filled star
      stars.push(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-star-half"
          viewBox="0 0 16 16"
        >
          <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" />
        </svg>
      );
    } else {
      // Empty star
      stars.push(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-star"
          viewBox="0 0 16 16"
        >
          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
        </svg>
      );
    }
  }

  return stars;
};

const MentorCard = ({ mentor }) => {
  if (!mentor) {
    return (
      <>
        {" "}
        <div class="col">
          <div class="card" aria-hidden="true">
            <svg
              class="bd-placeholder-img card-img-top"
              width="100%"
              height="180"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
            </svg>
            <div class="card-body">
              <h5 class="card-title placeholder-glow">
                <span class="placeholder col-6"></span>
              </h5>
              <p class="card-text placeholder-glow">
                <span class="placeholder col-7"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-6"></span>
                <span class="placeholder col-8"></span>
              </p>
              <a
                class="btn btn-primary disabled placeholder col-6"
                aria-disabled="true"
              ></a>
            </div>
          </div>
        </div>
      </>
    );
  }

  const tooltip = (
    <Tooltip id="tooltip-text">Mentor recommended by SaralUPSC</Tooltip>
  );

  const avgRating = mentor.avgRating;
  const apiUrl = process.env.REACT_APP_API_URL; // Replace with your API URL

  return (
    <div class="col">
      <div class="card h-100">
        <img
          src={
            mentor.profilePicture
              ? mentor.profilePicture
              : "https://thumbs.dreamstime.com/b/businessman-profile-icon-male-portrait-flat-design-vector-illustration-47075253.jpg"
          }
          class="card-img-top"
          height="200px"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://thumbs.dreamstime.com/b/businessman-profile-icon-male-portrait-flat-design-vector-illustration-47075253.jpg"; // Replace with a broken image URL
            e.target.alt = "UPSC mentor image";
          }}
        />

        <div class="card-body">
          <h5 class="card-title text-danger">
            {mentor.fullName}

            {mentor.recommended ? (
              <OverlayTrigger placement="top" overlay={tooltip}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-check-circle-fill ms-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </OverlayTrigger>
            ) : (
              <></>
            )}
          </h5>
          <p className="text-success fw-bold">
            {mentor.prelimsCleared > 0 ? (
              mentor.prelimsCleared + " Prelims Cleared"
            ) : (
              <></>
            )}

            {mentor.interviewsAppeared > 0 ? (
              " • Appeared in " + mentor.interviewsAppeared + " UPSC Interview"
            ) : (
              <></>
            )}
          </p>
          {avgRating > 3 ? (
            <p>
              <span className="fw-bold text-danger">Rating</span>
              <div>{generateStars(avgRating)}</div>
            </p>
          ) : (
            <></>
          )}

          {mentor.subjects.length > 0 ? (
            <>
              Specializes in : <br />
              {mentor.subjects.map((subject) => {
                return (
                  <span class="badge text-bg-primary me-2">{subject}</span>
                );
              })}
              <br />
            </>
          ) : (
            <></>
          )}

          <a
            href={"/upsc-mentor/" + mentor._id + "" + mentor.slug}
            class="btn btn-danger mt-3"
          >
            View Mentor Profile
          </a>
        </div>
      </div>
    </div>
  );
};

export default MentorCard;
