import React from "react";

const NextBlogTile = ({ blog }) => {
  if (!blog) {
    return (
      <div className="mt-3 d-flex justify-content-between">
        <div>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>

          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>

          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
        </div>
        <img
          src="https://t3.ftcdn.net/jpg/02/77/73/80/360_F_277738069_f6WVe12MFONDXmeD2YKlglUaryF8V461.jpg"
          className="img-fluid rounded shadow"
          width="100px"
        ></img>
      </div>
    );
  }
  return (
    <div>
      <div className="mt-3 d-flex justify-content-between">
        <div>
          <p className="text-danger mb-0 fw-bold">Must Read</p>
          <a href={"/article/" + blog._id} className="mb-0">
            <h4>{blog.title}</h4>
          </a>
          <p className="text-danger">
            Asked {blog.questions.length} times in Prelims
          </p>
        </div>
        <a href={"/article/" + blog._id}>
          <img
            src={blog.poster}
            className="img-fluid rounded shadow"
            width="100px"
          ></img>
        </a>
      </div>
      <hr className="text-danger" />
    </div>
  );
};

const ReadingList = ({ readingList }) => {
  if (!readingList) {
    return (
      <div className="card shadow-sm h-100">
        <div className="card-body">
          <h4>
            <u>Recommended Reading</u>
          </h4>

          <NextBlogTile />
          <NextBlogTile />
          <NextBlogTile />
          <NextBlogTile />
        </div>
      </div>
    );
  }

  return (
    <div className="card shadow-sm h-100">
      <div className="card-body">
        <h4>
          <u>Recommended Reading</u>
        </h4>
        {readingList.map((blog) => (
          <NextBlogTile blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default ReadingList;
