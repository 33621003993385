import React from "react";
import Container from "react-bootstrap/Container";

const TermsAndConditions = () => {
  return (
    <Container>
      <h1 className="my-4">Terms and Conditions</h1>
      <p>
        Welcome to SaralUPSC ("us", "we", or "our"). By accessing our website
        and using our services, you agree to comply with and be bound by the
        following terms and conditions:
      </p>
      <h4>Welcome to SaralUPSC</h4>
      <p>
        These terms and conditions outline the rules and regulations for the use
        of SaralUPSC’s Website, located at{" "}
        <a href="https://saralupsc.com">https://saralupsc.com</a>.
      </p>
      <p>
        By accessing this website, we assume you accept these terms and
        conditions. Do not continue to use{" "}
        <a href="https://saralupsc.com">https://saralupsc.com</a> if you do not
        agree to take all of the terms and conditions stated on this page.
      </p>
      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement, and Disclaimer Notice and all Agreements: “Client”, “You” and
        “Your” refers to you, the person who logs on this website and complies
        with the Company’s terms and conditions. “The Company”, “Ourselves”,
        “We”, “Our”, and “Us” refer to our Company. “Party”, “Parties”, or “Us”
        refer to both the Client and ourselves. All terms refer to the offer,
        acceptance, and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client’s needs in respect of
        provision of the Company’s stated services, in accordance with and
        subject to the prevailing law of Netherlands. Any use of the above
        terminology or other words in the singular, plural, capitalization,
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to the same.
      </p>
      <h4>Cookies</h4>
      <p>
        We employ the use of cookies. By accessing{" "}
        <a href="https://saralupsc.com">https://saralupsc.com</a>, you agree to
        use cookies in agreement with SaralUPSC’s Privacy Policy.
      </p>
      <p>
        Most interactive websites use cookies to let us retrieve the user’s
        details for each visit. Cookies are used by our website to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use
        cookies.
      </p>
      <h4>License</h4>
      <p>
        Unless otherwise stated, SaralUPSC and/or its licensors own the
        intellectual property rights for all material on{" "}
        <a href="https://saralupsc.com">https://saralupsc.com</a>. All
        intellectual property rights are reserved. You may access this from{" "}
        <a href="https://saralupsc.com">https://saralupsc.com</a> for your own
        personal use subjected to restrictions set in these terms and
        conditions.
      </p>
      <p>You must not:</p>
      <ul>
        <li>
          Republish material from{" "}
          <a href="https://saralupsc.com">https://saralupsc.com</a>
        </li>
        <li>
          Sell, rent, or sub-license material from{" "}
          <a href="https://saralupsc.com">https://saralupsc.com</a>
        </li>
        <li>
          Reproduce, duplicate, or copy material from{" "}
          <a href="https://saralupsc.com">https://saralupsc.com</a>
        </li>
        <li>
          Redistribute content from{" "}
          <a href="https://saralupsc.com">https://saralupsc.com</a>
        </li>
      </ul>
      <p>This Agreement shall begin on the date hereof.</p>
      <h4>Disclaimer</h4>
      <p>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties, and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will:
      </p>
      <ul>
        <li>
          limit or exclude our or your liability for death or personal injury;
        </li>
        <li>
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </li>
        <li>
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </li>
        <li>
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </li>
      </ul>
      <p>
        The limitations and prohibitions of liability set in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer,
        including liabilities arising in contract, in tort, and for breach of
        statutory duty.
      </p>
      <p>
        As long as the website and the information and services on the website
        are provided free of charge, we will not be liable for any loss or
        damage of any nature.
      </p>
      <h4>iFrames</h4>{" "}
      <p>
        Without prior approval and written permission, you may not create frames
        around our Webpages that alter in any way the visual presentation or
        appearance of our Website.
      </p>
      <h4>Content Liability</h4>
      <p>
        We shall not be hold responsible for any content that appears on your
        Website. You agree to protect and defend us against all claims that is
        rising on your Website. No link(s) should appear on any Website that may
        be interpreted as libelous, obscene or criminal, or which infringes,
        otherwise violates, or advocates the infringement or other violation of,
        any third party rights.
      </p>
      <h4>Reservation of Rights</h4>
      <p>
        We reserve the right to request that you remove all links or any
        particular link to our Website. You approve to immediately remove all
        links to our Website upon request. We also reserve the right to amen
        these terms and conditions and it’s linking policy at any time. By
        continuously linking to our Website, you agree to be bound to and follow
        these linking terms and conditions.
      </p>
      <h4>Removal of links from our website</h4>
      <p>
        If you find any link on our Website that is offensive for any reason,
        you are free to contact and inform us any moment. We will consider
        requests to remove links but we are not obligated to or so or to respond
        to you directly. We do not ensure that the information on this website
        is correct, we do not warrant its completeness or accuracy; nor do we
        promise to ensure that the website remains available or that the
        material on the website is kept up to date.
      </p>
    </Container>
  );
};

export default TermsAndConditions;
