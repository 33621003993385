import React, { useState } from "react";

const BlogPrelimQuestion = ({ index, question }) => {
  const [answer, setAnswer] = useState(-1);

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    setAnswer(selectedOption === question.options[question.correct] ? 1 : 0);
  };

  return (
    <div className="accordion-item mt-2 rounded border">
      <h2 className="accordion-header" id={`questionHeading${index}`}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#questionCollapse${index}`}
          aria-expanded="true"
          aria-controls={`questionCollapse${index}`}
        >
          <div className="d-flex flex-column">
            {question.year && (
              <p>
                <span style={{ color: "#9B2727" }}>
                  Asked in {question.year}
                </span>
              </p>
            )}
            <div dangerouslySetInnerHTML={{ __html: question.statement }} />
          </div>
        </button>
      </h2>
      <div
        id={`questionCollapse${index}`}
        className="accordion-collapse collapse show"
        aria-labelledby={`questionHeading${index}`}
      >
        <div className="accordion-body">
          {answer === 1 && (
            <div className="alert alert-success" role="alert">
              You answered this correctly!
            </div>
          )}
          {answer === 0 && (
            <div className="alert alert-danger" role="alert">
              You answered this incorrectly!
            </div>
          )}
          {question.options.map((option, optionIndex) => (
            <div className="form-check" key={optionIndex}>
              <input
                className="form-check-input"
                type="radio"
                name={`questionOptions${index}`}
                id={`questionOption${index}_${optionIndex}`}
                value={option}
                onChange={handleOptionChange}
              />
              <label
                className="form-check-label"
                htmlFor={`questionOption${index}_${optionIndex}`}
              >
                {option}
              </label>
            </div>
          ))}
          {answer !== -1 && (
            <>
              <p className="mt-2">
                <b>
                  <span className="text-primary">Correct Option is</span>:{" "}
                  {question.options[question.correct]}
                </b>
              </p>
              {question.explanation ? (
                <>
                  <button
                    type="button"
                    className="btn btn-warning"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapseExample" + question._id}
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    style={{ backgroundColor: "#9B2727", color: "white" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-key-fill me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>
                    See Explanation
                  </button>

                  <div
                    className="collapse mt-2"
                    id={"collapseExample" + question._id}
                  >
                    <div className="card card-body">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.explanation,
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogPrelimQuestion;
