import React from "react";
import { Link } from "react-router-dom";
import CountdownTimer from "./CountdownTimer";
import SingleQuestion from "./SingleQuestion";

const TestReportLimited = () => {
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <h3 className="mb-4">Test Overview</h3>
              <div className="card sticky-top">
                <div className="card-body">
                  <div className="text-center">
                    <img
                      src="/assets/icons/trophy.svg"
                      className="img mt-3 mb-3"
                      alt="Trophy"
                    ></img>
                    <h5>Congratulation !</h5>
                    <a href="/upgrade">
                      <h3>Upgrade to see your score</h3>
                    </a>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-around">
                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/qa.svg"
                          className="img-fluid"
                          width="40px"
                          alt="total questions"
                        ></img>
                        <h4 className="ms-2">XX</h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Total Ques
                      </p>
                    </div>

                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/Checkmark.svg"
                          className="img-fluid"
                          width="40px"
                          alt="correct questions"
                        ></img>
                        <h4 className="ms-2">XX</h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Correct
                      </p>
                    </div>

                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/cross.svg"
                          className="img-fluid"
                          width="40px"
                          alt="wrong questions"
                        ></img>
                        <h4 className="ms-2">XX</h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Wrong
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-around mt-4">
                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/target.svg"
                          className="img-fluid"
                          width="40px"
                          alt="accuracy"
                        ></img>
                        <h4 className="ms-2">XX %</h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Accuracy
                      </p>
                    </div>

                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/clock.svg"
                          className="img-fluid"
                          width="40px"
                          alt="time taken"
                        ></img>
                        <h4 className="ms-2">XX</h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Time Taken
                      </p>
                    </div>
                  </div>

                  <center>
                    <button className="btn btn-danger">Attempt Again</button>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="d-flex justify-content-between">
                <h3 className="mb-4"></h3>

                <button
                  type="button"
                  className="btn btn-warning dropdown-toggle mb-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All Questions
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button className="dropdown-item">All Questions</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Correct</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Incorrect</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Unanswered</button>
                  </li>
                </ul>
              </div>

              <div className="card bg-light mb-3">
                <div className="card-body p-5">
                  <center>
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="64"
                        height="64"
                        fill="currentColor"
                        class="bi bi-file-earmark-lock2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0zM7 7v1h2V7a1 1 0 0 0-2 0z" />
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                      </svg>
                    </p>
                    <h1 className="mb-2 text-danger">
                      Buy RANKONE Test Series to see your Report
                    </h1>
                    <blockquote class="blockquote">
                      <p>
                        Arise, awake, and, stop not till the goal is reached
                      </p>
                    </blockquote>
                    <figcaption class="blockquote-footer">
                      Swami Vivekananda
                    </figcaption>

                    <CountdownTimer />

                    <a
                      class="d-inline-flex align-items-center btn btn-warning btn-lg px-4 rounded-pill "
                      href="https://imjo.in/z9qpgK"
                      target="_blank"
                    >
                      UPGRADE NOW FOR JUST Rs. 1,999 <strike>Rs. 9999</strike>
                    </a>
                  </center>
                </div>
              </div>

              <div className="row row-cols-xl-3 row-cols-sm-2 row-cols-1 g-0">
                <div className="col p-1">
                  <div className="card shadow h-100">
                    <div className="card-body text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="54"
                        height="54"
                        fill="#FFC007"
                        class="bi bi-newspaper"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z" />
                        <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z" />
                      </svg>
                      <h4 className="card-title text-danger mt-3">
                        200+ Tests
                      </h4>
                      <p className="card-text">
                        Access 200+ Varied Tests: Our extensive collection
                        includes a diverse range of tests, meticulously designed
                        to boost your preparation.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col p-1">
                  <div className="card shadow h-100">
                    <div className="card-body text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="54"
                        height="54"
                        fill="#FFC007"
                        class="bi bi-infinity"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.68 5.792 7.345 7.75 5.681 9.708a2.75 2.75 0 1 1 0-3.916ZM8 6.978 6.416 5.113l-.014-.015a3.75 3.75 0 1 0 0 5.304l.014-.015L8 8.522l1.584 1.865.014.015a3.75 3.75 0 1 0 0-5.304l-.014.015L8 6.978Zm.656.772 1.663-1.958a2.75 2.75 0 1 1 0 3.916L8.656 7.75Z" />
                      </svg>
                      <h3 className="card-title text-danger mt-3">
                        Unlimited Attempts
                      </h3>
                      <p className="card-text">
                        Limitless Growth: Benefit from unlimited test attempts,
                        ensuring you have ample chances to enhance your
                        performance and secure success.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col p-1">
                  <div className="card shadow h-100">
                    <div className="card-body text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="54"
                        height="54"
                        fill="#FFC007"
                        class="bi bi-clipboard-pulse"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Zm6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128L9.979 5.356Z"
                        />
                      </svg>
                      <h3 className="card-title text-danger mt-3">
                        Previous Papers
                      </h3>
                      <p className="card-text">
                        Master the Past, Conquer the Future: Practice with
                        authentic Previous Year Papers (PYPs) to understand exam
                        patterns and excel in upcoming tests.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#F8F9FA" }}>
        <div class="container mt-5">
          <div class="p-5 text-center bg-body-tertiary rounded-3">
            <h1 class="text-danger text-body-emphasis">
              Ab UPSC Hoga Aur Bhi Saral
            </h1>
            <p class="col-lg-8 mx-auto fs-5 text-muted">
              Some reviews from our aspirant followers
            </p>

            <div className="row">
              <div className="col-sm-4 mb-3">
                <img
                  src="/assets/images/testimonials/testimonial1.png"
                  className="img-fluid"
                />
              </div>

              <div className="col-sm-4 mb-3">
                <img
                  src="/assets/images/testimonials/testimonial2.png"
                  className="img-fluid"
                />
              </div>

              <div className="col-sm-4 mb-3">
                <img
                  src="/assets/images/testimonials/testimonial3.png"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestReportLimited;
