import React from "react";

const BlogTileNew = ({ blog }) => {
  if (!blog) {
    return (
      <>
        <div className="row mb-4 d-flex align-items-center">
          <div className="col-3">
            <svg
              class="bd-placeholder-img card-img-top"
              width="100%"
              height="180"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Loading</title>
              <rect width="100%" height="100%" fill="#868e96"></rect>
            </svg>
          </div>
          <div className="col-9">
            <h3 class="placeholder-glow">
              <span class="placeholder col-12"></span>
            </h3>

            <p class="card-text placeholder-glow">
              <span class="placeholder col-7"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-6"></span>
              <span class="placeholder col-8"></span>
            </p>
            <p class="card-text placeholder-glow">
              <span class="placeholder col-7"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-6"></span>
              <span class="placeholder col-8"></span>
            </p>
          </div>

          <hr className="mt-3" />
        </div>
      </>
    );
  }

  function convertToSlug(title) {
    return title.toLowerCase().replace(/\s+/g, "-");
  }

  return (
    <div className="row mb-4 d-flex align-items-center">
      <div className="col-3">
        <img src={blog.poster} className="img-fluid" />
      </div>
      <div className="col-9">
        <h3>
          <a
            href={"/article/" + blog._id + "/" + convertToSlug(blog.title)}
            className="text-decoration-none"
          >
            {blog.title}
          </a>
        </h3>

        <p className="mt-2 lead" style={{ fontSize: "18px" }}>
          {blog.meta_desc}
        </p>
        <p>
          {blog.createdAt}{" "}
          {blog.questions.length > 0 ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>{" "}
              <span style={{ color: "#9B2727" }}>
                Asked {blog.questions.length} times in Prelims
              </span>
            </>
          ) : (
            <></>
          )}
        </p>
      </div>

      <hr className="mt-3" />
    </div>
  );
};

export default BlogTileNew;
