import React, { useEffect } from "react";

const RegistrationForm = () => {
  useEffect(() => {
    const loadTallyScript = () => {
      const d = document;
      const w = "https://tally.so/widgets/embed.js";

      const loadEmbeds = () => {
        if (window.Tally !== undefined) {
          window.Tally.loadEmbeds();
        } else {
          d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach(
            (element) => {
              element.src = element.dataset.tallySrc;
            }
          );
        }
      };

      if (window.Tally !== undefined) {
        loadEmbeds();
      } else if (d.querySelector(`script[src="${w}"]`) === null) {
        const script = d.createElement("script");
        script.src = w;
        script.onload = loadEmbeds;
        script.onerror = loadEmbeds;
        d.body.appendChild(script);
      }
    };

    loadTallyScript();
  }, []);

  return (
    <iframe
      data-tally-src="https://tally.so/embed/3yJMV8?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
      loading="lazy"
      width="100%"
      height="1"
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      title="Registration form"
    />
  );
};

export default RegistrationForm;
