import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap"; // Import Modal and related components
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import TruncatedAnswer from "./TruncatedAnswer";

const MainsQuestion = () => {
  const { questionSlug } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState();
  const [answer, setAnswer] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { currentUser } = useAuth();
  const [answerSubmitted, setAnswerSubmitted] = useState(false); // Track if the answer is submitted
  const [myAnswer, setMyAnswer] = useState();

  useEffect(() => {
    // Check if there is an unsaved answer in localStorage for the current questionSlug
    const unsavedAnswer = localStorage.getItem(`unsavedAnswer_${questionSlug}`);

    if (unsavedAnswer) {
      // Set the unsaved answer in the state
      setAnswer(unsavedAnswer);
    }

    axios
      .get(`${apiUrl}mains-question/${questionSlug}`)
      .then((response) => response.data)
      .then((data) => {
        setData(data);
      })
      .catch((err) => console.log(err));

    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        axios
          .get(`${apiUrl}mains-answers/my-answer/${questionSlug}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setMyAnswer(response.data.answer); // Assuming the API returns answers in the 'answers' field
          })
          .catch((error) => {
            console.error("Error fetching my answers", error);
          });
      });
    }
  }, []);

  const handleSubmit = () => {
    setShowModal(true);
  };

  function removeHTMLTags(input) {
    return input.replace(/<[^>]*>?/gm, ""); // This regex removes all HTML tags
  }

  const confirmSubmit = () => {
    if (!currentUser) {
      // User is not logged in
      // Save the answer and page URL in localStorage
      localStorage.setItem(`unsavedAnswer_${questionSlug}`, answer);
      localStorage.setItem("redirectUrl", window.location.pathname);
      localStorage.setItem("redirectType", "mains");
      // Redirect the user to the login page (replace '/login' with your actual login page URL)
      window.location.href = "/login";
      return;
    }

    currentUser.getIdToken().then((token) => {
      const payloadHeader = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      setIsSubmitting(true);

      axios
        .post(
          `${apiUrl}mains-answers`, // Update with the correct endpoint
          {
            question: question._id,
            answer,
          },
          payloadHeader
        )
        .then((response) => {
          localStorage.removeItem(`unsavedAnswer_${questionSlug}`);
          setIsSubmitting(false);
          setShowModal(false);
          setAnswerSubmitted(true); // Set answerSubmitted to true on success
        })
        .catch((error) => {
          console.error("Error submitting answer", error);
          setIsSubmitting(false);
          setShowModal(false);
        });
    });
  };

  if (!data) {
    return (
      <section>
        <div className="container mt-5">
          <h1>
            <span className="text-danger">
              <p class="placeholder-glow">
                <span class="placeholder col-12"></span>
              </p>
            </span>
            <p class="placeholder-glow">
              <span class="placeholder col-12"></span>
            </p>
          </h1>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
          <hr />
        </div>
      </section>
    );
  }

  const question = data.question;
  const topAnswers = data.topAnswers;

  return (
    <>
      <Helmet>
        <title>
          {question.statement + " - UPSC Mains Question | SaralUPSC"}
        </title>
        <meta name="description" content={question.statement} />
        <meta property="og:description" content={question.statement} />
        <meta property="og:title" content={question.statement} />
        <meta
          property="og:url"
          content={"https://saralupsc.com/mains-question/" + questionSlug}
        />
      </Helmet>
      <section>
        <div className="container mt-5">
          <h1>
            <span className="text-danger">[Ques] {question.statement}</span>
            {question.year ? (
              <small className="d-inline-flex px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 me-2 ms-2">
                {question.year}
              </small>
            ) : (
              <></>
            )}
          </h1>
          {question.createdAt} |{" "}
          <b className="text-danger">{question.marks + " Marks"}</b>
          {data.totalAnswers > 0 ? (
            <b>
              <span className="text-success">
                {" "}
                | Total Submitted Answers : {data.totalAnswers}
              </span>
            </b>
          ) : (
            <></>
          )}
          {question.subject ? (
            <b>
              <span className="text-danger">
                {" "}
                | {question.subject.toUpperCase()}
              </span>
            </b>
          ) : (
            <></>
          )}
          <hr />
          <div className="row">
            {topAnswers.length > 0 ? (
              <div className="col-sm-6">
                <h4>Answers from other Aspirants</h4>
                {topAnswers.map((topAnswer) => {
                  return (
                    <div class="card mb-3">
                      <div class="card-body">
                        <h6 class="card-subtitle mb-2 text-body-secondary">
                          <b>
                            {" "}
                            Marks Received :{" "}
                            {topAnswer.marks_allotted + "/" + question.marks}
                          </b>
                        </h6>
                        <h6 className="text-danger">
                          Top Marked Answer by Expert Faculties - Write a better
                          answer and win 50 INR
                        </h6>
                        <TruncatedAnswer answer={topAnswer.answer} />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="col-sm-6">
                <div
                  class="modal modal-sheet position-static d-block bg-body-secondary p-4 py-md-5"
                  tabindex="-1"
                  role="dialog"
                  id="modalTour"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content rounded-4 shadow">
                      <div class="modal-body p-5">
                        <h2 class="fw-bold mb-0 text-danger">
                          Earn 50₹ by submitting your answer
                        </h2>
                        <h6>
                          If you submit answer before anyone else, then you will
                          get 50₹
                        </h6>

                        <ul class="d-grid gap-4 my-5 list-unstyled small">
                          <li class="d-flex gap-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="48"
                              fill="currentColor"
                              class="bi bi-person-check-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                              />
                              <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                            <div>
                              <h5 class="mb-0">Expert Faculty Analysis</h5>
                              Make your answers better and score more
                            </div>
                          </li>
                          <li class="d-flex gap-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="48"
                              fill="currentColor"
                              class="bi bi-currency-rupee"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z" />
                            </svg>
                            <div>
                              <h5 class="mb-0">Learn and Earn</h5>
                              Get rewards as you complete milestones
                            </div>
                          </li>
                          <li class="d-flex gap-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="48"
                              fill="currentColor"
                              class="bi bi-pass"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5Zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3Z" />
                              <path d="M8 2a2 2 0 0 0 2-2h2.5A1.5 1.5 0 0 1 14 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-13A1.5 1.5 0 0 1 3.5 0H6a2 2 0 0 0 2 2Zm0 1a3.001 3.001 0 0 1-2.83-2H3.5a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5h-1.67A3.001 3.001 0 0 1 8 3Z" />
                            </svg>
                            <div>
                              <h5 class="mb-0">Clear UPSC in First Attempt</h5>
                              Aim for Rank #1 with our Rank #1 Programme
                            </div>
                          </li>
                        </ul>
                        <button
                          type="button"
                          class="btn btn-lg btn-danger w-100"
                          data-bs-dismiss="modal"
                        >
                          Ab UPSC Hoga Aur Bhi Saral
                        </button>
                        <p style={{ fontSize: "12px" }} className="text-center">
                          *Terms and Conditions Applied
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="col-sm-6 sticky-top">
              <div className="card sticky-top border-0">
                {myAnswer ? (
                  <div class="card mt-3 ">
                    <div class="card-body">
                      <h6 className="text-danger">
                        You answered this question on : {myAnswer.createdAt}
                      </h6>

                      {myAnswer.status === "UNDER_EVAL" ? (
                        <span class="badge text-bg-danger">
                          Under Evaluation
                        </span>
                      ) : (
                        <span class="badge text-bg-warning">Evaluated</span>
                      )}

                      <span class="badge text-bg-success ms-2">
                        {myAnswer.status === "EVALUATED" ? (
                          <>{myAnswer.marks_allotted + "/" + question.marks}</>
                        ) : (
                          <></>
                        )}
                      </span>
                      <hr />
                      <p
                        className="card-text"
                        dangerouslySetInnerHTML={{
                          __html: myAnswer.answer,
                        }}
                      ></p>

                      {myAnswer.status === "UNDER_EVAL" ||
                      myAnswer.status === "SPAM" ? (
                        <></>
                      ) : (
                        <div>
                          <button
                            class="btn btn-primary"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            See Faculty Comments
                          </button>

                          <div class="collapse mt-3" id="collapseExample">
                            <div class="card card-body">
                              {myAnswer.comments}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {" "}
                    <h4 className="text-danger">
                      You can answer this question
                    </h4>
                    <p>Answer in less than 1000 words</p>
                    <div className="mb-3">
                      {answerSubmitted ? (
                        <div className="alert alert-success" role="alert">
                          Your answer has been submitted successfully. You will
                          receive feedback soon
                        </div>
                      ) : (
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="20"
                          value={answer}
                          onChange={(e) =>
                            setAnswer(removeHTMLTags(e.target.value))
                          }
                          disabled={isSubmitting || answerSubmitted} // Disable the textarea when submitting or if answer is already submitted
                        ></textarea>
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-success mt-3 w-100"
                      onClick={handleSubmit}
                      disabled={isSubmitting || answerSubmitted} // Disable the submit button when submitting or if answer is already submitted
                    >
                      {isSubmitting ? "Submitting..." : "Submit your answer"}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to submit your answer?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={confirmSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MainsQuestion;
