import React, { useState } from "react";

function TruncatedAnswer({ answer }) {
  const [showFullAnswer, setShowFullAnswer] = useState(false);

  const toggleAnswer = () => {
    setShowFullAnswer(!showFullAnswer);
  };

  const renderAnswer = () => {
    if (showFullAnswer) {
      return (
        <div>
          <p
            className="card-text"
            dangerouslySetInnerHTML={{
              __html: answer,
            }}
          ></p>

          <a href="/contact-us" class="card-link text-success">
            Want to write answers like this?
          </a>
        </div>
      );
    } else {
      // Split the answer into an array of lines
      const lines = answer.split("\n");
      // Display only the first 6 lines
      const truncatedAnswer = lines.slice(0, 5).join("\n");

      return (
        <div>
          <p
            className="card-text"
            dangerouslySetInnerHTML={{
              __html: truncatedAnswer,
            }}
          ></p>
          <p>
            <span className="card-text">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleAnswer();
                }}
                className="me-2"
              >
                Read Complete Answer
              </a>

              <a href="/contact-us" class="card-link text-success">
                Want to write answers like this?
              </a>
            </span>
          </p>
        </div>
      );
    }
  };

  return <div>{renderAnswer()}</div>;
}

export default TruncatedAnswer;
