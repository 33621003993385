import React from "react";
import Container from "react-bootstrap/Container";

const PrivacyPolicy = () => {
  return (
    <Container>
      <h1 className="my-4">Privacy Policy</h1>
      <p>
        Welcome to SaralUPSC ("us", "we", or "our"). By accessing our website
        and using our services, you agree to comply with and be bound by the
        following terms and conditions:
      </p>

      <h4>Who We Are</h4>
      <p>Our website address is https://saralupsc.com/.</p>

      <h4>Use of Information</h4>
      <p>
        We require this information to understand your needs and provide you
        with better services:
      </p>
      <ol>
        <li>Internal record keeping.</li>
        <li>Improving our products and services.</li>
        <li>
          Sending promotional emails about new products, special offers, or
          other information which we think you may find interesting using the
          email address which you have provided.
        </li>
        <li>
          Contacting you for market research purposes via email, phone, fax,
          WhatsApp, or mail.
        </li>
        <li>Customizing the Platform according to your interests.</li>
      </ol>

      <h4>Data Retention</h4>
      <p>
        We retain user data while an account is active and for at least 4 years
        afterward, subject to legal and business requirements. We may adjust the
        retention period based on these factors.
      </p>

      <h4>Security</h4>
      <p>
        We are committed to safeguarding your information through suitable
        physical, electronic, and managerial procedures. However, no online data
        transmission is entirely secure, and you transmit data at your own risk.
      </p>

      <h4>Children</h4>
      <p>
        We do not contact children under age 15 for marketing purposes without
        parental permission.
      </p>

      <h4>Links to Other Platforms</h4>
      <p>
        We may link to other platforms, but we are not responsible for their
        protection and privacy policies. Please exercise caution and review the
        privacy statement of the linked platform.
      </p>

      <h4>Third-Party Services</h4>
      <p>
        We may share information with third-party service providers to deliver
        programs, products, and services. We take steps to ensure your data is
        protected.
      </p>

      <h4>Controlling Your Personal Information</h4>
      <p>
        You can control the use of your personal information by indicating your
        preferences when filling out forms. You can also change your preferences
        for direct marketing at any time.
      </p>

      <h4>Contacting Us</h4>
      <p>
        If you have questions about this privacy policy, you can contact us by
        clicking the “Contact Us” tab on our website or emailing us at
        team@saralupsc.com.
      </p>

      <h4>Changes to this Policy</h4>
      <p>
        Any changes to this policy will be updated and posted on our platform.
        Check periodically, especially before providing personally identifiable
        information.
      </p>

      <h4>Your Consent to This Policy</h4>
      <p>
        By accessing our platform, you agree to this Privacy Policy. This
        document supersedes prior communications and reflects the exclusive
        Privacy Policy for this platform. This Policy is subject to our Terms of
        Use.
      </p>
    </Container>
  );
};

export default PrivacyPolicy;
