import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

const MentorProfile = ({ mentor, currentUser }) => {
  // State to store availability matrix (7 days x 12 slots)
  const [availabilityMatrix, setAvailabilityMatrix] = useState(
    mentor.weeklyAvailability
  );

  const [image, setImage] = useState({ preview: "", data: "", name: "" });
  const [imageShow, setImageShow] = useState(false);
  const [subjects, setSubjects] = useState(mentor.subjects);
  const [languages, setLanguages] = useState(mentor.languages);
  const [editSubject, setEditSubject] = useState(false);
  const [editLang, setEditLang] = useState(false);
  const [savingAvailability, setSavingAvailability] = useState(false);
  const [newPricePerHour, setNewPricePerHour] = useState(mentor.pricePerHour);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [events, setEvents] = useState([]);
  const [pastStudents, setPastStudents] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        let apiUrl = process.env.REACT_APP_API_URL;

        const payloadHeader = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .get(`${apiUrl}mentors/upcoming-events`, payloadHeader)
          .then((response) => {
            console.log(response.data);
            setEvents(response.data); // Set fetched events in state
          })
          .catch((error) => {
            console.error("Error fetching events:", error);
          });

        axios
          .get(`${apiUrl}mentors/past-events`, payloadHeader)
          .then((response) => {
            console.log(response.data);
            setPastStudents(response.data); // Set fetched events in state
          })
          .catch((error) => {
            console.error("Error fetching events:", error);
          });
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (events.length > 0) {
      const totalPrice = events.reduce(
        (acc, event) => acc + event.totalPrice,
        0
      );
      setTotalPrice(totalPrice);
    }
  }, [events]);

  const weekMap = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0,
  };

  // Function to handle toggling availability
  const toggleAvailability = (dayIndex, slotIndex) => {
    const updatedMatrix = [...availabilityMatrix];
    updatedMatrix[dayIndex][slotIndex] = !updatedMatrix[dayIndex][slotIndex];
    setAvailabilityMatrix(updatedMatrix);
    console.log(availabilityMatrix);
  };

  const renderTimeSlots = (day) => {
    const timeSlots = [];

    for (let i = 9; i <= 20; i++) {
      const formattedHour = `${i < 10 ? "0" : ""}${i}:00`;

      timeSlots.push(
        <div className="btn-group ms-2" role="group" key={`${day}-${i}`}>
          <input
            type="checkbox"
            className="btn-check"
            id={`${day}-${i}`}
            autoComplete="off"
            checked={availabilityMatrix[weekMap[day]][i - 9]}
            onChange={() => toggleAvailability(weekMap[day], i - 9)}
          />
          <label className="btn btn-outline-secondary" htmlFor={`${day}-${i}`}>
            {formattedHour}
          </label>
        </div>
      );
    }

    return timeSlots;
  };

  const updateAvailability = () => {
    if (currentUser) {
      setSavingAvailability(true); // Set spinner when starting to save availability

      currentUser
        .getIdToken()
        .then(async (token) => {
          const apiUrl = process.env.REACT_APP_API_URL;

          const payloadHeader = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await axios.put(
            `${apiUrl}mentors/update-mentor`,
            { weeklyAvailability: availabilityMatrix },
            payloadHeader
          );

          // If the operation is successful, display the toast message
          setShow(true);
          setSavingAvailability(false); // Set spinner to false after successful update
        })
        .catch((err) => {
          console.log(err);
          setSavingAvailability(false); // Set spinner to false if there's an error
        });
    }
  };

  const updatePricePerHour = async () => {
    try {
      if (currentUser) {
        const token = await currentUser.getIdToken();
        const apiUrl = process.env.REACT_APP_API_URL;

        const payloadHeader = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.put(
          `${apiUrl}mentors/update-mentor`,
          { pricePerHour: newPricePerHour },
          payloadHeader
        );

        // Handle success, maybe display a success message or update the UI accordingly
        setShow(true);
      }
    } catch (error) {
      console.error("Error updating price per hour:", error);
      // Handle error cases
    }
  };

  const handleUpdate = () => {
    updateAvailability();
  };

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      name: e.target.files[0].name,
    };
    setImage(img);
  };

  const handleSubjectCheckboxChange = (selectedSubject) => {
    const updatedSubjects = [...subjects];
    const index = updatedSubjects.indexOf(selectedSubject);

    if (index > -1) {
      updatedSubjects.splice(index, 1); // Remove the subject if already selected
    } else {
      updatedSubjects.push(selectedSubject); // Add the subject if not already selected
    }

    setSubjects(updatedSubjects); // Update the state with the new subjects array
  };

  const handleLanguageCheckboxChange = (selectedLanguage) => {
    const updatedLanguages = [...languages];
    const index = updatedLanguages.indexOf(selectedLanguage);

    if (index > -1) {
      updatedLanguages.splice(index, 1); // Remove the Language if already selected
    } else {
      updatedLanguages.push(selectedLanguage); // Add the Language if not already selected
    }

    setLanguages(updatedLanguages); // Update the state with the new subjects array
  };

  const updateSubjects = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = await currentUser.getIdToken(); // Get the user's token

      const payloadHeader = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const endpoint = `${apiUrl}mentors/update-mentor`;
      const requestBody = { subjects: subjects }; // Mentor ID and updated subjects

      const response = await axios.put(endpoint, requestBody, payloadHeader);
      return response.data; // Returns the updated mentor information
    } catch (error) {
      console.error("Error updating subjects:", error);
      throw new Error("Failed to update subjects. Please try again."); // Throw an error for the caller to handle
    }
  };

  const updateLanguages = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = await currentUser.getIdToken(); // Get the user's token

      const payloadHeader = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const endpoint = `${apiUrl}mentors/update-mentor`;
      const requestBody = { languages: languages }; // Mentor ID and updated subjects

      const response = await axios.put(endpoint, requestBody, payloadHeader);
      return response.data; // Returns the updated mentor information
    } catch (error) {
      console.error("Error updating languages:", error);
      throw new Error("Failed to update languages. Please try again."); // Throw an error for the caller to handle
    }
  };

  const updateProfilePicture = async () => {
    const imageRef = ref(storage, `mentorImages/${image.name}`);
    await uploadBytes(imageRef, image.data).then(async (uploadedImage) => {
      // Get the download URL of the uploaded image
      const imageUrl = await getDownloadURL(imageRef);
      const apiUrl = process.env.REACT_APP_API_URL;
      const updatedFormData = {
        profilePicture: imageUrl,
      };

      const token = await currentUser.getIdToken(); // Get the user's token

      const payloadHeader = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        `${apiUrl}mentors/update-mentor`,
        updatedFormData,
        payloadHeader
      );

      if (response.data.savedMentor) {
        setShow(true);
      }
    });
  };

  return (
    <section className="h-100 gradient-custom-2">
      <div className="container py-5 h-100">
        <div className="row h-100">
          <div className="col-lg-4 ">
            <div className="card">
              <div
                className="rounded-top text-white d-flex flex-row"
                style={{
                  backgroundImage:
                    "url(https://png.pngtree.com/thumb_back/fw800/background/20220702/pngtree-background-of-soft-red-gradient-colorful-light-shades-with-blurry-effect-photo-image_32273196.jpg)",
                  height: "200px",
                }}
              >
                <div
                  className="ms-4 mt-5 d-flex flex-column"
                  style={{ width: "150px" }}
                >
                  <img
                    src={mentor.profilePicture}
                    alt="Generic placeholder image"
                    className="img-fluid img-thumbnail mt-4 mb-2"
                    style={{ width: "150px", minHeight: "150px", zIndex: "1" }}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    data-mdb-ripple-color="dark"
                    style={{ zIndex: "1" }}
                    onClick={() => {
                      setImageShow(true);
                    }}
                  >
                    Edit profile
                  </button>
                </div>
                <div className="ms-3" style={{ marginTop: "130px" }}>
                  <h2>{mentor.fullName}</h2>
                </div>
              </div>
              <div
                className="p-4 text-black"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <div className="d-flex justify-content-end text-center py-1">
                  <div>
                    <p className="mb-1 h5">{mentor.upscAttempts}</p>
                    <p className="small text-muted mb-0"> Attempts</p>
                  </div>
                  <div className="px-3">
                    <p className="mb-1 h5">{mentor.prelimsCleared}</p>
                    <p className="small text-muted mb-0">Prelims</p>
                  </div>
                  <div>
                    <p className="mb-1 h5">{mentor.interviewsAppeared}</p>
                    <p className="small text-muted mb-0">Interviews</p>
                  </div>
                </div>
              </div>
              <div className="card-body p-4 text-black">
                <p class="d-inline-flex gap-1">
                  Your Per Hour Session : {mentor.pricePerHour}
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#priceCollapse"
                    aria-expanded="false"
                    aria-controls="priceCollapse"
                  >
                    Change Per Hour Charges
                  </button>
                </p>

                <div class="collapse mb-3" id="priceCollapse">
                  <div class="card card-body">
                    <div class="mb-3">
                      <label for="pricePerHourInput" class="form-label">
                        New Per Hour Charge
                      </label>
                      <input
                        type="number"
                        step={100}
                        id="pricePerHourInput"
                        class="form-control"
                        placeholder="Enter New Price"
                        value={newPricePerHour}
                        onChange={(e) =>
                          setNewPricePerHour(parseInt(e.target.value))
                        }
                      />
                      <button
                        type="button"
                        class="btn btn-primary mt-2"
                        onClick={updatePricePerHour}
                      >
                        Update Price Per Hour
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="d-flex justify-content-between">
                    <p className="lead fw-normal mb-1">Subjects Expert In</p>

                    {editSubject ? (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          updateSubjects();
                          setEditSubject(false);
                        }}
                      >
                        Update
                      </a>
                    ) : (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setEditSubject(true);
                        }}
                      >
                        Edit
                      </a>
                    )}
                  </div>
                  <div className="p-4" style={{ backgroundColor: "#f8f9fa" }}>
                    {editSubject ? (
                      <ul className="list-group mx-auto">
                        {subjectsList.map((subject, index) => (
                          <li className="list-group-item" key={index}>
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value={subject}
                              id={`subjectCheckbox${index}`}
                              checked={subjects.includes(subject)}
                              onChange={() =>
                                handleSubjectCheckboxChange(subject)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`subjectCheckbox${index}`}
                            >
                              {subject}
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      subjects.map((subject, index) => (
                        <p className="font-italic mb-1" key={index}>
                          {subject}
                        </p>
                      ))
                    )}
                  </div>
                </div>

                <div className="mb-2">
                  <div className="d-flex justify-content-between">
                    <p className="lead fw-normal mb-1">Fluent In</p>

                    {editLang ? (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          updateLanguages();
                          setEditLang(false);
                        }}
                      >
                        Update
                      </a>
                    ) : (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setEditLang(true);
                        }}
                      >
                        Edit
                      </a>
                    )}
                  </div>
                  <div className="p-4" style={{ backgroundColor: "#f8f9fa" }}>
                    {editLang ? (
                      <ul className="list-group mx-auto">
                        {languagesList.map((language, index) => (
                          <li className="list-group-item" key={index}>
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value={language}
                              id={`languageCheckbox${index}`}
                              checked={languages.includes(language)}
                              onChange={() =>
                                handleLanguageCheckboxChange(language)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`languageCheckbox${index}`}
                            >
                              {language}
                            </label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      languages.map((language, index) => (
                        <p className="font-italic mb-1" key={index}>
                          {language}
                        </p>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>

            <BankDetailsCard currentUser={currentUser} />
          </div>

          <div className="col-lg-8">
            <div className="row mb-3">
              <div className="d-flex justify-content-between mb-3">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Upcoming 7 Days
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        Upcoming 7 Days
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Upcoming 15 Days
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Upcoming 30 Days
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-space-between">
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Your Availability
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="collapse mb-3" id="collapseExample">
                  <div className="card mb-2">
                    <div className="px-2 py-2 d-flex overflow-auto align-items-center">
                      Monday
                      {renderTimeSlots("Monday")}
                    </div>
                    <div className="px-2 py-2 d-flex overflow-auto align-items-center">
                      Tuesday
                      {renderTimeSlots("Tuesday")}
                    </div>
                    <div className="px-2 py-2 d-flex overflow-auto align-items-center">
                      Wednesday
                      {renderTimeSlots("Wednesday")}
                    </div>
                    <div className="px-2 py-2 d-flex overflow-auto align-items-center">
                      Thursday
                      {renderTimeSlots("Thursday")}
                    </div>
                    <div className="px-2 py-2 d-flex overflow-auto align-items-center">
                      Friday
                      {renderTimeSlots("Friday")}
                    </div>
                    <div className="px-2 py-2 d-flex overflow-auto align-items-center">
                      Saturday
                      {renderTimeSlots("Saturday")}
                    </div>
                    <div className="px-2 py-2 d-flex overflow-auto align-items-center">
                      Sunday
                      {renderTimeSlots("Sunday")}
                    </div>

                    <div class="card-footer text-body-secondary">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleUpdate}
                        disabled={savingAvailability}
                      >
                        {savingAvailability ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Update availability"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title">₹ {totalPrice}</h3>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                      Amount for Next 7 Days
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title">{events.length} Sessions</h3>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                      Reserved Slots
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title">
                      {pastStudents.numberOfStudents} Students
                    </h3>
                    <h6 class="card-subtitle mb-2 text-body-secondary">
                      Total Students in Last 7 Days
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <table class="table caption-top">
              <caption>Upcoming mentorship sessions</caption>
              <thead>
                <tr>
                  <th scope="col">Session ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Student</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {events &&
                  events.map((event) => {
                    return (
                      <tr>
                        <th scope="row">{event._id}</th>
                        <td>
                          {new Date(event.date).getDate()}/
                          {new Date(event.date).getMonth()}/
                          {new Date(event.date).getFullYear()}
                        </td>
                        <td>
                          {event.time % 12}
                          {event.time / 12 > 1 ? " PM" : " AM"}
                        </td>
                        <td>
                          {event.student.name ? event.student.name : "NA"}
                        </td>
                        {/*    <td>
                          <button type="button" class="btn btn-success me-2">
                            Join Now
                          </button>
                          <button type="button" class="btn btn-danger">
                            Re-schedule
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Woohoo, your profile has been updated successfully, changes will be
          implemented after one hour.
          <p>
            Also, updating availability do not mean that previous confirmed
            booking on changed slots will become cancelled. You have to attend
            them
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={imageShow}
        onHide={() => {
          setImageShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="mb-3">
            <label htmlFor="city" class="form-label">
              Your Profile Picture
            </label>
            <br />
            <div className="row">
              <div className="col-sm-6">
                {image.preview ? (
                  <img src={image.preview} width="100" height="100" />
                ) : (
                  <></>
                )}
              </div>
              <div className="col-sm-6">
                <input
                  type="file"
                  class="form-control"
                  id="profilePicture"
                  name="profilePicture" // Add the 'name' attribute here
                  onChange={handleFileChange} // Add onChange handler
                  required
                />
                <div class="invalid-feedback">
                  UPSC Aspirants would love to see your face. Please upload your
                  picture.
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setImageShow(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={updateProfilePicture}>
            Update Picture
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

const subjectsList = [
  "History",
  "Geography",
  "Economics",
  "PSIR",
  "Polity",
  "International Relations",
  "CSAT",
  "GS1",
  "GS2",
  "GS3",
  "GS4",
  "Optionals",
  "Environment",
  "Science and Technology",
  "Indian Society",
  "Internal Security",
  "Ethics",
  "Current Affairs",
  "Essay Writing",
];

const languagesList = [
  "Assamese",
  "Bengali",
  "English",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Hindi",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia (Oriya)",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
];

const BankDetailsCard = ({ currentUser }) => {
  const [bankDetails, setBankDetails] = useState({});
  const [editing, setEditing] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [empty, setEmpty] = useState(false);

  const updateDetails = () => {
    if (!currentUser) {
      return; // Return if currentUser is not available yet
    }

    currentUser
      .getIdToken()
      .then((token) => {
        const payloadHeader = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .put(`${apiUrl}mentor-bank`, bankDetails, payloadHeader)
          .then((response) => {
            console.log("Bank details updated successfully:", response.data);
            setEditing(false); // Reset editing state after successful update
          })
          .catch((error) => {
            console.error("Error updating bank details:", error);
          });
      })
      .catch((error) => {
        console.error("Error getting ID token:", error);
        alert("Error getting user ID token. Please try again.");
      });
  };

  const createDetails = () => {
    if (!currentUser) {
      return; // Return if currentUser is not available yet
    }

    currentUser
      .getIdToken()
      .then((token) => {
        const payloadHeader = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .post(`${apiUrl}mentor-bank`, bankDetails, payloadHeader)
          .then((response) => {
            console.log("Bank details updated successfully:", response.data);
            setEditing(false); // Reset editing state after successful update
          })
          .catch((error) => {
            console.error("Error updating bank details:", error);
          });
      })
      .catch((error) => {
        console.error("Error getting ID token:", error);
        alert("Error getting user ID token. Please try again.");
      });
  };

  const handleClick = () => {
    if (empty) {
      // Execute POST request to create bank details if it's empty
      createDetails();
    } else {
      updateDetails();
    }
  };

  const fetchDetails = () => {
    if (!currentUser) {
      return; // Return if currentUser is not available yet
    }

    currentUser
      .getIdToken()
      .then((token) => {
        const payloadHeader = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .get(`${apiUrl}mentor-bank`, payloadHeader)
          .then((response) => {
            const fetchedBankDetails = response.data;

            // Check if the response status is 404 (Not Found)
            if (response.status === 404) {
              setEmpty(true);
            } else {
              setBankDetails(fetchedBankDetails);
              setEmpty(false);
            }
          })
          .catch((error) => {
            // Check for specific 404 error response
            if (error.response && error.response.status === 404) {
              setEmpty(true);
            } else {
              console.error("Error fetching bank details:", error);
            }
          });
      })
      .catch((error) => {
        console.error("Error getting ID token:", error);
        alert("Error getting user ID token. Please try again.");
      });
  };

  useEffect(() => {
    fetchDetails();
  }, [currentUser]);

  return (
    <>
      {" "}
      <div className="card mt-3">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title">Bank Details</h5>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setEditing(true);
            }}
          >
            Edit
          </a>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <label className="form-label fw-bold">Account Holder Name:</label>
            <input
              className="form-control"
              type="text"
              name="accountHolderName"
              value={bankDetails.accountHolderName}
              onChange={(e) => {
                setBankDetails((prev) => ({
                  ...prev,
                  accountHolderName: e.target.value,
                }));
              }}
              disabled={!editing}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Account Number:</label>
            <input
              className="form-control"
              type="text"
              name="accountNumber"
              value={bankDetails.accountNumber}
              onChange={(e) => {
                setBankDetails((prev) => ({
                  ...prev,
                  accountNumber: e.target.value,
                }));
              }}
              disabled={!editing}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Bank Name:</label>
            <input
              className="form-control"
              type="text"
              name="bankName"
              value={bankDetails.bankName}
              onChange={(e) => {
                setBankDetails((prev) => ({
                  ...prev,
                  bankName: e.target.value,
                }));
              }}
              disabled={!editing}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Branch Name:</label>
            <input
              className="form-control"
              type="text"
              name="branchName"
              value={bankDetails.branchName}
              onChange={(e) => {
                setBankDetails((prev) => ({
                  ...prev,
                  branchName: e.target.value,
                }));
              }}
              disabled={!editing}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">IFSC Code:</label>
            <input
              className="form-control"
              type="text"
              name="ifscCode"
              value={bankDetails.ifscCode}
              onChange={(e) => {
                setBankDetails((prev) => ({
                  ...prev,
                  ifscCode: e.target.value,
                }));
              }}
              disabled={!editing}
            />
          </div>
        </div>
        <div className="card-footer">
          <button
            className="btn btn-primary"
            onClick={handleClick}
            disabled={!editing}
          >
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

const MentorDashboard = () => {
  const { currentUser } = useAuth();
  const [mentorProfile, setMentorProfile] = useState(null);

  useEffect(() => {
    const fetchMentorProfile = async () => {
      try {
        if (currentUser) {
          const token = await currentUser.getIdToken();
          const apiUrl = process.env.REACT_APP_API_URL;

          const payloadHeader = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await axios.get(
            `${apiUrl}mentors/my-profile`,
            payloadHeader
          );

          setMentorProfile(response.data); // Set the retrieved mentor profile data to state
        }
      } catch (error) {
        console.error("Error fetching mentor profile:", error);
        // Handle error cases
      }
    };

    fetchMentorProfile(); // Fetch mentor profile data when component mounts
  }, [currentUser]);

  if (!mentorProfile) {
    return (
      <>
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );
  }

  return <MentorProfile mentor={mentorProfile} currentUser={currentUser} />;
};

export default MentorDashboard;
