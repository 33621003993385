import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDJLOdltSr70oVSKRdAh-z17E73chQcilE",
  authDomain: "saralupsc-1a1d8.firebaseapp.com",
  projectId: "saralupsc-1a1d8",
  storageBucket: "saralupsc-1a1d8.appspot.com",
  messagingSenderId: "758059936377",
  appId: "1:758059936377:web:5f89a023462cd179f242a8",
  measurementId: "G-4QYLQXYTB8",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);

export { auth, storage };
