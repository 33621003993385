import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const TestingChart = ({ data }) => {
  const values = [
    { name: "Scored", value: parseInt(data) }, // Replace this value with the score you want to display
    { name: "Remaining", value: parseInt(100 - data) }, // Replace this value with (100 - score)
  ];

  var COLORS;
  if (data < 40) {
    COLORS = ["#FF0000", "#FFCCCC"];
  } else if (data >= 40 && data < 75) {
    COLORS = ["#FBA018", "#FFD965"];
  } else {
    COLORS = ["#1BC47D", "#42D99F"];
  }

  return (
    <div style={{ width: "100%", height: 95 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={values}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={45}
            innerRadius={35}
            fill="#8884d8"
          >
            {values.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TestingChart;
