import React from "react";
import StudentDonut from "./StudentDonutChart";

const StudentComparison = ({ high, medium, low }) => {
  /*  if (!high || !medium || !low) {
    return "loading";
  } */

  return (
    <div className="card shadow-sm h-100">
      <div className="card-body">
        <h4>
          <u>Student Comparison</u>
        </h4>
        <StudentDonut high={34} medium={23} low={65} />
      </div>
    </div>
  );
};

export default StudentComparison;
