import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogTileNew from "./BlogTileNew";

const BlogsGridNew = () => {
  const { subject } = useParams();
  const [blogs, setBlogs] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [page, setPage] = useState(0);
  const [hasMoreBlogs, setHasMoreBlogs] = useState(true);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true); // Initial loading state
  const [isLoadingMore, setIsLoadingMore] = useState(false); // Loading more state

  useEffect(() => {
    fetchBlogs(page);
  }, [page]);

  const fetchBlogs = async (page) => {
    try {
      if (page === 0) {
        setIsLoadingInitial(true); // Set initial loading state
      } else {
        setIsLoadingMore(true); // Set loading more state
      }

      const response = await axios.get(`${apiUrl}articles/${subject}`, {
        params: { page: page },
      });

      if (response.data.length === 0) {
        setHasMoreBlogs(false);
      } else {
        setBlogs((prevBlogs) => [...prevBlogs, ...response.data]);
      }

      setIsLoadingInitial(false); // Set initial loading state to false
      setIsLoadingMore(false); // Set loading more state to false
    } catch (error) {
      console.error("Error fetching tests:", error);

      if (page === 0) {
        setIsLoadingInitial(false); // Set initial loading state to false
      } else {
        setIsLoadingMore(false); // Set loading more state to false
      }
    }
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <section>
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-8">
            <h1 className="mb-5">Notes of {subject} </h1>
            {isLoadingInitial ? (
              <>
                <BlogTileNew />
                <BlogTileNew />
                <BlogTileNew />
                <BlogTileNew />
                <BlogTileNew />
              </>
            ) : blogs.length === 0 ? (
              <p>No Notes Available</p>
            ) : (
              blogs.map((blog) => <BlogTileNew key={blog.id} blog={blog} />)
            )}
            {isLoadingMore && (
              <>
                <BlogTileNew />
                <BlogTileNew />
                <BlogTileNew />
              </>
            )}
          </div>
          <div className="col-sm-4"></div>
        </div>
        <center>
          {hasMoreBlogs ? (
            <button
              className="btn btn-outline-primary mt-4"
              onClick={handleLoadMore}
              disabled={isLoadingMore}
            >
              {isLoadingMore ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                  {"    "}
                  Load More
                </>
              )}
            </button>
          ) : (
            <p className="mt-4">No More Notes</p>
          )}
        </center>
      </div>
    </section>
  );
};

export default BlogsGridNew;
