import React from "react";
import Container from "react-bootstrap/Container";

const RefundPolicy = () => {
  return (
    <Container>
      <h1 className="my-4">Refund and Cancellation Policy </h1>
      <b>(Revised on 29.08.2023)</b>
      <p>
        - We do not offer refunds for the Rank #1 Programme or any other
        services after 4 days from the date of purchase.
      </p>
      <h5> Cancellation Policy: </h5>
      <p>
        - Once enrolled for any course/class/material with SaralUPSC, SaralUPSC
        shall not refund the money or transfer the money to be adjusted against
        any other student. However, in some cases, the student can change the
        courses. In such cases, SaralUPSC will deduct administrative charges
        (Minimum 25% of the total payment) and put the remaining money as
        retained money. Retained money can be used only for new courses. Such
        cases shall be approved by SaralUPSC.{" "}
      </p>
      <h5> Extra Payment Refund Policy: </h5>
      <p>
        - In case SaralUPSC receives extra payments for any course by any means,
        after receiving proofs for such claims, SaralUPSC will try to refund the
        extra charges within 15 days. The ways may include direct bank transfer
        using NEFT, in-person/authorized representative cheque collection from
        SaralUPSC offices or any other means agreed by the student and the
        management of SaralUPSC. For any bank-related formalities during the
        payment process, SaralUPSC has no liability to pay for any damages which
        resulted solely due to bank processing or negligence from the side of
        the student. Students are advised to take up such matters directly with
        the bank and may proceed to consumer forums if not satisfied. SaralUPSC
        will try to help the students with constraints of time, work-force, and
        other factors.
      </p>
    </Container>
  );
};

export default RefundPolicy;
