import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";

const MultipleAttemptsTile = ({ dataAll }) => {
  const [allReports, setAllReports] = useState([]);
  const { currentUser } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const loadAllReports = async () => {
    try {
      // Retrieve the currentUser token
      if (currentUser) {
        const token = await currentUser.getIdToken();

        // Set the authorization header
        const payloadHeader = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        // Make a request to /tests/all-attempts
        const response = await fetch(
          `${apiUrl}all-attempts/${dataAll.test._id}`,
          payloadHeader
        );
        const data = await response.json();
        console.log(data);
        setAllReports(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let percentChange;
  if (dataAll.reports[1].score !== 0) {
    percentChange =
      ((dataAll.reports[0].score - dataAll.reports[1].score) /
        Math.abs(dataAll.reports[1].score)) *
      100;
  } else {
    // Handle the case when the denominator is zero (avoid division by zero)
    percentChange = dataAll.reports[0].score * 100;
  }

  const collapseId = `collapse-${dataAll.test._id}`;

  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="card shadow-sm">
          <div className="card-body">
            <div className="row">
              <div className="col-12 y d-flex justify-content-between align-items-center">
                <div>
                  <h3>
                    <a href={"/test/" + dataAll.test._id}>
                      {dataAll.test.name}
                    </a>
                  </h3>
                  <p>
                    Last Attempted on{" "}
                    <b>
                      {new Date(dataAll.reports[0].createdAt).toLocaleString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </b>
                  </p>

                  <h3>
                    {dataAll.reports[0].score.toFixed(2)} /{" "}
                    {dataAll.test.questionsLength * 2}
                  </h3>
                  <p>
                    {percentChange < 100 ? (
                      <>
                        <img
                          src="/assets/icons/trending-down.svg"
                          className="img-fluid h-100 me-2"
                        />
                        <span style={{ color: "#DB0001" }}>
                          -{percentChange.toFixed(2)}% Decrease{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        <img
                          src="/assets/icons/trending-up.svg"
                          className="img-fluid h-100 me-2"
                        />
                        <span style={{ color: "#42CB79" }}>
                          +{percentChange.toFixed(2)}% Increase
                        </span>
                      </>
                    )}
                    <span className="fw-light"> than last attempt</span>
                  </p>
                </div>

                <div className="d-flex flex-column align-items-center">
                  <a
                    className="btn btn-outline-warning"
                    href={"/report/" + dataAll.reports[0]._id}
                  >
                    View Report
                  </a>

                  <a
                    data-bs-toggle="collapse"
                    href={`#${collapseId}`}
                    role="button"
                    aria-expanded="false"
                    aria-controls={collapseId}
                    className="mt-2"
                    onClick={() => loadAllReports()}
                  >
                    View All Reports
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {" "}
                <div className="collapse" id={collapseId}>
                  <hr />
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Date Attempted</th>
                        <th scope="col">Score</th>
                        <th scope="col">Accuracy</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody className="table-group-divider">
                      {allReports.length > 0 ? (
                        allReports.map((data, index) => (
                          <tr key={index}>
                            <th scope="row">{data.attemptedAt}</th>
                            <td>{data.score.toFixed(2)}</td>
                            <td>{data.accuracy.toFixed(2)} %</td>
                            <td>
                              <a
                                className="btn btn-outline-success"
                                href={"/report/" + data.id}
                              >
                                View Report
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <th scope="row">
                            <p className="placeholder-glow">
                              <span className="placeholder col-12"></span>
                            </p>
                          </th>
                          <td>
                            <p className="placeholder-glow">
                              <span className="placeholder col-12"></span>
                            </p>
                          </td>
                          <td>
                            <p className="placeholder-glow">
                              <span className="placeholder col-12"></span>
                            </p>
                          </td>
                          <td>
                            <a
                              className="btn btn-primary disabled placeholder col-4"
                              aria-disabled="true"
                            ></a>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SingleTest = ({ data }) => {
  if (!data) {
    return (
      <div className="row mb-3">
        <div className="col-12">
          <div className="card shadow">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <p className="placeholder-glow">
                  <span className="placeholder col-12"></span>
                </p>

                <p className="placeholder-glow">
                  Last Attempted on <span className="placeholder col-12"></span>
                </p>

                <p className="placeholder-glow">
                  <span className="placeholder col-12"></span>
                </p>
              </div>

              <div className="d-flex flex-column align-items-center">
                <a className="btn btn-outline-warning disabled placeholder">
                  View Report
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (data.reports.length === 1) {
    return (
      <div className="row mb-3">
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="row">
                <div className="col-12 y d-flex justify-content-between align-items-center">
                  <div>
                    <h3>
                      <a href={"/test/" + data.test._id}>{data.test.name}</a>
                    </h3>
                    <p>
                      Last Attempted on{" "}
                      <b>
                        {new Date(data.reports[0].createdAt).toLocaleString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </b>
                    </p>

                    <h3>
                      {data.reports[0].score.toFixed(2)} /{" "}
                      {data.test.questionsLength * 2}
                    </h3>
                  </div>

                  <div className="d-flex flex-column align-items-center">
                    <a
                      className="btn btn-outline-warning"
                      href={"/report/" + data.reports[0]._id}
                    >
                      View Report
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <MultipleAttemptsTile dataAll={data} />;
};

export default SingleTest;
