import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import { Helmet } from "react-helmet";
import CurrentQuestion from "../TestAttempt/CurrentQuestion";
import ButtonStatusSideBar from "../TestAttempt/ButtonStatusSideBar";

const TestAttemptLayout = ({ test }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [answerMap, setAnswerMap] = useState(
    new Array(test.questions.length).fill(-1)
  );

  const [timeMap, setTimeMap] = useState(
    new Array(test.questions.length).fill(0)
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const id = setInterval(() => {
      setTimeMap((prevMap) => {
        const newMap = [...prevMap];
        newMap[currentQuestion] += 1;
        return newMap;
      });
    }, 1000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [currentQuestion]);

  const genKnowledgeScore = () => {
    let kScore = 0;
    let totalWeights = 0;
    test.questions.forEach((question, index) => {
      totalWeights += question.weight;
      if (answerMap[index] === question.correct) {
        kScore += question.weight;
      } else if (answerMap[index] !== question.correct) {
        kScore = kScore - 0.5 * question.weight;
      } else if (answerMap[index] !== -1) {
        kScore = kScore - 0.25 * question.weight;
      }
    });

    let score = kScore / totalWeights;
    return score < 0 ? 0 : score;
  };

  const handleGenerateReport = () => {
    setIsSubmitting(true);
    clearInterval(intervalId);

    const wrong = answerMap.filter(
      (answer, index) =>
        answer !== test.questions[index].correct && answer !== -1
    ).length;
    const correct = answerMap.filter(
      (answer, index) => answer === test.questions[index].correct
    ).length;
    const accuracy = (correct / (wrong + correct)) * 100;
    if (isNaN(accuracy) || accuracy === null) {
      accuracy = 0;
    }

    const topicReport = {
      wrong,
      correct,
      accuracy,
      topicTest: test._id,
      knowledge_score: genKnowledgeScore(),
      time: timeMap,
      answers: answerMap,
    };

    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        const payloadHeader = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        axios
          .post(apiUrl + "topic-reports", topicReport, payloadHeader)
          .then((response) => response.data)
          .then((testReport) => {
            setIsSubmitting(false);
            navigate("/topic-report/" + testReport._id);
          });
      });
    }
  };

  return (
    <>
      <section className="section mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-3  d-none d-lg-block d-md-block d-sm-none d-xs-none">
              <h4>Questions</h4>

              <div className="list-group">
                {test.questions.map((question, index) => {
                  return (
                    <button
                      type="button"
                      className="list-group-item list-group-item-action"
                      aria-current="true"
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentQuestion(index);
                      }}
                    >
                      <b>Q {index + 1}. </b>
                      <div
                        dangerouslySetInnerHTML={{ __html: question.statement }}
                      />
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="col-sm-6">
              <div>
                <button
                  className="btn btn-outline-primary btn-sm"
                  id="clear"
                  onClick={() => {
                    if (currentQuestion > 0) {
                      setCurrentQuestion(currentQuestion - 1);
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  className="btn btn-outline-danger mx-2 btn-sm"
                  id="clear"
                  onClick={() => {
                    let newAnswerMap = [...answerMap];
                    newAnswerMap[currentQuestion] = -1;
                    setAnswerMap(newAnswerMap);
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-success ml-2 btn-sm"
                  id="next"
                  onClick={() => {
                    if (currentQuestion < test.questions.length - 1) {
                      setCurrentQuestion(currentQuestion + 1);
                    }
                  }}
                >
                  Next Question
                </button>
              </div>
              <br />
              <div>
                <CurrentQuestion
                  question={test.questions[currentQuestion]}
                  index={currentQuestion}
                  answerMap={answerMap}
                  setAnswerMap={setAnswerMap}
                />
              </div>

              <div className="mb-3">
                <button
                  className="btn btn-outline-primary btn-sm"
                  id="clear"
                  onClick={() => {
                    if (currentQuestion > 0) {
                      setCurrentQuestion(currentQuestion - 1);
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  className="btn btn-outline-danger mx-2 btn-sm"
                  id="clear"
                  onClick={() => {
                    let newAnswerMap = [...answerMap];
                    newAnswerMap[currentQuestion] = -1;
                    setAnswerMap(newAnswerMap);
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-success ml-2 btn-sm"
                  id="next"
                  onClick={() => {
                    if (currentQuestion < test.questions.length - 1) {
                      setCurrentQuestion(currentQuestion + 1);
                    }
                  }}
                >
                  Next Question
                </button>
              </div>
            </div>

            <div className="col-sm-3">
              <div id="status">
                <center>
                  <button
                    className="btn btn-success"
                    data-mdb-toggle="modal"
                    data-mdb-target="#exampleModal"
                    id="msub"
                    onClick={handleGenerateReport}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Submitting...</span>
                      </div>
                    ) : (
                      "Submit Test"
                    )}
                  </button>

                  <br />
                  <ButtonStatusSideBar
                    answerMap={answerMap}
                    setCurrentQuestion={setCurrentQuestion}
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ConfirmationModal = ({ testName, noOfQuestions, setStart }) => {
  const navigate = useNavigate();
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>{testName}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ListGroup as="ol" numbered>
            <ListGroup.Item as="li">
              Complete this test in {noOfQuestions * 2} minutes to obtain your
              knowledge score
            </ListGroup.Item>
            <ListGroup.Item as="li">
              Click on the Start button to start the Test{" "}
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              navigate("/tests");
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => setStart(true)}>
            Start
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

const TopicTestAttemptPage = () => {
  const [test, setTest] = useState(null);
  const [start, setStart] = useState(false);
  const [limited, setLimited] = useState(false);
  const params = useParams();
  const testId = params.testId;
  const { currentUser } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    // Fetch the test data unconditionally and at the top level
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        const payloadHeader = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        axios
          .get(apiUrl + "topics/topic/" + testId, payloadHeader)
          .then((response) => response.data)
          .then((test) => {
            console.log("Test", test);
            if (test.msg && test.msg === 2) {
              setLimited(true);
            } else {
              setTest(test);
            }
            setLoading(false); // Set loading to false after data is fetched
          })
          .catch((err) => {
            console.log(err);
            setLoading(false); // Set loading to false if there's an error
          });
      });
    }
  }, [currentUser, testId]); // Add dependencies for the useEffect hook

  if (!currentUser) {
    return (
      <div className="section my-5">
        <div className="container text-center">
          <h4>You are not Authorized to Attempt this Track</h4>
          <a href="/upgrade" className="btn btn-primary">
            Join RANKONE Program Now
          </a>
        </div>
      </div>
    );
  }

  if (limited) {
    return (
      <>
        <div className="section mb-5">
          <div className="container text-center mt-5">
            <h3>
              You can not attempt this Topic Knowledge Test more than once
            </h3>
            <a className="btn btn-primary" href="/dashboard">
              Go To Dashboard
            </a>
          </div>
        </div>
      </>
    );
  }

  if (loading) {
    return <p>Loading...</p>; // Display a loading message while fetching data
  }

  if (!test) {
    return <p>Failed to fetch test data</p>; // Display an error message if test data is not available
  }

  if (!start) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{test.name}</title>
          <meta name="description" content={test.seodesc} />
          <meta property="og:description" content={test.seodesc} />
          <meta property="og:title" content={test.name} />
          <meta property="og:image" content={test.poster} />
          <meta
            property="og:url"
            content={"https://saralupsc.com/test/" + test._id}
          />
        </Helmet>

        <ConfirmationModal
          testName={test.name}
          noOfQuestions={test.questions.length}
          setStart={setStart}
        />
      </>
    );
  }

  if (start) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{test.name}</title>
          <meta name="description" content={test.seodesc} />
          <meta property="og:description" content={test.seodesc} />
          <meta property="og:title" content={test.name} />
          <meta property="og:image" content={test.poster} />
          <meta
            property="og:url"
            content={"https://saralupsc.com/test/" + test._id}
          />
        </Helmet>

        <TestAttemptLayout test={test} />
      </>
    );
  }
};

export default TopicTestAttemptPage;
