import React from "react";

const MainsQuestionTile = ({ question }) => {
  if (!question) {
    return (
      <a class="list-group-item list-group-item-action" disabled>
        <p class="placeholder-glow">
          <span class="placeholder col-12"></span>
        </p>
      </a>
    );
  }
  return (
    <a
      href={"/mains-question/" + question.slug}
      class="list-group-item list-group-item-action"
    >
      <h5>{question.statement}</h5>
      <small className="fw-bold">{question.marks} Marks</small> |{" "}
      {question.year ? (
        <small className="text-danger fw-bold">{question.year + " | "}</small>
      ) : (
        <></>
      )}
      <small>{question.subject.toUpperCase()}</small>
    </a>
  );
};

export default MainsQuestionTile;
