import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TestCard from "./TestCard";

const SubjectTests = () => {
  const { subject } = useParams();
  const [tests, setTests] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMoreTests, setHasMoreTests] = useState(true);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true); // Initial loading state
  const [isLoadingMore, setIsLoadingMore] = useState(false); // Loading more state

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchTests(page);
  }, [page]);

  const fetchTests = async (page) => {
    try {
      if (page === 0) {
        setIsLoadingInitial(true); // Set initial loading state
      } else {
        setIsLoadingMore(true); // Set loading more state
      }

      const response = await axios.get(`${apiUrl}tests/subject/${subject}`, {
        params: { page: page },
      });

      if (response.data.length === 0) {
        setHasMoreTests(false);
      } else {
        setTests((prevTests) => [...prevTests, ...response.data]);
      }

      setIsLoadingInitial(false); // Set initial loading state to false
      setIsLoadingMore(false); // Set loading more state to false
    } catch (error) {
      console.error("Error fetching tests:", error);

      if (page === 0) {
        setIsLoadingInitial(false); // Set initial loading state to false
      } else {
        setIsLoadingMore(false); // Set loading more state to false
      }
    }
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <section>
        <div className="container mt-5">
          <div className="row row-cols-1 row-cols-md-4 g-4 ">
            {isLoadingInitial ? (
              <TestCard />
            ) : tests.length === 0 ? (
              <p>No Tests Available</p>
            ) : (
              tests.map((test) => <TestCard key={test.id} test={test} />)
            )}
            {isLoadingMore && <TestCard />}
          </div>
          <center>
            {hasMoreTests ? (
              <button
                className="btn btn-outline-primary mt-4"
                onClick={handleLoadMore}
                disabled={isLoadingMore} // Disable the button when loading
              >
                {isLoadingMore ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    {"    "}
                    Load More
                  </>
                )}
              </button>
            ) : (
              <p className="mt-4">No More Tests</p>
            )}
          </center>
        </div>
      </section>
      <section>
        <div className="container mt-5">
          <img
            src="/assets/images/rankonetestsbanner.png"
            className="img-fluid"
            alt="RankOne Tests Banner"
          />
        </div>
      </section>
    </>
  );
};

export default SubjectTests;
