import React from "react";

const ScoreColor = ({ score }) => {
  const getColor = (score) => {
    if (score >= 0 && score <= 0.3) {
      return "#EB5757";
    } else if (score > 0.3 && score <= 0.7) {
      return "#F2994A";
    } else if (score > 0.7 && score <= 1) {
      return "#42CB79";
    } else {
      return "black"; // Default color if score is out of range
    }
  };

  const color = getColor(score);

  return <span style={{ color }}>{(score * 100).toFixed(2)}%</span>;
};

export default ScoreColor;
