import React, { useEffect } from "react";

const TestSeriesForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && window.Tally) {
      window.Tally.loadEmbeds();
    }
  }, []);

  return (
    <div>
      <script src="https://tally.so/widgets/embed.js"></script>
      <iframe
        data-tally-src="https://tally.so/embed/nW8jke?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
        loading="lazy"
        width="100%"
        height="567"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Smart RankOne Test Series"
      ></iframe>
    </div>
  );
};

export default TestSeriesForm;
