import React from "react";

const MainsAnswerItem = ({ answer }) => {
  const status = answer.status;
  return (
    <>
      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            <a
              href={"/mains-question/" + answer.question.slug}
              className="text-decoration-none text-secondary fw-bold"
            >
              {answer.question.statement}
            </a>
          </div>

          {status === "UNDER_EVAL" ? (
            <></>
          ) : (
            <a href={"/mains-question/" + answer.question.slug}>
              See Faculty Comments
            </a>
          )}

          <p>
            <small className="text-danger">
              {answer.question.subject.toUpperCase()}
            </small>
          </p>
        </div>

        {status === "UNDER_EVAL" ? (
          <span class="badge bg-warning rounded-pill">Under Evaluation</span>
        ) : (
          <span class="badge bg-success rounded-pill">Evaluated</span>
        )}
      </li>
    </>
  );
};

export default MainsAnswerItem;
