import React from "react";
import SingleQuestion from "./SingleQuestion";

const TestReportDummy = () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <h3 className="mb-4">Test Overview</h3>
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img
                    src="/assets/icons/trophy.svg"
                    className="img mt-3 mb-3"
                    alt="Trophy"
                  ></img>
                  <h5>Congratulation !</h5>
                  <p className="placeholder-glow">
                    <span className="fw-light">you have scored </span>
                    <span
                      className=" fw-bold"
                      style={{ fontSize: "20px", color: "#4bb543" }}
                    >
                      <span className="placeholder col-12"></span>
                    </span>
                  </p>
                </div>
                <hr />
                <div className="d-flex justify-content-around">
                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/icons/qa.svg"
                        className="img-fluid"
                        width="40px"
                        alt="total questions"
                      ></img>
                      <h4 className="ms-2">
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                        </p>
                      </h4>
                    </div>
                    <p
                      className="text-center mt-2 text-secondary"
                      style={{ fontSize: "20px" }}
                    >
                      Total Ques
                    </p>
                  </div>

                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/icons/Checkmark.svg"
                        className="img-fluid"
                        width="40px"
                        alt="correct questions"
                      ></img>
                      <h4 className="ms-2">
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                        </p>
                      </h4>
                    </div>
                    <p
                      className="text-center mt-2 text-secondary"
                      style={{ fontSize: "20px" }}
                    >
                      Correct
                    </p>
                  </div>

                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/icons/cross.svg"
                        className="img-fluid"
                        width="40px"
                        alt="wrong questions"
                      ></img>
                      <h4 className="ms-2">
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                        </p>
                      </h4>
                    </div>
                    <p
                      className="text-center mt-2 text-secondary"
                      style={{ fontSize: "20px" }}
                    >
                      Wrong
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-around mt-4">
                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/icons/target.svg"
                        className="img-fluid"
                        width="40px"
                        alt="accuracy"
                      ></img>
                      <h4 className="ms-2">
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                        </p>
                        %
                      </h4>
                    </div>
                    <p
                      className="text-center mt-2 text-secondary"
                      style={{ fontSize: "20px" }}
                    >
                      Accuracy
                    </p>
                  </div>

                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/icons/clock.svg"
                        className="img-fluid"
                        width="40px"
                        alt="time taken"
                      ></img>
                      <h4 className="ms-2">
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                        </p>
                      </h4>
                    </div>
                    <p
                      className="text-center mt-2 text-secondary"
                      style={{ fontSize: "20px" }}
                    >
                      Time Taken
                    </p>
                  </div>
                </div>

                <center>
                  <button className="btn btn-danger">Attempt Again</button>
                </center>
              </div>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="d-flex justify-content-between">
              <h3 className="mb-4 placeholder-glow">
                <span
                  className="placeholder col-12 placeholder-lg"
                  style={{ width: "400px" }}
                ></span>
              </h3>

              <button
                type="button"
                className="btn btn-warning dropdown-toggle mb-3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></button>
              <ul className="dropdown-menu">
                <li>
                  <button className="dropdown-item">All Questions</button>
                </li>
                <li>
                  <button className="dropdown-item">Correct</button>
                </li>
                <li>
                  <button className="dropdown-item">Incorrect</button>
                </li>
                <li>
                  <button className="dropdown-item">Unanswered</button>
                </li>
              </ul>
            </div>

            <SingleQuestion />

            <button type="button" className="btn btn-dark me-2">
              Previous
            </button>
            <button type="button" className="btn btn-dark">
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestReportDummy;
