import axios from "axios";
import { signInWithPhoneNumber } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../firebase";

const MentorReviews = ({ mentorId }) => {
  const [topReviews, setTopReviews] = useState([]);
  const [submittingReview, setSubmittingReview] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [ratingData, setRatingData] = useState({
    mentorId: mentorId,
    rating: 0,
    reviewMessage: "",
  });

  const { currentUser } = useAuth();

  const sendOTP = () => {
    // Perform logic to send OTP
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+91" + formData.phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).

        window.confirmationResult = confirmationResult;

        // ...
      })
      .catch((error) => {});
  };

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRatingChange = (e) => {
    // Extract the rating value from the data attribute in the dropdown
    const ratingValue = e.target.getAttribute("data-value");
    setRatingData({
      ...ratingData,
      rating: ratingValue,
    });
  };

  const handleReviewChange = (e) => {
    // Extract the rating value from the data attribute in the dropdown
    const reviewMessage = e.target.value;
    setRatingData({
      ...ratingData,
      reviewMessage: reviewMessage,
    });
  };

  const registerStudent = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}student/register-student`,
        formData
      );
      console.log("Student registered:", response.data);

      // If registration is successful, call sendOTP
      sendOTP();
    } catch (error) {
      console.error("Error registering student:", error);
    }
  };

  const submitReview = () => {
    currentUser.getIdToken().then((token) => {
      const payloadHeader = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .post(
          `${apiUrl}reviews`, // Update with the correct endpoint
          ratingData,
          payloadHeader
        )
        .then((response) => {})
        .catch((error) => {
          console.error("Error submitting answer", error);
        });
    });
  };

  const handleReviewSubmission = async (e) => {
    e.preventDefault();
    if (currentUser === null) {
      registerStudent();
    } else {
      submitReview();
    }
  };

  useEffect(() => {
    const fetchTopReviews = async () => {
      try {
        const response = await axios.get(`${apiUrl}top-reviews/${mentorId}`);
        setTopReviews(response.data);
      } catch (error) {
        console.error("Error fetching top reviews:", error);
      }
    };

    fetchTopReviews();
  }, [apiUrl, mentorId]);

  return (
    <div className="card border-1 shadow-sm p-2 p-lg-0">
      <div className="card-body p-lg-5">
        <h2 className="h3 text-danger mb-4">Mentor Reviews</h2>
        {topReviews.map((review, index) => (
          <div key={index}>
            <div className="row mb-1">
              <div className="col-lg-8">
                {/* Display review details */}
                <div className="d-flex align-items-center">
                  {/* Display reviewer's image */}
                  <img
                    className="rounded-circle"
                    src="https://i.pinimg.com/originals/09/04/9a/09049aa9d6e8cb79674ab772702b8c9b.jpg"
                    alt=""
                    width="40"
                  />
                  <div className="ms-2">
                    <h6 className="mb-0 text-danger">
                      {review.studentId ? review.studentId.phone : <></>}
                      {review.verified ? (
                        <span class="badge text-bg-success ms-2">
                          Verified Review
                        </span>
                      ) : (
                        <></>
                      )}
                    </h6>
                    <p className="small text-muted mb-0 fw-bold">
                      {new Date(review.date).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </p>

                    <ul className="list-inline mb-0 small ms-5 ms-lg-0">
                      {Array.from({ length: 5 }, (_, i) => {
                        if (i < review.rating) {
                          return (
                            <li key={i} className="list-inline-item m-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#FEC000"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </li>
                          );
                        } else {
                          return (
                            <li key={i} className="list-inline-item m-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#FEC000"
                                className="bi bi-star"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                              </svg>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ps-5">
              <div className="col-12">
                {/* Display review text */}
                <p className="text-sm">{review.reviewMessage}</p>
              </div>
            </div>
          </div>
        ))}

        {topReviews.length < 1 ? (
          <div class="d-grid gap-2 col-6 mx-auto">
            <p className="text-center">
              Leave a review for your mentor and show your gratitude towards
              them
            </p>
            <button
              class="btn btn-danger"
              type="button"
              href="#reviewPanel"
              role="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-control="reviewPanel"
            >
              Leave a review
            </button>
          </div>
        ) : (
          <>
            {" "}
            <a
              className="btn btn-secondary"
              href="#reviewPanel"
              role="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-control="reviewPanel"
            >
              Add a review
            </a>
          </>
        )}

        <div className="collapse" id="reviewPanel">
          <div className="pt-4">
            <form onSubmit={handleReviewSubmission}>
              <div className="row gy-3">
                {currentUser ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <div className="col-lg-6">
                      <label className="form-label" for="name">
                        Full Name
                      </label>
                      <input
                        className="form-control form-control"
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Your Full Name"
                        onChange={handleInputChange} // Add onChange handler
                        required
                      />
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label" for="phone">
                        Mobile Number
                      </label>
                      <input
                        className="form-control form-control"
                        id="phone"
                        type="text"
                        name="phone"
                        placeholder="Your Mobile Number"
                        onChange={handleInputChange} // Add onChange handler
                        required
                      />
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label" for="email">
                        Email
                      </label>
                      <input
                        className="form-control form-control"
                        id="firstName"
                        type="email"
                        name="email"
                        placeholder="Your Email Address"
                        onChange={handleInputChange} // Add onChange handler
                        required
                      />
                    </div>
                  </>
                )}

                <div className="col-lg-6">
                  <label className="form-label" for="lastName">
                    Rate your mentor
                  </label>
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle w-100"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {ratingData.rating}
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={handleRatingChange}
                          data-value={1}
                        >
                          1. Mentor did not meet my expectations
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={handleRatingChange}
                          data-value={2}
                        >
                          2. Mentor had some impact on my preparation
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={handleRatingChange}
                          data-value={3}
                        >
                          3. Mentor added 20-30 marks in my Prelims Score
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={handleRatingChange}
                          data-value={4}
                        >
                          4. Mentor added 50+ marks in my final score
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={handleRatingChange}
                          data-value={5}
                        >
                          5. Mentor made UPSC too Saral
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-12">
                  <label className="form-label" for="reviewMessage">
                    What would you like to say about your mentor
                  </label>
                  <textarea
                    className="form-control form-control"
                    id="reviewMessage"
                    name="reviewMessage"
                    onChange={handleReviewChange} // Add onChange handler
                    required
                    rows="5"
                    placeholder="Mentor ke baare mai 2 shabad"
                  ></textarea>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary" type="submit">
                    Post your review
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorReviews;
