import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import MainsAnswerItem from "./MainsAnswerItem";

const MainsAnswerList = () => {
  const [data, setData] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchMyAnswers = async () => {
      try {
        if (currentUser) {
          const token = await currentUser.getIdToken();
          const apiUrl = process.env.REACT_APP_API_URL;

          const payloadHeader = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          const response = await fetch(
            `${apiUrl}mains-answers/my-answers`,
            payloadHeader
          );
          const answerData = await response.json();
          setData(answerData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchMyAnswers();
  }, [currentUser]);

  if (!data) {
    return (
      <div>
        <p className="fw-lighter mb-0">Answer Writing</p>
        <h3 className="mb-3">Your Recent Answers</h3>

        <ul className="list-group list-group-numbered"></ul>
      </div>
    );
  }

  return (
    <div>
      <p className="fw-lighter mb-0">Answer Writing</p>
      <h3 className="mb-3">Your Recent Answers</h3>

      <ul className="list-group list-group-numbered">
        {data.length > 0 ? (
          data.map((answer) => (
            <MainsAnswerItem key={answer._id} answer={answer} />
          ))
        ) : (
          <>
            <p>You have not answered any Mains Question Yet</p>
          </>
        )}
      </ul>
    </div>
  );
};

export default MainsAnswerList;
