import React from "react";
import TestingChart from "./TestingChart";

const ReportAnalytics = ({ accuracy, time, knowledge_score }) => {
  if (!accuracy || !time || !knowledge_score) {
    return (
      <div className="row">
        <div className="col-sm-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <h6 className="fw-bold text-danger">
                    Average Time per Question
                  </h6>
                  <h3 class="placeholder-glow">
                    <span class="placeholder col-12"></span>
                  </h3>
                </div>
                <div className="col-6">
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <h6 className="fw-bold text-danger">Accuracy Level</h6>
                  <h3 class="placeholder-glow">
                    <span class="placeholder col-12"></span>
                  </h3>
                </div>
                <div className="col-6">
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <h6 className="fw-bold text-danger">Knowledge Score</h6>
                  <h3 class="placeholder-glow">
                    <span class="placeholder col-12"></span>
                  </h3>
                </div>
                <div className="col-6">
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const avgTime =
    time.filter((time) => time > 0).reduce((total, time) => total + time, 0) /
    time.filter((time) => time > 0).length;

  // Checking if avgTime is NaN, which could happen if all values are -1 or 0
  const avgTimeDisplay = isNaN(avgTime) ? "N/A" : `${avgTime.toFixed(2)}s`;
  return (
    <div className="row">
      <div className="col-sm-4">
        <div className="card shadow-sm">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h6 className="fw-bold text-danger">
                  Average Time per Question
                </h6>
                <h3>{avgTimeDisplay}</h3>
              </div>
              <div className="col-6">
                <TestingChart data={avgTimeDisplay} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="card shadow-sm">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h6 className="fw-bold text-danger">Accuracy Level</h6>
                <h3>{accuracy.toFixed(2)}</h3>
              </div>
              <div className="col-6">
                <TestingChart data={accuracy} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="card shadow-sm">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h6 className="fw-bold text-danger">Knowledge Score</h6>
                <h3>{knowledge_score}</h3>
              </div>
              <div className="col-6">
                <TestingChart data={knowledge_score} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportAnalytics;
