import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import SubjectStatus from "./SubjectStatus";

const subjects = [
  "Polity",
  "Modern History",
  "Ancient History",
  "Economy",
  "Science & Tech",
  "Medieval History",
  "Environment",
  "Geography",
];

const PreparationScore = () => {
  const { currentUser } = useAuth();
  const [scores, setScores] = useState([]);

  useEffect(() => {
    // Fetch the API URL from environment variables
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchMyReports = async () => {
      try {
        // Retrieve the currentUser token

        if (currentUser) {
          const token = await currentUser.getIdToken();

          // Set the authorization header
          const payloadHeader = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          // Make a request to /tests/my-reports
          const response = await fetch(
            `${apiUrl}subject-knowledge-score`,
            payloadHeader
          );
          const data = await response.json();
          setScores(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchMyReports();
  }, []);

  if (!scores) {
    return <></>;
  }

  return (
    <>
      <p className="fw-lighter mb-0">Dashboard</p>
      <h3>Your Subject Knowledge Score</h3>
      {subjects.map((subject) => {
        const score = scores.find((s) => s._id === subject.toLowerCase());
        const percentage = score ? score.subjectKnowledgeScore : 0;
        return (
          <SubjectStatus
            key={subject}
            subject={subject}
            percentage={percentage}
          />
        );
      })}
    </>
  );
};

export default PreparationScore;
