import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import RegistrationForm from "./components/RegistrationForm";

const testimonials = [
  {
    id: 1,
    name: "Rohan",
    comment:
      "I tried SaralUPSC Test Series which helped me learn a lot of things",
    imgUrl:
      "https://img.etimg.com/authorthumb/msid-00479169717,width-358,height-353,imgsize-00479169717,resizemode-8.cms",
  },
  {
    id: 4,
    name: "Vanshika Ghosh",
    comment:
      "Learning from their website has been so great. I would recommend it to other aspirants too",
    imgUrl:
      "https://isg-one.com/images/default-source/default-album/madan-vanshika-pic-sq.tmb-th595-583.jpg?sfvrsn=e12adc31_0",
  },
  {
    id: 2,
    name: "Kanishk Kamra",
    comment:
      "These test series are just fun. You attempt them and then check for the answers and learn a bit. Then Attempt again and try to score higher and higher",
    imgUrl:
      "https://i.ndtvimg.com/i/2018-03/bhupesh-jain-kanishk-gold_270x300_41521691663.jpg",
  },
  {
    id: 3,
    name: "Kunal Mittal",
    comment:
      "I had my last attempt pending and i also bought Saral UPSC Test Series which took some pressure off me and made me learn something",
    imgUrl:
      "https://nyulangone.org/images/doctors/m/mehta/1902115553/kunal-mehta-square.jpg",
  },

  {
    id: 5,
    name: "Ankita",
    comment:
      "I come from a small town where no big coaching is there. SaralUPSC was able to provide quality learning",
    imgUrl:
      "https://media-exp1.licdn.com/dms/image/C5603AQHImgCMBIoqDQ/profile-displayphoto-shrink_800_800/0/1642488794055?e=2147483647&v=beta&t=_bY9LQCptcscDJbMEeonXC-ChNokOKxe2Pkbc0qDMDw",
  },
];

const HomePage = () => {
  return (
    <>
      <section>
        <div className="px-4 pt-5 my-5 text-center border-bottom">
          <h1 className="display-4 fw-bold text-body-emphasis">
            Rank #1 Programme for{" "}
            <span className="text-danger border-bottom border-danger">
              Serious Aspirants
            </span>
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              Welcome to the Rank #1 Programme, designed exclusively for UPSC
              CSE Aspirants who are determined to conquer the UPSC Prelims in
              their very first attempt. Our team of experienced and dedicated
              faculties have meticulously crafted this course, integrating
              cutting-edge AI Enabled Preparation Tracks.
            </p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
              {/*    <a
                type="button"
                className="btn btn-danger btn-lg px-4 w-100 w-sm-50"
                href="https://forms.gle/X6WJW3aiGNprVgRNA"
                target="_blank"
              >
                Enroll Now
              </a> */}

              <button
                type="button"
                class="btn btn-danger btn-lg px-4 w-100 w-sm-50"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Enroll Now
              </button>
            </div>
          </div>
          <div className="overflow-hidden" style={{ maxHeight: "30vh" }}>
            <div className="container px-5">
              <img
                src="/assets/images/banner-hero.png"
                className="img-fluid border rounded-3 shadow-lg mb-4"
                alt="Example image"
                width="700"
                height="500"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="container px-4 py-5">
          <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
            <div className="col d-flex flex-column align-items-start gap-2">
              <h2 className="fw-bold text-body-emphasis">
                A Programme Designed for Success in{" "}
                <span className="text-danger border-bottom border-danger">
                  Prelims in First Attempt
                </span>{" "}
              </h2>
              <p className="text-body-secondary">
                Embark on a journey of excellence with our Rank #1 Programme,
                tailored to help you succeed in UPSC Prelims on your first
                attempt. Our comprehensive approach, guided by experienced
                faculties and AI technology, sets the foundation for your
                triumph.
              </p>

              <button
                type="button"
                class="btn btn-danger btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Enroll Now
              </button>
            </div>

            <div className="col">
              <div className="row row-cols-1 row-cols-sm-2 g-4">
                <div className="col d-flex flex-column gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    class="bi bi-camera-video"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z"
                    />
                  </svg>

                  <h4 className="fw-semibold mb-0 text-body-emphasis">
                    Online Live Classes by Experienced Faculty
                  </h4>
                  <p className="text-body-secondary">
                    Engage in real-time learning through dynamic online live
                    classes hosted by our experienced faculty members.
                  </p>
                </div>

                <div className="col d-flex flex-column gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    class="bi bi-newspaper"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z" />
                    <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 2h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z" />
                  </svg>
                  <h4 className="fw-semibold mb-0 text-body-emphasis">
                    Preparation Tracks for Comprehensive Analysis
                  </h4>
                  <p className="text-body-secondary">
                    Stay ahead with our well-structured preparation tracks that
                    provide in-depth analysis and comprehensive coverage of the
                    syllabus.
                  </p>
                </div>

                <div className="col d-flex flex-column gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    class="bi bi-bar-chart-steps"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z" />
                  </svg>
                  <h4 className="fw-semibold mb-0 text-body-emphasis">
                    Previous Year Paper Practice
                  </h4>
                  <p className="text-body-secondary">
                    Strengthen your preparation by solving previous year papers,
                    gaining insights into the exam pattern, and mastering time
                    management.
                  </p>
                </div>

                <div className="col d-flex flex-column gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    class="bi bi-calendar4-week"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                    <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                  </svg>
                  <h4 className="fw-semibold mb-0 text-body-emphasis">
                    Doubt Sessions and Live Interactions with IAS, IPS
                  </h4>
                  <p className="text-body-secondary">
                    Get your questions answered in real-time during dedicated
                    doubt sessions and live interactions with IAS and IPS
                    officers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-5 bt-2">
          <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
            <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis">
                <span className="text-danger border-bottom border-danger">
                  AIR 13, 2019
                </span>{" "}
                Trusted Us
              </h1>
              <p className="lead">
                We're proud to have been trusted by AIR 13, 2019. Our commitment
                to excellence and comprehensive approach has helped candidates
                achieve their UPSC goals.
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                <button
                  type="button"
                  class="btn btn-danger btn-lg px-4 me-md-2 fw-bold"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Enroll Now
                </button>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden">
              <img
                className="rounded-lg-3"
                src="/assets/images/upscranker.png"
                alt="UPSC Ranker"
                width="350"
              />
            </div>
          </div>
        </div>

        <div className="container px-4 py-5" id="hanging-icons">
          <h2 className="fw-bold text-body-emphasis text-center">
            Don't Try Our{" "}
            <span className="text-danger border-bottom border-danger">
              SECRET
            </span>{" "}
            Formula
          </h2>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
            <div className="col d-flex align-items-start">
              <div className="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-book"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
              </div>
              <div>
                <h3 className="fs-2 text-body-emphasis">General Studies</h3>
                <p>
                  Explore comprehensive study material and resources for General
                  Studies. Our content is designed to help you grasp key
                  concepts and excel in your preparation.
                </p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-lightbulb-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z" />
                </svg>
              </div>
              <div>
                <h3 className="fs-2 text-body-emphasis">CSAT</h3>
                <p>
                  Master your CSAT preparation with our expert guidance. From
                  problem-solving techniques to time management strategies, we
                  cover all aspects to enhance your skills.
                </p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-newspaper"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z" />
                </svg>
              </div>
              <div>
                <h3 className="fs-2 text-body-emphasis">Current Affairs</h3>
                <p>
                  Stay updated with the latest Current Affairs topics. Our
                  resources provide a comprehensive coverage of important news
                  and events relevant to your preparation.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-md-stretch">
            <div className="col-md-6">
              <div className="h-100 p-5 text-bg-dark rounded-3">
                <h2>STATIC</h2>
                <ul>
                  <li>
                    In-depth Study Materials: Comprehensive resources for
                    History, Geography, and Polity.
                  </li>
                  <li>
                    Expert Faculty: Experienced educators simplify complex
                    topics for thorough understanding.
                  </li>
                  <li>
                    Interactive Live Classes: Real-time doubt-solving for better
                    comprehension.
                  </li>
                  <li>
                    Mock Tests: Regular quizzes to gauge progress and identify
                    weak areas.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="h-100 p-5 bg-body-tertiary border rounded-3">
                <h2>DYNAMIC</h2>
                <ul>
                  <li>
                    Current Affairs Updates: Daily news on national and
                    international events.
                  </li>
                  <li>
                    Editorial Analysis: Insights into editorial articles for
                    understanding current affairs.
                  </li>
                  <li>
                    Interactive Discussions: Live webinars with experts for
                    critical analysis.
                  </li>
                  <li>
                    Monthly Magazines: Curated summaries of important events for
                    quick revision.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 bg-danger py-5">
        <h1 className="fw-bold mb-2 text-center text-white">
          How Good Does it Feel to Clear{" "}
          <span className="border-bottom">Prelims in First Attempt?</span>
        </h1>
        <h5 className="text-center mb-5 text-white">
          Read the Joys of Successful Aspirants
        </h5>

        {/* Swiper for desktop mode */}
        <div className="d-none d-md-block">
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <img
                          src={TESTIMONIAL.imgUrl}
                          className="img-fluid rounded-pill me-3"
                          width="75px"
                          alt="Testimonial author"
                        />
                        <div>
                          <p>
                            <b>{TESTIMONIAL.name}</b>
                          </p>
                          <br />
                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        {/* Swiper for mobile mode */}
        <div className="d-md-none">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            freeMode={true}
            navigation={true}
            pagination={{ clickable: true }}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i} className="px-3">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <img
                          src={TESTIMONIAL.imgUrl}
                          className="img-fluid rounded-pill me-3"
                          width="75px"
                          alt="Testimonial author"
                        />
                        <div>
                          <p>
                            <b>{TESTIMONIAL.name}</b>
                          </p>
                          <br />
                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}

            <SwiperSlide className="px-3">
              <div className="card">
                <div className="card-body">
                  <p className="text-muted">
                    Our chatbot simplifies medication management. It sends
                    timely reminders for your meds and assists with prescription
                    refills. No more missed doses or rushed pharmacy trips.
                    Enjoy hassle-free healthcare support
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      src="https://img.etimg.com/authorthumb/msid-00479169717,width-358,height-353,imgsize-00479169717,resizemode-8.cms"
                      className="img-fluid rounded-pill me-3"
                      width="75px"
                      alt="Testimonial author"
                    />
                    <div>
                      <p>
                        <b>Hemant Sharma</b>
                        <br />
                        <span className="text-muted">
                          I have used Saral RANK #1 Programme
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        {/* Second Swiper for desktop mode */}
        <div className="d-none d-md-block mt-4">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <img
                          src={TESTIMONIAL.imgUrl}
                          className="img-fluid rounded-pill me-3"
                          width="75px"
                          alt="Testimonial author"
                        />
                        <div>
                          <p>
                            <b>{TESTIMONIAL.name}</b>
                          </p>
                          <br />
                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>

      <section>
        <div className="container py-5">
          <div className="row align-items-center g-lg-5 py-5">
            <div className="col-lg-7 text-center text-lg-start">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis mb-3">
                Smart Aspirants have already joined Rank #1 Programme
              </h1>
              <p className="col-lg-10 fs-4">
                You can{" "}
                <span className="text-danger border-bottom border-danger fw-bold">
                  FAIL, WASTE A YEAR
                </span>{" "}
                and then come back to us
              </p>{" "}
              <h4>OR</h4>
              <p className="col-lg-10 fs-4">
                be a SMART Aspirant and clear UPSC Prelims in{" "}
                <span className="text-danger border-bottom border-danger fw-bold">
                  FIRST ATTEMPT
                </span>
              </p>
            </div>
            <div className="col-md-10 mx-auto col-lg-5">
              <div className="p-4 p-md-5 border rounded-3 bg-body-tertiary">
                <RegistrationForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <RegistrationForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
