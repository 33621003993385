import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import TestAttemptPage from "./components/TestAttempt/TestAttemptPage";
import TestReportPage from "./components/TestReport/TestReportPage";
import SubjectTests from "./components/Tests/SubjectTests";
import TopicTestAttemptPage from "./components/Track/TopicTestAttemptPage";
import SubjectTrack from "./components/Track/SubjectTrack";
import TopicReport from "./components/Track/TopicReport";
import SingleBlogNew from "./components/Blogs/SingleBlogNew";
import BlogsGridNew from "./components/Blogs/BlogsGridNew";
import HomePage from "./HomePage";
import TermsAndConditions from "./policy-pages/TermsAndConditions";
import RefundPolicy from "./policy-pages/RefundPolicy";
import AboutUs from "./policy-pages/AboutUs";
import PrivacyPolicy from "./policy-pages/PrivacyPolicy";
import QuestionList from "./components/Mains/QuestionList";
import MainsQuestion from "./components/Mains/MainsQuestion";
import NavbarCompact from "./NavbarCompact";
import Footer from "./Footer";
import TestSeries from "./components/LandingPages/TestSeries";
import BoosterBatch from "./components/LandingPages/BoosterBatch";
import MentorGrid from "./components/Mentors/MentorGrid";
import MentorProfile from "./components/Mentors/MentorProfile";
import MentorRegistration from "./components/Mentors/MentorRegistration";
import PrelimsWorkshop from "./components/LandingPages/PrelimsWorkshop";
import MentorRegistrationLanding from "./components/LandingPages/MentorRegistrationLanding";
import CheckoutPage from "./components/Checkout/CheckoutPage";
import MentorPaymentSuccess from "./components/Mentors/MentorPaymentSuccess";
import MentorLogin from "./components/Login/MentorLogin";
import MentorDashboard from "./components/Mentors/MentorDashboard";
import MentorProfileOneHour from "./components/Mentors/MentorProfileOneHour";

function App() {
  return (
    <>
      <BrowserRouter>
        <NavbarCompact />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/mentor-login" element={<MentorLogin />} />
          <Route path="/mentor-dashboard" element={<MentorDashboard />} />

          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/rankone-test-series" element={<TestSeries />} />
          <Route
            path="/rankone-prelims-booster-batch"
            element={<BoosterBatch />}
          />

          <Route path="/articles/:subject" element={<BlogsGridNew />} />
          <Route path="/article/:articleId" element={<SingleBlogNew />} />
          <Route path="/article/:articleId/:slug" element={<SingleBlogNew />} />

          <Route path="/mains-questions" element={<QuestionList />} />
          <Route
            path="/mains-question/:questionSlug"
            element={<MainsQuestion />}
          />

          <Route path="/tests/:subject" element={<SubjectTests />} />
          <Route path="/test/:testId" element={<TestAttemptPage />} />
          <Route
            element={<TopicTestAttemptPage />}
            path="topic-attempt/:testId"
          />
          <Route element={<TopicReport />} path="topic-report/:reportId" />
          {/*    <Route path="/track/:subject" element={<SubjectTrackPage />} /> */}
          <Route path="/track/:subject" element={<SubjectTrack />} />
          <Route path="/topic/:topicId/:reportId" element={<SubjectTrack />} />

          <Route path="/report/:reportId" element={<TestReportPage />} />
          <Route
            path="/rankone-prelims-workshop"
            element={<PrelimsWorkshop />}
          />

          <Route path="/upsc-mentors" element={<MentorGrid />} />
          <Route
            path="/mentorship-confirmation"
            element={<MentorPaymentSuccess />}
          />
          <Route
            path="/upsc-mentor/:mentorId/:slug"
            element={<MentorProfileOneHour />}
          />
          <Route path="/register-mentor" element={<MentorRegistration />} />
          <Route
            path="/mentor-registration"
            element={<MentorRegistrationLanding />}
          />

          <Route path="/checkout" element={<CheckoutPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
