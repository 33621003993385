import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import SingleQuestion from "./SingleQuestion";
import TestReportDummy from "./TestReportDummy";
import TestReportLimited from "./TestReportLimited";

const TestReportPage = () => {
  const { reportId } = useParams();
  const [report, setReport] = useState();
  const { currentUser } = useAuth();
  const [selectedOption, setSelectedOption] = useState("All Questions");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [limited, setLimited] = useState(false);
  const questionsPerPage = 10;

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedOption]);

  useEffect(() => {
    const updateFilteredQuestions = () => {
      const questionsWithStatus = report.test.questions.map(
        (question, index) => {
          let answer = report.answers[index];
          let questionNo = index + 1;
          return {
            ...question,
            answer,
            questionNo,
          };
        }
      );

      const filteredQuestions = questionsWithStatus.filter((question) => {
        if (selectedOption === "All Questions") {
          return true;
        } else if (selectedOption === "Unanswered") {
          return question.answer === -1;
        } else if (selectedOption === "Correct") {
          return question.answer === question.correct;
        } else if (selectedOption === "Incorrect") {
          return question.answer !== question.correct && question.answer !== -1;
        }
      });

      setFilteredQuestions(filteredQuestions);
    };

    if (report) {
      updateFilteredQuestions();
    }
  }, [selectedOption, report]);

  const handleSelectedOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    // Fetch the API URL from environment variables
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchMyReport = async () => {
      try {
        // Retrieve the currentUser token

        if (currentUser) {
          const token = await currentUser.getIdToken();

          // Set the authorization header
          const payloadHeader = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          // Make a request to /tests/my-reports
          const response = await fetch(
            `${apiUrl}report/${reportId}`,
            payloadHeader
          );

          if (response.status === 403) {
            const responseData = await response.json();
            console.log(responseData);
            if (responseData.message === "Limit exceeded") {
              // Set the 'limited' variable to true
              setLimited(true);
            }
          } else {
            const data = await response.json();
            setReport(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchMyReport();
  }, []);

  if (limited) {
    return <TestReportLimited />;
  }

  if (!report) {
    return <TestReportDummy />;
  }

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = filteredQuestions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <h3 className="mb-4">Test Overview</h3>
              <div className="card sticky-top">
                <div className="card-body">
                  <div className="text-center">
                    <img
                      src="/assets/icons/trophy.svg"
                      className="img mt-3 mb-3"
                      alt="Trophy"
                    ></img>
                    <h5>Congratulation !</h5>
                    <p>
                      <span className="fw-light">you have scored </span>
                      <span
                        className=" fw-bold"
                        style={{ fontSize: "20px", color: "#4bb543" }}
                      >
                        {report.score.toFixed(2)} /{" "}
                        {report.test.questions.length * 2}
                      </span>
                    </p>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-around">
                        <div>
                          <div className="d-flex align-items-center">
                            <img
                              src="/assets/icons/qa.svg"
                              className="img-fluid"
                              width="40px"
                              alt="total questions"
                            ></img>
                            <h4 className="ms-2">
                              {report.test.questions.length}
                            </h4>
                          </div>
                          <p
                            className="text-center mt-2 text-secondary"
                            style={{ fontSize: "20px" }}
                          >
                            Total Ques
                          </p>
                        </div>

                        <div>
                          <div className="d-flex align-items-center">
                            <img
                              src="/assets/icons/Checkmark.svg"
                              className="img-fluid"
                              width="40px"
                              alt="correct questions"
                            ></img>
                            <h4 className="ms-2">{report.correct}</h4>
                          </div>
                          <p
                            className="text-center mt-2 text-secondary"
                            style={{ fontSize: "20px" }}
                          >
                            Coorect
                          </p>
                        </div>

                        <div>
                          <div className="d-flex align-items-center">
                            <img
                              src="/assets/icons/cross.svg"
                              className="img-fluid"
                              width="40px"
                              alt="wrong questions"
                            ></img>
                            <h4 className="ms-2">{report.wrong}</h4>
                          </div>
                          <p
                            className="text-center mt-2 text-secondary"
                            style={{ fontSize: "20px" }}
                          >
                            Wrong
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-around mt-4">
                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/target.svg"
                          className="img-fluid"
                          width="40px"
                          alt="accuracy"
                        ></img>
                        <h4 className="ms-2">{report.accuracy.toFixed(2)}%</h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Accuracy
                      </p>
                    </div>

                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/clock.svg"
                          className="img-fluid"
                          width="40px"
                          alt="time taken"
                        ></img>
                        <h4 className="ms-2">{formatTime(report.time)}</h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Time Taken
                      </p>
                    </div>
                  </div>

                  <center>
                    <a
                      className="btn btn-danger"
                      href={"/test/" + report.test._id}
                    >
                      Attempt Again
                    </a>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="d-flex justify-content-between">
                <h3 className="mb-4">{report.test.name}</h3>

                <button
                  type="button"
                  className="btn btn-warning dropdown-toggle mb-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedOption}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => setSelectedOption("All Questions")}
                    >
                      All Questions
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => setSelectedOption("Correct")}
                    >
                      Correct
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => setSelectedOption("Incorrect")}
                    >
                      Incorrect
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => setSelectedOption("Unanswered")}
                    >
                      Unanswered
                    </button>
                  </li>
                </ul>
              </div>

              {currentQuestions.length === 0 ? (
                <p>There are no {selectedOption} questions</p>
              ) : (
                currentQuestions.map((question) => (
                  <SingleQuestion question={question} />
                ))
              )}

              <button
                type="button"
                className="btn btn-dark me-2"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              <button
                type="button"
                className="btn btn-dark"
                onClick={handleNextPage}
                disabled={currentQuestions.length < questionsPerPage}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <img
          src="/assets/images/rankonetestsbanner.png"
          className="img-fluid mt-5"
        ></img>
      </section>
    </>
  );
};

export default TestReportPage;
