import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopicCard from "./TopicCard";

const SubjectTrack = () => {
  const { subject } = useParams();
  const [topics, setTopics] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    try {
      const response = await axios.get(`${apiUrl}topics/${subject}`);
      setTopics(response.data);
      setIsLoading(false); // Set loading state to false after fetching data
    } catch (error) {
      console.error("Error fetching tests:", error);
      setIsLoading(false); // Set loading state to false on error
    }
  };

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-sm-2"></div>
        <div className="col-sm-8">
          <center>
            <h1> {subject.toLocaleUpperCase()} TOPICS</h1>
          </center>

          <div className="mt-4">
            {isLoading ? ( // Display loading state
              <TopicCard />
            ) : topics.length > 0 ? (
              topics.map((topic) => <TopicCard key={topic.id} topic={topic} />)
            ) : (
              <p>No Topics Available</p>
            )}
          </div>
        </div>
        <div className="col-sm-2"></div>
      </div>
    </div>
  );
};

export default SubjectTrack;
