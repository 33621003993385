import React, { useState, useEffect } from "react";

const CountdownTimer = () => {
  const [minutes, setMinutes] = useState(Math.floor(Math.random() * 4) + 8);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let countdownInterval;

    if (minutes >= 0 && seconds >= 0) {
      countdownInterval = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          clearInterval(countdownInterval);
          // You can add code to handle what happens when the timer reaches 0.
        } else if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [minutes, seconds]);

  return (
    <div className="text-center text-emphasis">
      <p className="fw-bold text-danger">
        Your Test Report will get deleted in
      </p>
      <h1>
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </h1>
    </div>
  );
};

export default CountdownTimer;
