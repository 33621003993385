import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import TestSeriesForm from "../TestSeriesForm";

const testimonials = [
  {
    id: 1,
    name: "Rohan",
    comment:
      "I tried SaralUPSC Test Series which helped me learn a lot of things",
    imgUrl:
      "https://img.etimg.com/authorthumb/msid-00479169717,width-358,height-353,imgsize-00479169717,resizemode-8.cms",
  },
  {
    id: 4,
    name: "Vanshika Ghosh",
    comment:
      "Learning from their website has been so great. I would recommend it to other aspirants too",
    imgUrl:
      "https://isg-one.com/images/default-source/default-album/madan-vanshika-pic-sq.tmb-th595-583.jpg?sfvrsn=e12adc31_0",
  },
  {
    id: 2,
    name: "Kanishk Kamra",
    comment:
      "These test series are just fun. You attempt them and then check for the answers and learn a bit. Then Attempt again and try to score higher and higher",
    imgUrl:
      "https://i.ndtvimg.com/i/2018-03/bhupesh-jain-kanishk-gold_270x300_41521691663.jpg",
  },
  {
    id: 3,
    name: "Kunal Mittal",
    comment:
      "I had my last attempt pending and i also bought Saral UPSC Test Series which took some pressure off me and made me learn something",
    imgUrl:
      "https://nyulangone.org/images/doctors/m/mehta/1902115553/kunal-mehta-square.jpg",
  },

  {
    id: 5,
    name: "Ankita",
    comment:
      "I come from a small town where no big coaching is there. SaralUPSC was able to provide quality learning",
    imgUrl:
      "https://media-exp1.licdn.com/dms/image/C5603AQHImgCMBIoqDQ/profile-displayphoto-shrink_800_800/0/1642488794055?e=2147483647&v=beta&t=_bY9LQCptcscDJbMEeonXC-ChNokOKxe2Pkbc0qDMDw",
  },
];

const TestSeries = () => {
  return (
    <>
      <section>
        <div class="px-4 pt-5 my-5 text-center ">
          <p class="d-inline-flex mb-3 px-2 py-1 fw-semibold text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-4 px-4">
            Crack UPSC Prelims in First Attempt
          </p>
          <h1 class="display-4 fw-bold text-body-emphasis">
            Rank One UPSC Test Series for Smart Aspirants
          </h1>
          <div class="col-lg-8 mx-auto">
            <p class="lead mb-4">
              13 Lakh Aspirants appeared for UPSC 2023 but only 1% could qualify
              for Preliminary Exam. We have designed a Smart UPSC Test Series
              which gives you micro analysis of your Preparation and helps you
              in your weak areas.
            </p>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
              <button
                type="button"
                class="btn btn-danger btn-lg px-5 me-sm-3  rounded-pill "
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Enroll Now for Just 9,999 INR
              </button>
            </div>
          </div>
          <div class="overflow-hidden">
            <div class="container px-5">
              <img
                src="/assets/images/dashboard.png"
                class="img-fluid border rounded-3 shadow-lg mb-4"
                alt="Example image"
                width="1000"
                height="500"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container px-4 pt-5" id="hanging-icons">
          <div className="text-center">
            <h1 class="display-4 fw-bold text-body-emphasis">
              But, why Rank One UPSC Test Series?
            </h1>
          </div>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 py-5">
            <div class="col d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.75em"
                height="1.75em"
                fill="currentColor"
                class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Micro Analysis
                </h3>
                <p>Dive deep into your performance with detailed analysis.</p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.75em"
                height="1.75em"
                fill="currentColor"
                class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Prelims Focused
                </h3>
                <p>
                  Sharpen your knowledge and skills for the Preliminary
                  examination.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.75em"
                height="1.75em"
                fill="currentColor"
                class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  All the PYQs
                </h3>
                <p>
                  Access a comprehensive collection of Previous Year Questions.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.75em"
                height="1.75em"
                fill="currentColor"
                class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Reading Material
                </h3>
                <p>
                  Get access to valuable study materials to enhance your
                  preparation.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.75em"
                height="1.75em"
                fill="currentColor"
                class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">AI Enabled</h3>
                <p>
                  Utilize advanced AI tools for personalized learning and
                  tracking.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.75em"
                height="1.75em"
                fill="currentColor"
                class="bibi-check-square text-body-secondary flex-shrink-0 me-3"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
              </svg>
              <div>
                <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">
                  Preparation Score
                </h3>
                <p>
                  Monitor your preparation progress with our scoring system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container my-5">
          <div class="p-5 text-center  bg-danger rounded-5">
            <h1 class="text-white mt-5">
              Increase your Chances of Cracking Prelims by 86%
            </h1>
            <p class="lead text-white">
              Unlock a World of Opportunities and Maximize Your Odds of
              Succeeding in the UPSC Prelims Exam with Our Expertly Crafted Rank
              One UPSC Test Series. Leverage Our Comprehensive Study Materials,
              In-Depth Analysis, and AI-Driven Insights to Propel Your
              Preparation to New Heights. Join Our Community of Ambitious
              Aspirants and Secure Your Future for Just 9,999 INR."
            </p>

            <button
              class="d-inline-flex align-items-center btn btn-outline-light btn-lg px-4 rounded-pill mb-5 mt-3"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Enroll Now for Just 9,999 INR
            </button>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <div className="text-center py-5">
            <h1 class="display-4 fw-bold text-body-emphasis">
              And, Not Just Regular Test Series
            </h1>
          </div>
          <div class="row flex-lg-row-reverse align-items-center g-5 py-1">
            <div class="col-10 col-sm-8 col-lg-6">
              <img
                src="https://getbootstrap.com/docs/5.3/examples/heroes/bootstrap-themes.png"
                class="d-block mx-lg-auto img-fluid"
                alt="Bootstrap Themes"
                width="700"
                height="500"
                loading="lazy"
              />
            </div>
            <div class="col-lg-6">
              <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
                Preparation Score
              </h1>
              <p class="lead">
                Boost your performance and enhance your UPSC exam readiness with
                our cutting-edge tools and resources. Quickly design and
                customize responsive mobile-first sites with Bootstrap, the
                world's most popular front-end open-source toolkit. It features
                Sass variables, responsive grid systems, extensive prebuilt
                components, and powerful JavaScript plugins.
              </p>
              <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  class="btn btn-outline-danger btn-lg px-4 me-md-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container py-5">
          <div class="row flex-lg-row-reverse align-items-center g-5">
            <div class="col-lg-6">
              <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
                Dive into Micro Analysis
              </h1>
              <p class="lead">
                Experience a revolutionary approach to exam preparation with our
                Micro Analysis tools. Quickly design and customize responsive
                mobile-first sites with Bootstrap, the world's most popular
                front-end open-source toolkit. It features Sass variables,
                responsive grid systems, extensive prebuilt components, and
                powerful JavaScript plugins.
              </p>
              <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  class="btn btn-outline-danger btn-lg px-4 me-md-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Enroll Now
                </button>
              </div>
            </div>
            <div class="col-10 col-sm-8 col-lg-6">
              <img
                src="https://getbootstrap.com/docs/5.3/examples/heroes/bootstrap-themes.png"
                class="d-block mx-lg-auto img-fluid"
                alt="Bootstrap Themes"
                width="700"
                height="500"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 bg-danger py-5">
        <h1 className="fw-bold mb-5 text-center text-white">
          Well, Read about these Smart Aspirants <br />
          who cracked{" "}
          <span className="border-bottom">Prelims in First Attempt?</span>
        </h1>

        {/* Swiper for desktop mode */}
        <div className="d-none d-md-block">
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <img
                          src={TESTIMONIAL.imgUrl}
                          className="img-fluid rounded-pill me-3"
                          width="75px"
                          alt="Testimonial author"
                        />
                        <div>
                          <p>
                            <b>{TESTIMONIAL.name}</b>
                          </p>
                          <br />
                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        {/* Swiper for mobile mode */}
        <div className="d-md-none">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            freeMode={true}
            navigation={true}
            pagination={{ clickable: true }}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i} className="px-3">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <img
                          src={TESTIMONIAL.imgUrl}
                          className="img-fluid rounded-pill me-3"
                          width="75px"
                          alt="Testimonial author"
                        />
                        <div>
                          <p>
                            <b>{TESTIMONIAL.name}</b>
                          </p>
                          <br />
                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}

            <SwiperSlide className="px-3">
              <div className="card">
                <div className="card-body">
                  <p className="text-muted">
                    Our chatbot simplifies medication management. It sends
                    timely reminders for your meds and assists with prescription
                    refills. No more missed doses or rushed pharmacy trips.
                    Enjoy hassle-free healthcare support
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      src="https://img.etimg.com/authorthumb/msid-00479169717,width-358,height-353,imgsize-00479169717,resizemode-8.cms"
                      className="img-fluid rounded-pill me-3"
                      width="75px"
                      alt="Testimonial author"
                    />
                    <div>
                      <p>
                        <b>Hemant Sharma</b>
                        <br />
                        <span className="text-muted">
                          I have used Saral RANK #1 Programme
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        {/* Second Swiper for desktop mode */}
        <div className="d-none d-md-block mt-4">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            {testimonials.map((TESTIMONIAL, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="card">
                    <div className="card-body">
                      <p className="text-muted">{TESTIMONIAL.comment}</p>
                      <div className="d-flex align-items-center">
                        <img
                          src={TESTIMONIAL.imgUrl}
                          className="img-fluid rounded-pill me-3"
                          width="75px"
                          alt="Testimonial author"
                        />
                        <div>
                          <p>
                            <b>{TESTIMONIAL.name}</b>
                          </p>
                          <br />
                          <span className="text-muted">
                            {TESTIMONIAL.occupation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>

      {/*  <section className="pt-5">
        <div className="container mt-4">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8">
              <div className="section-title text-center">
                <h1 className="display-4 fw-bold text-body-emphasis">
                  Shall I Pay 9,999 INR for Rank One?
                </h1>
                <p className="text-lg">We have curated Test Series</p>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-md-row gap-4 py-md-5 align-items-center justify-content-center">
            <div class="list-group">
              <div className="text-center">
                <h2>SaralUPSC Rank One</h2>
              </div>
              <a
                class="list-group-item list-group-item-action d-flex gap-3 py-3"
                aria-current="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="green"
                  class="bi bi-check-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                </svg>
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 class="mb-0">List group item heading</h6>
                    <p class="mb-0 opacity-75">
                      Some placeholder content in a paragraph.
                    </p>
                  </div>
                  <small class="opacity-50 text-nowrap">now</small>
                </div>
              </a>
              <a
                class="list-group-item list-group-item-action d-flex gap-3 py-3"
                aria-current="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="green"
                  class="bi bi-check-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                </svg>
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 class="mb-0">Another title here</h6>
                    <p class="mb-0 opacity-75">
                      Some placeholder content in a paragraph that goes a little
                      longer so it wraps to a new line.
                    </p>
                  </div>
                  <small class="opacity-50 text-nowrap">3d</small>
                </div>
              </a>
              <a
                class="list-group-item list-group-item-action d-flex gap-3 py-3"
                aria-current="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="green"
                  class="bi bi-check-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                </svg>
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 class="mb-0">Third heading</h6>
                    <p class="mb-0 opacity-75">
                      Some placeholder content in a paragraph.
                    </p>
                  </div>
                  <small class="opacity-50 text-nowrap">1w</small>
                </div>
              </a>
            </div>

            <div class="list-group">
              <div className="text-center">
                <h2>Funacademy and Bajiram</h2>
              </div>
              <a
                class="list-group-item list-group-item-action d-flex gap-3 py-3"
                aria-current="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="green"
                  class="bi bi-check-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                </svg>
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 class="mb-0">List group item heading</h6>
                    <p class="mb-0 opacity-75">
                      Some placeholder content in a paragraph.
                    </p>
                  </div>
                  <small class="opacity-50 text-nowrap">now</small>
                </div>
              </a>
              <a
                class="list-group-item list-group-item-action d-flex gap-3 py-3"
                aria-current="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="green"
                  class="bi bi-check-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                </svg>
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 class="mb-0">Another title here</h6>
                    <p class="mb-0 opacity-75">
                      Some placeholder content in a paragraph that goes a little
                      longer so it wraps to a new line.
                    </p>
                  </div>
                  <small class="opacity-50 text-nowrap">3d</small>
                </div>
              </a>
              <a
                class="list-group-item list-group-item-action d-flex gap-3 py-3"
                aria-current="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="red"
                  class="bi bi-x-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                </svg>
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 class="mb-0">Third heading</h6>
                    <p class="mb-0 opacity-75">
                      Some placeholder content in a paragraph.
                    </p>
                  </div>
                  <small class="opacity-50 text-nowrap">1w</small>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="container pb-4">
          <div className="row align-items-center g-lg-5 py-5">
            <div className="col-lg-7 text-center text-lg-start">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis mb-3">
                Smart Aspirants have already joined Rank One Series
              </h1>
              <p className="col-lg-10 fs-4">
                You can{" "}
                <span className="text-danger border-bottom border-danger fw-bold">
                  FAIL, WASTE A YEAR
                </span>{" "}
                and then come back to us
              </p>{" "}
              <h4>OR</h4>
              <p className="col-lg-10 fs-4">
                be a SMART Aspirant and clear UPSC Prelims in{" "}
                <span className="text-danger border-bottom border-danger fw-bold">
                  FIRST ATTEMPT
                </span>
              </p>
              <button
                type="button"
                class="btn btn-danger btn-lg px-4 me-md-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Enroll Now for Just 9,999 INR
              </button>
            </div>
            <div className="col-md-10  col-lg-5">
              <h4 className="mb-4">Frequently Asked Questions</h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style={{ fontSize: "20px" }}
                    >
                      Are the test series questions updated to align with the
                      latest UPSC exam pattern and syllabus?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Yes, our test series questions are regularly updated to
                      reflect the most recent UPSC exam pattern and syllabus
                      changes. We ensure that you practice with the most
                      relevant and up-to-date material.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      style={{ fontSize: "20px" }}
                    >
                      Do I receive feedback and performance analysis after
                      taking the tests?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Yes, after each test, you'll receive a detailed
                      performance analysis that highlights your strengths and
                      areas that need improvement. This feedback is invaluable
                      in refining your preparation strategy and increasing your
                      chances of success in the UPSC exam.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      style={{ fontSize: "20px" }}
                    >
                      How many times can I attempt a test?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      You can attempt each test a maximum of three times. This
                      flexibility allows you to take the test multiple times,
                      giving you the opportunity to assess your progress and
                      make improvements. It's an important feature to help you
                      refine your preparation and enhance your performance in
                      the UPSC exam.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                      style={{ fontSize: "20px" }}
                    >
                      Is there any refund policy?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Yes, we have a 3-day, no-questions-asked refund policy.
                      If, for any reason, you are not satisfied with our Rank
                      One Series within the first three days of your purchase,
                      you can request a refund, and we'll process it without any
                      hassle.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                      style={{ fontSize: "20px" }}
                    >
                      What are the benefits of Preparation Tracks?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Preparation Tracks offer several benefits, including:
                      <ul>
                        <li>
                          Structured study plans to keep you on track with your
                          UPSC exam preparation.
                        </li>
                        <li>
                          Customized content and resources tailored to your
                          specific needs and goals.
                        </li>
                        <li>
                          Regular assessments and feedback to gauge your
                          progress and make improvements.
                        </li>
                        <li>
                          Access to experienced mentors and experts who can
                          guide you in your journey.
                        </li>
                        <li>
                          Flexible learning options to fit your schedule and
                          preferences.
                        </li>
                      </ul>
                      These benefits make Preparation Tracks a valuable resource
                      for UPSC aspirants, helping you stay focused, motivated,
                      and well-prepared for your exams.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container my-5">
          <div class="p-5 text-center  bg-danger rounded-5">
            <h1 class="text-white mt-5">
              Increase your Chances of Cracking Prelims by 86%
            </h1>

            <p class="lead text-white">
              Unlock a World of Opportunities and Maximize Your Odds of
              Succeeding in the UPSC Prelims Exam with Our Expertly Crafted Rank
              One UPSC Test Series. Leverage Our Comprehensive Study Materials,
              In-Depth Analysis, and AI-Driven Insights to Propel Your
              Preparation to New Heights. Join Our Community of Ambitious
              Aspirants and Secure Your Future for Just 9,999 INR."
            </p>

            <button
              class="d-inline-flex align-items-center btn btn-outline-light btn-lg px-4 rounded-pill mb-5 mt-3"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Enroll Now for Just 9,999 INR
            </button>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5>
                Please fill the following form to join Smart RankOne Test Series{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <TestSeriesForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestSeries;
