import React from "react";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Radar,
  Legend,
} from "recharts";

const FocusChart = ({ data }) => {
  const maxValue = Math.max(...data.map((item) => item.value));

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <RadarChart outerRadius={80} data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis
            angle={270}
            domain={[0, maxValue + 1]}
            tick={false} // Hide the ticks
            tickLine={false} // Hide the tick lines
          />
          <Radar
            name="Focus"
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.8}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FocusChart;
