import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import ButtonStatusSideBar from "./ButtonStatusSideBar";
import CurrentQuestion from "./CurrentQuestion";
import { Helmet } from "react-helmet";
import { Button, Modal } from "react-bootstrap";

const GreyCard = ({ testName, noOfQuestions, setStart }) => {
  return (
    <>
      <div className="card" style={{ backgroundColor: "#F1F5F8" }}>
        <div className="card-body">
          <h2 className="card-title">{testName}</h2>
          <p className="card-subtitle mb-2 text-muted">
            Read the instructions carefully
          </p>

          <div className="card bg-white">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <p className="card-subtitle mb-2 text-muted">
                    Total Questions
                  </p>
                  <h5 className="card-title">{noOfQuestions}</h5>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="card-subtitle mb-2 text-muted">Time Allowed</p>
                  <h5 className="card-title">{noOfQuestions * 2}</h5>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="card-subtitle mb-2 text-muted">
                    Correct Question Marks
                  </p>
                  <h5 className="card-title">2</h5>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p className="card-subtitle mb-2 text-muted">
                    Incorrect Question Marks
                  </p>
                  <h5 className="card-title">- 0.66</h5>
                </div>
              </div>
            </div>
          </div>

          <button
            className="btn  btn-danger mt-3 w-100"
            onClick={() => setStart(true)}
          >
            Start your Test{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="btn btn-outline-danger mt-3 w-100">
        Share Test Link
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          className="bi bi-link-45deg"
          viewBox="0 0 16 16"
        >
          <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
          <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
        </svg>
      </div>
    </>
  );
};

const TestAttemptLayout = ({ test, apiUrl }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [answerMap, setAnswerMap] = useState(
    new Array(test.questions.length).fill(-1)
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(test.questions.length * 2 * 60);
  const [askSubmit, setAskSubmit] = useState(false);

  const [intervalId, setIntervalId] = useState(null);

  const navigate = useNavigate();

  const calculateScore = () => {
    var score = 0;
    var correct = 0;
    var wrong = 0;
    return new Promise((resolve, reject) => {
      for (var i = 0; i < answerMap.length; i++) {
        if (answerMap[i] === -1) {
          continue;
        } else if (answerMap[i] === test.questions[i].correct) {
          score = score + 2;
          correct += 1;
        } else {
          score = score - 0.66;
          wrong += 1;
        }
      }

      if (i === answerMap.length) {
        resolve([score, wrong, correct]);
      }
    });
  };

  const { currentUser } = useAuth();

  useEffect(() => {
    const id = setInterval(() => {
      if (timeLeft === 1) {
        submitTest();
      } else {
        setTimeLeft((prevTime) => prevTime - 1);
      }
    }, 1000);
    setIntervalId(id);

    // Add a cleanup function to clear the interval when the component unmounts or when the test is submitted
    return () => {
      clearInterval(id);
    };
  }, [timeLeft]);

  const submitTest = async () => {
    setIsSubmitting(true);
    clearInterval(intervalId);
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        const payloadHeader = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        calculateScore().then((analytics) => {
          axios
            .post(
              `${apiUrl}submit-test/${test._id}`,
              {
                answers: answerMap,
                score: analytics[0],
                wrong: analytics[1],
                correct: analytics[2],
                accuracy: (analytics[2] / (analytics[1] + analytics[2])) * 100,
                time: test.questions.length * 2 * 60 - timeLeft,
              },
              payloadHeader
            )
            .then((response) => response.data)
            .then((progress) => {
              setIsSubmitting(false);
              navigate("/report/" + progress._id);
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.status === 403 &&
                error.response.data.error === "Trial plan limit exceeded"
              ) {
                calculateScore()
                  .then((analytics) => {
                    localStorage.setItem(
                      "report",
                      JSON.stringify({
                        answers: answerMap,
                        score: analytics[0],
                        wrong: analytics[1],
                        time: test.questions.length * 2 * 60 - timeLeft,
                        correct: analytics[2],
                        accuracy:
                          (analytics[2] / (analytics[1] + analytics[2])) * 100,
                        testId: test._id,
                      })
                    );

                    localStorage.setItem(
                      "redirectUrl",
                      window.location.pathname
                    );
                    localStorage.setItem("redirectType", "prelims");
                  })
                  .then(() => {
                    //Show Login Portal
                    setShow(true);
                    // navigate("/upgrade");
                  });
              } else {
                alert(
                  "An error occurred while submitting your test. Please try again later."
                );
              }

              setIsSubmitting(false);
            });
        });
      });
    } else {
      calculateScore()
        .then((analytics) => {
          localStorage.setItem(
            "report",
            JSON.stringify({
              answers: answerMap,
              score: analytics[0],
              wrong: analytics[1],
              time: test.questions.length * 2 * 60 - timeLeft,
              correct: analytics[2],
              accuracy: (analytics[2] / (analytics[1] + analytics[2])) * 100,
              testId: test._id,
            })
          );

          localStorage.setItem("redirectUrl", window.location.pathname);
          localStorage.setItem("redirectType", "prelims");
        })
        .then(() => {
          //Show Login Portal
          navigate("/login");
        });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{test.name}</title>
        <meta name="description" content={test.seodesc} />
        <meta property="og:description" content={test.seodesc} />
        <meta property="og:title" content={test.name} />
        <meta property="og:image" content={test.poster} />
        <meta
          property="og:url"
          content={"https://saralupsc.com/test/" + test._id}
        />
      </Helmet>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="py-5">
          <center>
            <div className="py-3">
              <img
                src="/assets/icons/sademoji.gif"
                className="img-fluid"
                style={{ width: "200px" }}
              />
              <h1>Dear RANKONE Aspirant</h1>
              <p className="w-50">
                You have reached the limit of Free Trial under RANKONE Programme
              </p>
              <div className="mt-3">
                <button
                  className="btn btn-outline-danger me-2"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <a href="/rankone" className="btn btn-danger">
                  Buy RANKONE Membership now
                </a>
              </div>
            </div>
          </center>
        </Modal.Body>
      </Modal>

      <section className="section mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-3  d-none d-lg-block d-md-block d-sm-none d-xs-none">
              <h4>Questions</h4>

              <div className="list-group">
                {test.questions.map((question, index) => {
                  return (
                    <button
                      type="button"
                      className="list-group-item list-group-item-action"
                      aria-current="true"
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentQuestion(index);
                      }}
                    >
                      <b>Q {index + 1}. </b>
                      <div
                        dangerouslySetInnerHTML={{ __html: question.statement }}
                      />
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="col-sm-6">
              <div>
                <button
                  className="btn btn-outline-primary btn-sm"
                  id="clear"
                  onClick={() => {
                    if (currentQuestion > 0) {
                      setCurrentQuestion(currentQuestion - 1);
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  className="btn btn-outline-danger mx-2 btn-sm"
                  id="clear"
                  onClick={() => {
                    let newAnswerMap = [...answerMap];
                    newAnswerMap[currentQuestion] = -1;
                    setAnswerMap(newAnswerMap);
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-success ml-2 btn-sm"
                  id="next"
                  onClick={() => {
                    if (currentQuestion < test.questions.length - 1) {
                      setCurrentQuestion(currentQuestion + 1);
                    }
                  }}
                >
                  Next
                </button>
              </div>
              <br />
              <div className="card border-0 sticky-top">
                <CurrentQuestion
                  question={test.questions[currentQuestion]}
                  index={currentQuestion}
                  answerMap={answerMap}
                  setAnswerMap={setAnswerMap}
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div id="status" className="sticky-top">
                <center>
                  <div>
                    <h4>
                      {" "}
                      <b>
                        Time Remaining: {Math.floor((timeLeft / 3600) % 60)}:
                        {Math.floor((timeLeft / 60) % 60)}:
                        {Math.floor(timeLeft % 60)}
                      </b>
                    </h4>
                  </div>

                  <button
                    className="btn btn-success"
                    data-mdb-toggle="modal"
                    data-mdb-target="#exampleModal"
                    id="msub"
                    onClick={() => {
                      submitTest();
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Submitting...</span>
                      </div>
                    ) : (
                      "Submit Test"
                    )}
                  </button>

                  <br />
                  <ButtonStatusSideBar
                    answerMap={answerMap}
                    setCurrentQuestion={setCurrentQuestion}
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const TestAttemptPage = () => {
  const [test, setTest] = useState(null);
  const [start, setStart] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const params = useParams();
  const testId = params.testId;

  useEffect(() => {
    axios
      .get(`${apiUrl}tests/${testId}`)
      .then((data) => data.data)
      .then((test) => {
        setTest(test);
      })
      .catch((err) => console.log(err));
  }, []);

  if (!test) {
    return <></>;
  }

  if (!start) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{test.name}</title>
          <meta name="description" content={test.seodesc} />
          <meta property="og:description" content={test.seodesc} />
          <meta property="og:title" content={test.name} />
          <meta property="og:image" content={test.poster} />
          <meta
            property="og:url"
            content={"https://saralupsc.com/test/" + test._id}
          />
        </Helmet>

        <div className="section">
          <div className="container my-5">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6">
                <GreyCard
                  testName={test.name}
                  noOfQuestions={test.questions.length}
                  setStart={setStart}
                ></GreyCard>
              </div>
              <div className="col-sm-3"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (start) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{test.name}</title>
          <meta name="description" content={test.seodesc} />
          <meta property="og:description" content={test.seodesc} />
          <meta property="og:title" content={test.name} />
          <meta property="og:image" content={test.poster} />
          <meta
            property="og:url"
            content={"https://saralupsc.com/test/" + test._id}
          />
        </Helmet>

        <TestAttemptLayout test={test} apiUrl={apiUrl} />
      </>
    );
  }
};

export default TestAttemptPage;
