import React from "react";

const NextTopicTile = ({ topic }) => {
  if (!topic) {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <p class="placeholder-glow">
          <span class="placeholder col-5"></span>
        </p>

        <a
          class="btn btn-danger disabled placeholder col-4"
          aria-disabled="true"
        >
          {" "}
          Attempt Now
        </a>
      </div>
    );
  }
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <div className="d-flex align-items-center">
          <img src="/assets/icons/polity.svg"></img>
          <div>
            <div className="d-flex flex-column">
              <h4>{topic.name}</h4>
              <p>
                <small className="d-inline-flex mb-3 px-2 py-1 fw-semibold text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-2">
                  Important
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <a className="btn btn-danger" href={"/topic-attempt/" + topic._id}>
        Attempt Now
      </a>
    </div>
  );
};

const NextTopics = ({ topics }) => {
  if (!topics) {
    return (
      <div className="card shadow-sm h-100">
        <div className="card-body">
          <div className="d-flex justify-content-between mb-3">
            <h4>
              <u>Browse Next Topics</u>
            </h4>
            <a
              className="icon-link icon-link-hover"
              style={{ "--bs-link-hover-color-rgb": "25, 135, 84" }}
              href="#"
            >
              See All
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </a>
          </div>
          <NextTopicTile></NextTopicTile>
        </div>
      </div>
    );
  }
  return (
    <div className="card shadow-sm h-100">
      <div className="card-body">
        <div className="d-flex justify-content-between mb-3">
          <h4>
            <u>Browse Next Topics</u>
          </h4>
          <a
            className="icon-link icon-link-hover"
            style={{ "--bs-link-hover-color-rgb": "25, 135, 84" }}
            href="#"
          >
            See All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </a>
        </div>
        {topics.map((topic) => (
          <NextTopicTile topic={topic}></NextTopicTile>
        ))}
      </div>
    </div>
  );
};

export default NextTopics;
