import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import MentorCard from "./MentorCard";

const MentorGrid = () => {
  const [mentors, setMentors] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMoreMentors, setHasMoreMentors] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false); // Loading more state
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [isRecommended, setIsRecommended] = useState(false);
  const [prelims, setPrelims] = useState(false);
  const subjects = [
    "History",
    "Geography",
    "Economics",
    "Polity",
    "International Relations",
    "CSAT",
    "GS1",
    "GS2",
    "GS3",
    "GS4",
    "Optionals",
    "Polity",
    "Environment",
    "Science and Technology",
    "Indian Society",
    "Internal Security",
    "Ethics",
    "Current Affairs",
    "Essay Writing",
  ];

  const handleRecommendedChange = () => {
    setPage(1);
    setIsRecommended(!isRecommended);
  };

  const handleExperienceChange = (value) => {
    setPage(1);
    setSelectedExperience(value);
  };

  const handlePrelims = (value) => {
    setPage(1);
    setPrelims(true);
  };

  const handleSubjectChange = (event) => {
    const { value, checked } = event.target;
    setPage(1);
    if (checked) {
      setSelectedSubjects([...selectedSubjects, value]);
    } else {
      setSelectedSubjects(
        selectedSubjects.filter((subject) => subject !== value)
      );
    }
  };
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchMentors(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedExperience, selectedSubjects, isRecommended]);

  const fetchMentors = async (pageNumber) => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${apiUrl}mentors`, {
        params: {
          page: pageNumber,
          experience: selectedExperience ? selectedExperience : undefined,
          recommended: isRecommended ? isRecommended : undefined,
          subjects: selectedSubjects.length > 0 ? selectedSubjects : undefined,
          prelimsCleared: prelims ? 2 : undefined,
        },
      });

      const newMentors = response.data.mentors;

      if (pageNumber === 1) {
        setMentors(newMentors);
      } else {
        setMentors((prevMentors) => [...prevMentors, ...newMentors]);
      }

      if (newMentors.length === 0) {
        setHasMoreMentors(false);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching mentors:", error);
      setIsLoading(false);
    }
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Find a Mentor | SaralUPSC</title>
        <meta name="description" content="Find a mentor for upsc preparation" />
        <meta
          property="og:description"
          content="Find a mentor for upsc preparation"
        />
        <meta
          property="og:title"
          content="Find a Mentor for UPSC Preparation"
        />
        <meta
          property="og:image"
          content="https://saralupsc.com/assets/images/rankonetestsbanner.png"
        />
        <meta
          property="og:url"
          content={"https://saralupsc.com/upsc-mentors/"}
        />
      </Helmet>

      <section className="py-5">
        <div className="container">
          <h1 className="text-center text-danger display-5 fw-bold">
            Find a Mentor for yourself
          </h1>
          <h4 className="text-center mb-3">Ab UPSC Hoga Aur Bhi Saral</h4>

          <center>
            <b>Filter Mentors by</b>
            <div className="py-3">
              <div class="btn-group">
                <button
                  type="button"
                  className={`btn ${
                    selectedExperience ? "btn-danger" : "btn-outline-danger"
                  } dropdown-toggle me-2`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedExperience
                    ? `Experience more than ${selectedExperience} years`
                    : "Experience more than"}
                </button>
                <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    <input
                      className="form-check-input me-1"
                      type="radio"
                      name="experienceRadio"
                      value=""
                      id="experienceAny"
                      onClick={() => handleExperienceChange("")}
                    />
                    <label className="form-check-label" htmlFor="experienceAny">
                      Any
                    </label>
                  </li>
                  <li className="dropdown-item">
                    <input
                      className="form-check-input me-1"
                      type="radio"
                      name="experienceRadio"
                      value="2"
                      id="experience2Years"
                      onClick={() => handleExperienceChange("2")}
                      checked={selectedExperience === "2" ? true : false}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="experience2Years"
                    >
                      2 Years
                    </label>
                  </li>
                  <li className="dropdown-item">
                    <input
                      className="form-check-input me-1"
                      type="radio"
                      name="experienceRadio"
                      value="4"
                      id="experience4Years"
                      onClick={() => handleExperienceChange("4")}
                      checked={selectedExperience === "4" ? true : false}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="experience4Years"
                    >
                      4 Years
                    </label>
                  </li>
                  <li className="dropdown-item">
                    <input
                      className="form-check-input me-1"
                      type="radio"
                      name="experienceRadio"
                      value="6"
                      id="experience6Years"
                      onClick={() => handleExperienceChange("6")}
                      checked={selectedExperience === "6" ? true : false}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="experience6Years"
                    >
                      6 Years
                    </label>
                  </li>
                  {/* Add other experience options similarly */}
                </ul>
              </div>

              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-outline-danger dropdown-toggle me-2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Subject Experts in
                </button>

                <ul className="dropdown-menu">
                  {subjects.map((subject) => (
                    <li className="dropdown-item">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        value={subject}
                        id={"subject" + subject}
                        onChange={handleSubjectChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"subject" + subject}
                      >
                        {subject}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              <input
                type="checkbox"
                className="btn-check me-3"
                id="recommended"
                autocomplete="off"
                checked={isRecommended}
                onChange={handleRecommendedChange}
              />
              <label class="btn btn-outline-danger me-2" for="recommended">
                Recommended by SaralUPSC
              </label>

              <input
                type="checkbox"
                className="btn-check me-3"
                id="prelims"
                autocomplete="off"
                checked={prelims}
                onChange={handlePrelims}
              />
              <label class="btn btn-outline-danger me-2" for="prelims">
                Cleared more than 2 Prelims
              </label>
            </div>
          </center>

          <center>
            <div className="mb-4">
              {selectedExperience ? (
                <button
                  key={selectedExperience}
                  type="button"
                  className="btn btn-primary me-2 mb-2"
                  style={{
                    "--bs-btn-padding-y": ".25rem",
                    "--bs-btn-padding-x": ".5rem",
                    "--bs-btn-font-size": ".75rem",
                  }}
                >
                  {selectedExperience + " years exp"}
                  {/*    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x-square-fill ms-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                  </svg> */}
                </button>
              ) : (
                <></>
              )}
              {selectedSubjects.map((subject) => (
                <button
                  key={subject}
                  type="button"
                  className="btn btn-primary me-2 mb-2"
                  style={{
                    "--bs-btn-padding-y": ".25rem",
                    "--bs-btn-padding-x": ".5rem",
                    "--bs-btn-font-size": ".75rem",
                  }}
                >
                  {subject}
                  {/*      <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x-square-fill ms-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                  </svg> */}
                </button>
              ))}
            </div>
          </center>

          <Mentors page={page} isLoading={isLoading} mentors={mentors} />

          <center>
            {hasMoreMentors ? (
              <button
                className="btn btn-outline-primary mt-4"
                onClick={handleLoadMore}
                disabled={isLoadingMore} // Disable the button when loading
              >
                {isLoadingMore ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    {"    "}
                    Load More
                  </>
                )}
              </button>
            ) : (
              <p className="mt-4">We have already shown you best mentors</p>
            )}
          </center>
        </div>
      </section>
    </>
  );
};

const Mentors = ({ isLoading, mentors, page }) => {
  if (isLoading && page === 1) {
    return (
      <>
        <div class="row row-cols-1 row-cols-md-4 g-4">
          <MentorCard />
          <MentorCard />
          <MentorCard />
          <MentorCard />
        </div>
      </>
    );
  }

  if (!isLoading && mentors.length === 0) {
    return (
      <>
        <center>
          <b>No Mentors Available</b>
        </center>
      </>
    );
  }

  return (
    <>
      <div class="row row-cols-1 row-cols-md-4 g-4">
        {mentors.map((mentor) => {
          return <MentorCard mentor={mentor} />;
        })}
      </div>
    </>
  );
};

export default MentorGrid;
