import React from "react";

const MentorRegistrationLanding = () => {
  return (
    <>
      <section>
        <div class="d-flex align-items-center justify-content-center vh-100 bg-danger">
          <div className="col-8 mx-auto">
            {" "}
            <h1 class="display-1 fw-bold text-white">
              Become a UPSC Mentor and Guide UPSC Aspirants
            </h1>
            <h4 className="text-white">
              Every UPSC Aspirants needs a <u>Sandeep Bhaiya</u> in their life
            </h4>
            <a
              href="/register-mentor"
              class="btn btn-light btn-lg mt-3"
              type="button"
            >
              Become a UPSC Mentor now
            </a>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-4">
        <div className="container">
          <div class="p-5 mb-4 bg-body-tertiary rounded-3">
            <div class="container-fluid py-5">
              <h1 class="display-5 fw-bold">Earn while you Learn</h1>
              <ul class="list-unstyled">
                <li>
                  <strong>Embark on a Fulfilling Journey:</strong> Join
                  SaralUPSC as a mentor and begin a rewarding experience.
                  Connect with a diverse audience of aspirants, broadening your
                  horizons and making a lasting impact.
                </li>
                <li>
                  <strong>Expand Your Influence:</strong> As a UPSC mentor on
                  our platform, not only will you increase your earnings, but
                  you'll also extend your sphere of influence. Guide passionate
                  learners, shape future candidates, and make a meaningful
                  difference in their lives.
                </li>
                <li>
                  <strong>Empower Aspiring Candidates:</strong> Seize the
                  opportunity to share your expertise and help shape the future
                  of UPSC aspirants. Register now to unlock your potential,
                  reaching and assisting a diverse community of students in
                  their pursuit of success.
                </li>
              </ul>
              <a
                href="/register-mentor"
                class="btn btn-danger btn-lg"
                type="button"
              >
                Become a UPSC Mentor now
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-4">
        <div className="container">
          <div class="row align-items-md-stretch">
            <div class="col-md-6">
              <div class="h-100 p-5 text-bg-dark rounded-3">
                <h2>Democraticisng Education</h2>
                <p>
                  Embrace the ethos of accessibility and inclusivity in
                  education. Our platform strives to democratize learning by
                  breaking barriers and providing equal opportunities to all. We
                  believe in empowering individuals from diverse backgrounds,
                  ensuring that quality education is accessible to everyone.
                  Join us in revolutionizing education by fostering an
                  environment where knowledge knows no boundaries.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="h-100 p-5 bg-body-tertiary border rounded-3">
                <h2>Making UPSC Saral</h2>
                <p>
                  Simplifying the complexity of UPSC preparation is our mission.
                  We aim to streamline the learning process, making it
                  straightforward and comprehensible for aspirants. Our approach
                  focuses on providing easy-to-understand resources,
                  personalized guidance, and effective strategies. Let's
                  navigate the journey to cracking UPSC together, making it
                  simpler and achievable for every aspirant.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MentorRegistrationLanding;
