import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../firebase";

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [otpError, setOTPError] = useState(false);
  const navigate = useNavigate();
  const otpInputsRef = useRef([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    }
  }, [currentUser]);

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
    setError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (phoneNumber.length !== 10) {
      setError(true);
    } else {
      sendOTP();
    }
  };

  const sendOTP = () => {
    // Perform logic to send OTP
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+91" + phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).

        window.confirmationResult = confirmationResult;
        setOTP(["", "", "", "", "", ""]);
        setShowOTPForm(true);
        // ...
      })
      .catch((error) => {});
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sendotp",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  }, []);

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    const enteredOTP = otp.join("");

    window.confirmationResult
      .confirm(enteredOTP)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        auth.currentUser = user;

        // Check if there is a redirect URL in localStorage
        const redirectUrl = localStorage.getItem("redirectUrl");
        const redirectType = localStorage.getItem("redirectType");

        if (redirectUrl) {
          // Clear the redirect URL from localStorage
          localStorage.removeItem("redirectUrl");

          if (redirectType === "mains") {
            navigate(redirectUrl);
          } else {
            // If there is no redirect URL, navigate to the default URL (e.g., dashboard)
            navigate("/dashboard");
          }
        } else {
          // If there is no redirect URL, navigate to the default URL (e.g., dashboard)
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        setOTPError(error.toString());
      });
  };

  const handleOTPChange = (index, value, event) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (
      event.type === "keyup" &&
      value.length === 1 &&
      index < otp.length - 1
    ) {
      otpInputsRef.current[index + 1].focus();
    }
  };

  const handleOTPInputFocus = (index) => {
    otpInputsRef.current[index].select();
  };

  useEffect(() => {
    if (showOTPForm) {
      otpInputsRef.current[0].focus();
    }
  }, [showOTPForm]);

  const handleEditNumber = () => {
    setShowOTPForm(false);
    setPhoneNumber(phoneNumber);
  };

  return (
    <section className="py-5">
      {/* 
style={{
        background:
          "radial-gradient(circle at 10.6% 22.1%, rgb(206, 18, 18) 0%, rgb(122, 21, 21) 100.7%)",
      }} */}
      <div className="container">
        <div className="row">
          <div className="col-sm-3"></div>
          <div className="col-sm-6 text-center">
            {/*    <div
              class="modal modal-sheet position-static d-block p-4 py-md-5"
              tabindex="-1"
              role="dialog"
              id="modalSignin"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content rounded-4 shadow">
                  <div class="modal-header p-5 pb-4 border-bottom-0">
                    <h1 class="fw-bold mb-0 fs-2">Sign up/Log in for free</h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div class="modal-body p-5 pt-0">
                    <form class="">
                      <div class="form-floating mb-3">
                        <input
                          type="email"
                          class="form-control rounded-3"
                          id="floatingInput"
                          placeholder="name@example.com"
                        />
                        <label for="floatingInput">Email address</label>
                      </div>
                      <div class="form-floating mb-3">
                        <input
                          type="password"
                          class="form-control rounded-3"
                          id="floatingPassword"
                          placeholder="Password"
                        />
                        <label for="floatingPassword">Password</label>
                      </div>
                      <button
                        class="w-100 mb-2 btn btn-lg rounded-3 btn-danger"
                        type="submit"
                      >
                        Sign up
                      </button>
                      <small class="text-body-secondary">
                        By clicking Sign up, you agree to the terms of use.
                      </small>
                    </form>
                  </div>
                </div>
              </div>
            </div> */}

            <h2>Ab UPSC Hua Aur Bhi Saral</h2>
            <div className="d-flex justify-content-between align-items-center">
              <hr className="w-50" />
              <h4 className="text-center mx-3 lead">Login</h4>
              <hr className="w-50" />
            </div>

            {!showOTPForm ? (
              <form onSubmit={handleSubmit}>
                <div className="row g-2 mt-4">
                  {localStorage.getItem("redirectType") ? (
                    localStorage.getItem("redirectType") === "prelims" ? (
                      <h6>You have an unsaved test</h6>
                    ) : (
                      <h6>You have an unsaved Mains Answer</h6>
                    )
                  ) : (
                    <></>
                  )}

                  <div className="col-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="IN"
                      value="IN"
                      disabled
                    />
                  </div>
                  <div className="col">
                    <div className="position-relative">
                      <input
                        type="text"
                        className={`form-control ${error ? "is-invalid" : ""}`}
                        placeholder="Mobile Number"
                        value={phoneNumber}
                        onChange={handleChange}
                      />
                      {error && (
                        <div className="invalid-feedback">
                          <i className="bi bi-exclamation-triangle-fill"></i>
                          Please enter a valid 10-digit number.
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn mt-4 w-100"
                  style={{ backgroundColor: "#DB0001", color: "white" }}
                  id="sendotp"
                >
                  Continue
                </button>
              </form>
            ) : (
              <form onSubmit={handleOTPSubmit}>
                <div className="mt-4">
                  <p>
                    We have sent an OTP to <strong>{phoneNumber}</strong>{" "}
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={handleEditNumber}
                      style={{ color: "#DB0001" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#DB0001"
                        className="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>{" "}
                      Edit
                    </button>
                  </p>
                </div>

                <div className="row g-2 mt-4">
                  {otp.map((digit, index) => (
                    <div className="col" key={index}>
                      <input
                        ref={(el) => (otpInputsRef.current[index] = el)}
                        type="text"
                        className={`form-control ${
                          otpError ? "is-invalid" : ""
                        }`}
                        maxLength={1}
                        value={digit}
                        onChange={(e) =>
                          handleOTPChange(index, e.target.value, e)
                        }
                        onKeyUp={(e) =>
                          handleOTPChange(index, e.target.value, e)
                        }
                        onFocus={() => handleOTPInputFocus(index)}
                      />
                    </div>
                  ))}
                </div>
                {otpError && (
                  <div className="mt-2 text-danger">
                    Incorrect OTP. Please try again.
                  </div>
                )}

                <button
                  type="submit"
                  className="btn mt-4 w-100"
                  style={{ backgroundColor: "#DB0001", color: "white" }}
                >
                  Verify OTP
                </button>
              </form>
            )}
          </div>
          <div className="col-sm-3"></div>
        </div>
      </div>
    </section>
  );
};

export default Login;
