import React, { useEffect, useState } from "react";

const SubjectStatus = ({ subject, percentage }) => {
  const [color, setColor] = useState();

  useEffect(() => {
    const setInitialColor = () => {
      if (percentage === 0) {
        setColor("#9E9E9E");
      } else if (percentage > 0 && percentage <= 0.3) {
        setColor("#F2994A");
      } else if (percentage > 0.3 && percentage <= 0.7) {
        setColor("#EB5757");
      } else if (percentage > 0.7 && percentage <= 1) {
        setColor("#42CB79");
      }
    };

    setInitialColor();
  }, [percentage]);

  if (percentage !== 0 && !percentage) {
    return (
      <div className="d-inline-flex p-2 justify-content-between align-items-center">
        <img
          src={"/assets/icons/" + subject.toLocaleLowerCase() + ".svg"}
          alt="Polity"
        ></img>
        <div>
          <p className="placeholder-glow">
            <span className="placeholder col-12"></span>
          </p>
          <h5>{subject}</h5>
        </div>
      </div>
    );
  }

  return (
    <div className="d-inline-flex p-2 justify-content-between align-items-center">
      <img src={"/assets/icons/" + subject + ".svg"} alt="Polity"></img>
      <div>
        <h4 style={{ color: color }}>{(percentage * 100).toFixed(2)}%</h4>

        <a
          className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
          href={"/track/" + subject}
        >
          <h5>{subject.toUpperCase()}</h5>
        </a>
      </div>
    </div>
  );
};

export default SubjectStatus;
