import React from "react";

const SingleQuestion = ({ question }) => {
  if (!question) {
    return (
      <div className="card bg-light mb-3">
        <div className="card-body">
          <h3 className="mb-2 placeholder-glow">
            Q{" "}
            <span
              className="placeholder col-12 placeholder-lg"
              style={{ width: "400px" }}
            ></span>
          </h3>

          <p className="placeholder-glow">
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
          </p>

          <a className="btn btn-warning disabled placeholder col-4"></a>
        </div>
      </div>
    );
  }
  return (
    <div className="card bg-light mb-3">
      <div className="card-body">
        {question.answer === -1 ? (
          <p>You didn't attempt this question</p>
        ) : question.correct === question.answer ? (
          <p className="text-success">You answered this question correctly.</p>
        ) : (
          <p className="text-danger">
            Uh Oh! You answered this question incorrectly
          </p>
        )}
        <h5>Q{question.questionNo}. </h5>
        <h5 dangerouslySetInnerHTML={{ __html: question.statement }} />
        {question.correct === 0 ? (
          <div>
            <button
              type="button"
              className="btn btn-outline-success mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              {question.options[0]}
            </button>
          </div>
        ) : question.answer === 0 && question.correct !== 0 ? (
          <div>
            <button
              type="button"
              className="btn btn-outline-danger mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              {question.options[0]}
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              className="btn btn-outline-dark mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              </svg>
              {question.options[0]}
            </button>
          </div>
        )}

        {question.correct === 1 ? (
          <div>
            <button
              type="button"
              className="btn btn-outline-success mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              {question.options[1]}
            </button>
          </div>
        ) : question.answer === 1 && question.correct !== 1 ? (
          <div>
            <button
              type="button"
              className="btn btn-outline-danger mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              {question.options[1]}
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              className="btn btn-outline-dark mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              </svg>
              {question.options[1]}
            </button>
          </div>
        )}

        {question.correct === 2 ? (
          <div>
            <button
              type="button"
              className="btn btn-outline-success mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              {question.options[2]}
            </button>
          </div>
        ) : question.answer === 2 && question.correct !== 2 ? (
          <div>
            <button
              type="button"
              className="btn btn-outline-danger mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              {question.options[2]}
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              className="btn btn-outline-dark mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              </svg>
              {question.options[2]}
            </button>
          </div>
        )}

        {question.correct === 3 ? (
          <div>
            <button
              type="button"
              className="btn btn-outline-success mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              {question.options[3]}
            </button>
          </div>
        ) : question.answer === 3 && question.correct !== 3 ? (
          <div>
            <button
              type="button"
              className="btn btn-outline-danger mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle-fill me-2"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              {question.options[3]}
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              className="btn btn-outline-dark mt-2"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-circle me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              </svg>
              {question.options[3]}
            </button>
          </div>
        )}

        {question.explanation ? (
          <>
            <button
              type="button"
              className="btn btn-warning mt-2"
              data-bs-toggle="collapse"
              data-bs-target={"#collapseExample" + question._id}
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-key-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              See Explanation
            </button>

            <div
              className="collapse mt-2"
              id={"collapseExample" + question._id}
            >
              <div className="card card-body">
                <div
                  dangerouslySetInnerHTML={{ __html: question.explanation }}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SingleQuestion;
