import axios from "axios";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const MentorRegistration = () => {
  const subjects = [
    "History",
    "Geography",
    "Economics",
    "Polity",
    "International Relations",
    "CSAT",
    "GS1",
    "GS2",
    "GS3",
    "GS4",
    "Optionals",
    "Polity",
    "Environment",
    "Science and Technology",
    "Indian Society",
    "Internal Security",
    "Ethics",
    "Current Affairs",
    "Essay Writing",
  ];

  const languages = [
    "Assamese",
    "Bengali",
    "Bodo",
    "Dogri",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Kashmiri",
    "Konkani",
    "Maithili",
    "Malayalam",
    "Manipuri",
    "Marathi",
    "Nepali",
    "Odia (Oriya)",
    "Punjabi",
    "Sanskrit",
    "Santali",
    "Sindhi",
    "Tamil",
    "Telugu",
    "Urdu",
  ];

  const [formDisabled, setFormDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [image, setImage] = useState({ preview: "", data: "", name: "" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    dob: "",
    city: "",
    bio: "",
    highestDegree: "",
    upscAttempts: 0,
    prelimsCleared: 0,
    interviewsAppeared: 0,
    upscCleared: false,
    subjects: [],
    languages: [],
    pricePerHour: 1000,
    mentorshipPackages: [
      {
        hours: 30,
        months: 3,
        price: 5000,
      },
      {
        hours: 60,
        months: 6,
        price: 9000,
        tag: "Aspirants Choice",
      },
      {
        hours: 90,
        months: 12,
        price: 15000,
        tag: "IAS Choice",
      },
    ],
    profilePicture: "", // You can set a default value for the profile picture if needed
    recommended: false, // Set as needed
    active: false, // Set as needed
    avgRating: 0, // Default average rating
    numberOfRatings: 0, // Default number of ratings
    password: "",
    // Add additional fields as needed
  });

  const submitFormData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL; // Replace with your API URL

      const imageRef = ref(
        storage,
        `mentorImages/${formData.fullName}/${image.name}`
      );
      await uploadBytes(imageRef, image.data).then(async (uploadedImage) => {
        // Get the download URL of the uploaded image
        const imageUrl = await getDownloadURL(imageRef);
        const slug = `/${formData.fullName.replace(/\s+/g, "-")}`;
        // Update the profilePicture field in formData with the uploaded image URL
        const updatedFormData = {
          ...formData,
          profilePicture: imageUrl,
          slug: slug,
        };

        const response = await axios.post(
          `${apiUrl}mentors/register-mentor`,
          updatedFormData
        );

        if (response.data.savedMentor) {
          setShow(true);
        }
      });

      // Optionally, perform actions after successful submission
    } catch (error) {
      console.error("Error submitting form:", error.message);
      alert(`Error: ${error.message}`); // Display error message properly
      // Handle error scenarios
      setFormDisabled(false); // Enable inputs after form submission
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = [
      "fullName",
      "email",
      "phone",
      "dob",
      "city",
      "bio",
      "highestDegree",
      "upscAttempts",
      // Add other required field names here
    ];

    const isValid = requiredFields.every((field) => formData[field] !== "");

    setValidated(true); // Set validated to true to trigger validation styles

    if (isValid) {
      setFormDisabled(true); // Disable inputs when submitting
      await submitFormData();
    }
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      name: e.target.files[0].name,
    };
    setImage(img);
  };

  const handlePackageChange = (index, field, value) => {
    const updatedPackages = [...formData.mentorshipPackages];
    updatedPackages[index][field] = value;

    setFormData((prevData) => ({
      ...prevData,
      mentorshipPackages: updatedPackages,
    }));
  };

  const handleSubjectChange = (e) => {
    const { value, checked } = e.target;

    // If the subject is checked, add it to the formData.subjects array
    // If unchecked, remove it from the array
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        subjects: [...prevData.subjects, value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        subjects: prevData.subjects.filter((subject) => subject !== value),
      }));
    }
  };

  const handleLanguageChange = (e) => {
    const { value, checked } = e.target;

    // If the language is checked, add it to the formData.languages array
    // If unchecked, remove it from the array
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        languages: [...prevData.languages, value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        languages: prevData.languages.filter((lang) => lang !== value),
      }));
    }
  };

  return (
    <>
      <section className="py-5">
        <div className="container">
          <Form
            validated={validated}
            onSubmit={handleSubmit}
            className={validated ? "was-validated" : ""}
          >
            <div className="row">
              <div className="col-sm-6">
                <h2 class="text-body-emphasis">Personal Details</h2>
                <p class="fs-5 col-md-8">
                  This section will help us in knowing more about you.
                </p>
              </div>
              <div className="col-sm-6">
                <div class="mb-3">
                  <label htmlFor="fullname" class="form-label">
                    Full Name
                  </label>
                  <input
                    type="fullname"
                    class="form-control"
                    id="fullname"
                    value={formData.fullName}
                    name="fullName" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                  <div class="invalid-feedback">Please enter your name.</div>
                </div>
                <div class="mb-3">
                  <label htmlFor="DOB" class="form-label">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="dob"
                    value={formData.dob}
                    name="dob" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                  <div class="invalid-feedback">
                    Please enter your Date of Birth.
                  </div>
                </div>

                <div class="mb-3">
                  <label htmlFor="city" class="form-label">
                    Current City
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    value={formData.city}
                    name="city" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                  <div class="invalid-feedback">
                    Please enter your current city.
                  </div>
                </div>

                <div class="mb-3">
                  <label htmlFor="city" class="form-label">
                    Your Portrait Photo
                  </label>
                  <br />
                  <div className="row">
                    <div className="col-sm-6">
                      {image.preview ? (
                        <img src={image.preview} width="100" height="100" />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="file"
                        class="form-control"
                        id="profilePicture"
                        name="profilePicture" // Add the 'name' attribute here
                        onChange={handleFileChange} // Add onChange handler
                        required
                      />
                      <div class="invalid-feedback">
                        UPSC Aspirants would love to see your face. Please
                        upload your picture.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label htmlFor="bio" class="form-label">
                    Your Bio in less than 150 words
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="bio"
                    value={formData.bio}
                    name="bio" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                  <div class="invalid-feedback">
                    Just a small bio is required about you
                  </div>
                </div>

                <div class="mb-3">
                  <label htmlFor="degree" class="form-label">
                    Your Highest Educational Degree
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="highestDegree"
                    value={formData.highestDegree}
                    name="highestDegree" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                  <div class="invalid-feedback">
                    Please tell us what is the highest degree of Education you
                    have
                  </div>
                </div>
                <div class="mb-3">
                  <label htmlFor="exampleInputEmail1" class="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    value={formData.email}
                    name="email" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                  <div class="invalid-feedback">
                    Please enter your email address
                  </div>
                </div>
                <div class="mb-3">
                  <label htmlFor="phone" class="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    class="form-control"
                    id="phone"
                    value={formData.phone}
                    name="phone" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                  <div class="invalid-feedback">
                    Please enter your Mobile Number
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-sm-6">
                <h2 class="text-body-emphasis">UPSC Attempt Details</h2>
                <p class="fs-5 col-md-8">
                  This section is aimed at capturing information about your UPSC
                  Journey
                </p>
              </div>
              <div className="col-sm-2">
                <div class="mb-3">
                  <label htmlFor="fullnames" class="form-label">
                    How many UPSC Exam attempts have you given?
                  </label>
                  <input
                    type="number"
                    step={1}
                    max={10}
                    min={1}
                    class="form-control"
                    id="totalAttempts"
                    value={formData.upscAttempts}
                    name="upscAttempts" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div class="mb-3">
                  <label htmlFor="fullnames" class="form-label">
                    How many prelims did you clear?
                  </label>
                  <input
                    type="number"
                    step={1}
                    max={6}
                    min={1}
                    class="form-control"
                    id="totalAttempts"
                    value={formData.prelimsCleared}
                    name="prelimsCleared" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div class="mb-3">
                  <label htmlFor="fullnames" class="form-label">
                    In how many UPSC Interviews did you appear?
                  </label>
                  <input
                    type="number"
                    step={1}
                    max={6}
                    min={0}
                    class="form-control"
                    id="totalAttempts"
                    value={formData.interviewsAppeared}
                    name="interviewsAppeared" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                </div>
              </div>

              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-6">
                    {" "}
                    <div class="mb-3 form-check">
                      <input
                        type="checkbox"
                        class="form-check-input border-primary"
                        id="exampleCheck1"
                        value={formData.upscCleared}
                        name="upscCleared" // Add the 'name' attribute here
                        onChange={handleInputChange} // Add onChange handler
                      />
                      <label
                        class="form-check-label fw-bold"
                        htmlFor="exampleCheck1"
                      >
                        Please tick this box if you have been in the UPSC Final
                        List
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Your Highest Prelims Score
                      </label>
                      <input
                        type="number"
                        step={1}
                        min={0}
                        max={200}
                        class="form-control"
                        id="highestScoreInPrelims"
                        name="highestScoreInPrelims" // Add the 'name' attribute here
                        onChange={handleInputChange} // Add onChange handler
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                </div>

                <h5>Please select all the subjects you are expert in</h5>
                <div className="row">
                  <div className="col-sm-6">
                    {subjects
                      .slice(0, Math.ceil(subjects.length / 2))
                      .map((subject, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={subject} // Assign the value of the subject
                            id={`subject-${index}`}
                            onChange={handleSubjectChange} // Add onChange handler
                            checked={formData.subjects.includes(subject)} // Check if the subject is in the formData.subjects array
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`subject-${index}`}
                          >
                            {subject}
                          </label>
                        </div>
                      ))}
                  </div>
                  <div className="col-sm-6">
                    {subjects
                      .slice(Math.ceil(subjects.length / 2))
                      .map((subject, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={subject}
                            id={`subject-${
                              index + Math.ceil(subjects.length / 2)
                            }`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`subject-${
                              index + Math.ceil(subjects.length / 2)
                            }`}
                          >
                            {subject}
                          </label>
                        </div>
                      ))}
                  </div>

                  <div className="col-sm-12 mt-3 mb-3">
                    <h5>
                      Please select all the languages you are proficient in
                    </h5>
                    <div className="row">
                      {languages.map((language, index) => (
                        <div key={index} className="col-sm-6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={language}
                              id={`language-${index}`}
                              onChange={handleLanguageChange}
                              checked={formData.languages.includes(language)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`language-${index}`}
                            >
                              {language}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-sm-6">
                {" "}
                <h2 class="text-body-emphasis">Mentorship Offering</h2>
                <p class="fs-5 col-md-8">
                  In this section, you need to enter the details on Mentorship
                  program that you can offer
                </p>
              </div>
              <div className="col-sm-6">
                <div class="mb-3">
                  <label htmlFor="pricePerHour" class="form-label">
                    One Hour Mentorship Session
                  </label>
                  <input
                    type="number"
                    step={100}
                    min={500}
                    max={5000}
                    defaultValue={1000}
                    class="form-control"
                    id="pricePerHour"
                    value={formData.pricePerHour}
                    name="pricePerHour" // Add the 'name' attribute here
                    onChange={handleInputChange} // Add onChange handler
                    required
                  />
                  <div class="invalid-feedback">Please enter this value</div>
                </div>

                <div className="row">
                  <b>Package 1</b>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Number of Hours
                      </label>
                      <input
                        type="number"
                        step={5}
                        min={10}
                        max={100}
                        defaultValue={30}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            0,
                            "hours",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Number of Months
                      </label>
                      <input
                        type="number"
                        step={1}
                        min={1}
                        max={6}
                        defaultValue={3}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            0,
                            "months",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Price
                      </label>
                      <input
                        type="number"
                        step={500}
                        min={2500}
                        defaultValue={5000}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            0,
                            "price",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <b>Package 2</b>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Number of Hours
                      </label>
                      <input
                        type="number"
                        step={5}
                        min={60}
                        max={150}
                        defaultValue={60}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            1,
                            "hours",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Number of Months
                      </label>
                      <input
                        type="number"
                        step={1}
                        min={1}
                        max={12}
                        defaultValue={6}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            1,
                            "months",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Price
                      </label>
                      <input
                        type="number"
                        step={500}
                        min={500}
                        defaultValue={9000}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            1,
                            "price",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <b>Package 3</b>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Number of Hours
                      </label>
                      <input
                        type="number"
                        step={10}
                        min={120}
                        defaultValue={120}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            2,
                            "hours",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Number of Months
                      </label>
                      <input
                        type="number"
                        step={1}
                        min={6}
                        defaultValue={12}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            2,
                            "months",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div class="mb-3">
                      <label htmlFor="fullnames" class="form-label">
                        Price
                      </label>
                      <input
                        type="number"
                        step={500}
                        min={10000}
                        defaultValue={15000}
                        class="form-control"
                        id="totalAttempts"
                        onChange={(e) =>
                          handlePackageChange(
                            2,
                            "price",
                            parseInt(e.target.value)
                          )
                        }
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter this value
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 justify-content-end d-flex mt-5">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={formDisabled}
                >
                  Register as a Mentor
                </button>
              </div>
            </div>
          </Form>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Your Registration is Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Our team will contact you soon for further onboarding process!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MentorRegistration;
